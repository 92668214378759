import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HealthStatus, ComplianceStatus } from 'mti-jsclient-shared'
import InfoLabel from '../InfoLabel'
import { InfoText, StatusContainer, StatusTitle } from '../RuleCell'

import HEALTHY_ICON from '../../images/svg/health-check/all-ok.svg'
import NOT_HEALTHY_ICON from '../../images/svg/health-check/not_healthy.svg'
import NOT_COMPLIANT_ICON from '../../images/svg/not_compliant.svg'
import COMPLIANT_ICON from '../../images/svg/compliant.svg'

const Container = styled.div`
  border-top: 1px solid #d9d9d9;
`

const StatusIcon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  overflow: hidden;
  object-fit: contain;
  margin-right: 8px;
`

const StatusText = styled.span`
  display: block;
  font-size: 16px;
  margin: 3px 8px 3px 0px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0px 8px 25px;
`

const MoreInfoContainer = styled.div`
  margin-left: 46px;
  padding-bottom: 10px;
`

const getStatusIcon = (state) => {
  switch (state) {
    // health
    case HealthStatus.HEALTHY:
      return HEALTHY_ICON
    case HealthStatus.UNHEALTHY:
    case HealthStatus.WARNING:
      return NOT_HEALTHY_ICON
    case HealthStatus.MISSING_SECURITY_DEVICE:
    case HealthStatus.UNKNOWN:
      return NOT_HEALTHY_ICON
    // compliance
    case ComplianceStatus.COMPLIANT:
      return COMPLIANT_ICON
    case ComplianceStatus.NON_COMPLIANT:
    case ComplianceStatus.NOTCOMPLIANT:
      return NOT_COMPLIANT_ICON
    default:
      return NOT_COMPLIANT_ICON
  }
}

function renderHealthActions(action) {
  if (!action) return null
  const actions = action.replace('↵', '\n').split('\n')

  return (
    <StatusTitle>
      Recommended Actions:
      <InfoText name="healthAction">
        {actions.map((a, index) => {
          const text = index === 0 ? `• ${a}` : a
          return <div key={a}>{text}</div>
        })}
      </InfoText>
    </StatusTitle>
  )
}

export function HealthStatusCell({ state, status, since, action, reason }) {
  return (
    <Container name="healthStatus">
      <RowContainer>
        {state && <StatusIcon src={getStatusIcon(state)} name="healthIcon" />}
        <StatusText name="healthText">{status}</StatusText>
        {since && <InfoLabel>Since: {since}</InfoLabel>}
      </RowContainer>
      {(reason || action) && (
        <MoreInfoContainer>
          <StatusContainer>
            {reason ? (
              <StatusTitle name="healthReason">
                Reason:
                <InfoText>{` ${reason}`}</InfoText>
              </StatusTitle>
            ) : null}
            {renderHealthActions(action)}
          </StatusContainer>
        </MoreInfoContainer>
      )}
    </Container>
  )
}

HealthStatusCell.propTypes = {
  state: PropTypes.string,
  status: PropTypes.string.isRequired,
  since: PropTypes.string,
  action: PropTypes.string,
  reason: PropTypes.string,
}

export function ComplianceStatusCell({ state, description, since }) {
  return (
    <Container name="complianceStatus">
      <RowContainer>
        {state && (
          <StatusIcon src={getStatusIcon(state)} name="complianceIcon" />
        )}
        <StatusText name="complianceDescription">{description}</StatusText>
        {since && <InfoLabel name="complianceDate">Since: {since}</InfoLabel>}
      </RowContainer>
    </Container>
  )
}

ComplianceStatusCell.propTypes = {
  state: PropTypes.string,
  description: PropTypes.string.isRequired,
  since: PropTypes.string,
}
