import { Record, List } from 'immutable'
import moment from 'moment'
import { formattedDuration } from './utils'
import { isOldRule } from '../../utils/mtiUtils'
import { kebabToUppercase } from '../../utils/utils'

export const Exception = new Record({
  id: null,
  sviException: null,

  // user
  userId: null,
  userName: null,
  // key
  keyId: null,
  keySerialNumber: null,

  // Health Operations
  eventTypeMessage: null,
  unhealthyReason: null,
  // Compliance
  descriptionRule: null,
  descriptionAction: null,
  lastValue: null,
  currently: null,

  occurredAt: null,

  duration: null,
  floor: null,

  storeId: null,
  storeName: null,

  fixtureId: null,
  fixtureName: null,

  position: null,
  resourceId: null,
  resourceName: null,
  resourceTypeResource: null,
  area: null,
  additionalInformation: null,

  // user for modal
  // user: null,
})

export function createExceptions(results) {
  return List(
    (results || []).filter((i) => !isOldRule(i)).map((data) => {
      const occurredAt = data.status_updated_at
      const duration =
        occurredAt && moment().diff(moment(occurredAt).utcOffset(0))

      const storeTimeZone = data.store.time_zone
      const occurredAtInStoreTimezone =
        occurredAt &&
        moment.tz(occurredAt, storeTimeZone).format('MM/DD/YYYY H:mm')

      const user = data.resourceOwner || {}
      const attrs = {
        id: data.id,
        sviException: data.sviException,

        // column User/Key
        // user
        userId: data.userId,
        userName: `${user.firstName || ''} ${user.lastName || ''}`, // for operational exceptions
        // key
        keyId: data.physicalKeyId,
        keySerialNumber: data.physicalKeySerialNumber, // for operational exceptions

        // column Occurred at (Date)
        occurredAt: occurredAtInStoreTimezone,

        // column Event type/Event message
        eventTypeMessage: eventTypeMessage(data),
        unhealthyReason: kebabToUppercase(data.unhealthy_reason),

        // column Description/Currently
        descriptionRule: data.description,
        descriptionAction: data.action,
        lastValue: data.last_value,
        currently: data.additionalInformation,

        // column Duration
        duration: formattedDuration(duration),

        // column Store
        storeId: data.store_id,
        storeName: data.store_name,

        // column Fixture
        fixtureId: data.fixture_id,
        fixtureName: data.fixture_name,

        // column Resource type/Resource (Position)
        position: data.position,
        resourceId: (data.resource || {}).id || (data.position || {}).id,
        resourceName: (data.resource || {}).name || (data.position || {}).name,
        resourceTypeResource:
          data.name ||
          (data.resource || {}).name ||
          data.positionName ||
          (data.position || {}).name,
        area: data.area,
        floor: data.floor,

        additionalInformation: additionalInformation(data),

        // user for modal
        // user, // TODO: implement in case it is planned to open user modal dialog
      }
      return new Exception(attrs)
    })
  )
}

function additionalInformation({ additional_information = '', description }) {
  if (description && !description.includes(additional_information)) {
    return additional_information
  }
}

function eventTypeMessage(data) {
  if (data.health_status_key) {
    data.health_status_key = kebabToUppercase(data.health_status_key)
  }

  if (data.operations_status_key === 'other') {
    return data.health_status_key
  }

  if (data.sviException === 'health') {
    return data.health_status_key
  }

  if (data.operations_status_key) {
    return kebabToUppercase(data.operations_status_key)
  }

  return data.operations_status_key
}
