/**
 * Users Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { getUsers, activateUser, deactivateUser, orgIdUrl } from 'api'
import {
  makeSelectToken,
  makeSelectIsMtiAdmin,
  makeSelectMtiPermissionAnyResource,
} from '../App/selectors'
import {
  fetchUsersPending,
  fetchUsersFulfilled,
  fetchUsersFailed,
} from './actions'
import { makeSelectUsers, makeSelectLoading } from './selectors'
import { errorToast, successToast } from '../../utils/utils'

export function* loadUsersData({ payload = {} } = {}) {
  const { attribute = 'lastName', direction = 'asc', withInactive = false } = payload
  const token = yield select(makeSelectToken())
  const data = yield call(
    getUsers,
    token,
    attribute,
    direction,
    undefined,
    undefined,
    withInactive,
  )

  return data
}

export function* loadUsers({ payload = {} } = {}) {
  const listUsersPermissionData = yield select(
    makeSelectMtiPermissionAnyResource('User: Index (list) Records')
  )
  if (!listUsersPermissionData) {
    return
  }

  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Users')
    return
  }
  const isMtiAdmin = yield select(makeSelectIsMtiAdmin())
  if (isMtiAdmin && !orgIdUrl()) return
  const isFetchUsersPending = yield select(makeSelectLoading())
  if (isFetchUsersPending) return
  const usersLoaded = yield select(makeSelectUsers())
  const isUsersLoaded = usersLoaded && usersLoaded.length > 0
  try {
    if (!isUsersLoaded) {
      yield put(fetchUsersPending())
    }

    const data = yield call(loadUsersData, { payload })
    yield put(fetchUsersFulfilled(data))
  } catch (error) {
    console.error(error)
    yield put(fetchUsersFailed(error))
    errorToast('Load Users failed')
  }
}

export function* changeUserStatus({ payload: { user, newStatus, showInactiveUsersOnReload } }) {
  const token = yield select(makeSelectToken())
  if (newStatus == 'active') {
    try {
      yield call(activateUser, token, user.id)
    } catch (e) {
      errorToast('Unable to activate user.')
      return console.log(e)
    }
    successToast('Successfully activated user.')
  } else if (newStatus == 'inactive') {
    try {
      yield call(deactivateUser, token, user.id)
    } catch (e) {
      errorToast('Unable to deactivate user.')
      return console.log(e)
    }
    successToast('Successfully deactivated user.')
  } else { return } // we don't currently support manually changing to any other status

  // reload users to refresh users page
  yield call(loadUsers, { payload: { withInactive: showInactiveUsersOnReload } })
}
