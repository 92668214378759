import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Checkbox from '../../../components/Checkbox'
import Radio from '../../../components/Radio'
import styles from '../../../styles/App.css'
import { ModalBodyPure } from './securityDevice.screen'
import { fullTextFilter } from '../../../utils/utils'
import {
  renderFilterInput,
  renderHeaderCell,
} from '../../UsersPage/index.screen'
import { TableInModal } from '../../../styles/styled'
import { CellContainer } from '../../AreaPage/modals/selectTemplate.screen'

export const Header = styled.h5`
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ModalBody = styled.div`
  padding: 10px;
`

/* eslint-disable react/prop-types, no-underscore-dangle, no-unused-vars */
const renderMainContent = ({
  dId,
  name,
  products,
  selectedProduct,
  onChange,
  onDeleteProduct,
}) => {
  const columns = [
    {
      id: 'assign',
      accessor: 'id',
      sortable: false,
      width: 75,
      style: { justifyContent: 'center' },
      Cell: ({ original: { id }, original }) => (
        <Radio
          id={`device-${id}`}
          name={'security-device'}
          onChange={() => onChange({ selectedProduct: original })}
          checked={id === (selectedProduct || {}).id}
        />
      ),
    },
    {
      id: 'manufacturer',
      Header: renderHeaderCell('Manufacturer'),
      accessor: (product) => manufacturerName(product),
      filterable: true,
      width: 150,
      Filter: ({ filter, onChange: onChangeFilter }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange: onChangeFilter,
          id: 'manufacturer',
        }),
      Cell: ({ original }) => (
        <CellContainer>{manufacturerName(original)}</CellContainer>
      ),
    },
    {
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      filterable: true,
      Filter: ({ filter, onChange: onChangeFilter }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange: onChangeFilter,
          id: 'name',
        }),
      Cell: ({ original: { name } }) => <CellContainer>{name}</CellContainer>,
    },
    {
      Header: 'Should Charge',
      sortable: false,
      width: 125,
      style: { justifyContent: 'center' },
      Cell: ({ original: { id, usbCapable } }) => (
        <Checkbox
          id={`charge-${id}`}
          name={`charge-${id}`}
          onChange={(e) => console.log(e.target.checked)}
          checked={usbCapable}
        />
      ),
    },
    {
      Header: 'Delete',
      sortable: false,
      width: 125,
      style: { justifyContent: 'center' },
      Cell: ({ original }) => (
        <Link
          to={'#'}
          onClick={() => {
            $(`#modal-${dId}`).modal('hide') // eslint-disable-line
            onDeleteProduct(original)
          }}
          style={{ color: '#04a9f4' }}
        >
          Delete
        </Link>
      ),
    },
  ]

  return (
    <TableInModal
      data={products}
      columns={columns}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      defaultPageSize={100}
      defaultFilterMethod={fullTextFilter}
    />
  )
}

/**
 * Determines correct product name field from presence of manufacturerId
 * @param {Object} product
 * @returns {String} Manufacturer Name
 */
const manufacturerName = (product) => {
  if (product.manufacturerId) return product.manufacturer.name
  return product.otherManufacturerName
}

const AssignProductScreen = ({
  id,
  onConfirm,
  onClose,
  onChange,
  onUnassign,
  onAddNewProduct,
  onDeleteProduct,
  name,
  selectedProduct,
  products,
  selectedPosition,
}) => (
  <div className="modal-content">
    <div className="modal-header border-bottom">
      <h5 className="modal-title" id="exampleModalLongTitle">
        Assign Product
      </h5>
      <div style={{ flex: 1, marginLeft: 20 }} />
      <div>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: '20px' }}
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onClose()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: '20px' }}
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            setTimeout(() => onAddNewProduct(), 500)
          }}
        >
          Add New Product
        </button>
        {selectedPosition.securedProduct && (
          <button
            type="button"
            className="btn btn-raised"
            style={{
              flex: 1,
              marginRight: 20,
              width: 100,
              marginBottom: '20px',
            }}
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onUnassign()
            }}
          >
            Unassign
          </button>
        )}
        <button
          type="button"
          className="btn btn-raised"
          style={{ marginBottom: '20px' }}
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onConfirm()
          }}
        >
          Assign
        </button>
      </div>
    </div>
    <ModalBodyPure className="modal-body">
      <div className={styles.modalContentWrapper}>
        {renderMainContent({
          dId: id,
          name,
          products,
          selectedProduct,
          onChange,
          onDeleteProduct,
        })}
      </div>
    </ModalBodyPure>
  </div>
)

AssignProductScreen.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddNewProduct: PropTypes.func.isRequired,
  onDeleteProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  products: PropTypes.array,
  selectedPosition: PropTypes.object,
}

export default AssignProductScreen
