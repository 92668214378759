import React from 'react'
import PropTypes from 'prop-types'
import { IssueContainer, TextTop } from '../Issue'
import { IssuesContainer, IssuesTitle } from '../NotCompliantFixtures'
import RulesList from '../RulesList'

function renderFixtureRules(rules) {
  if (!rules || rules.length < 1) {
    return (
      <IssueContainer>
        <TextTop>No Rules</TextTop>
      </IssueContainer>
    )
  }

  return <RulesList rules={rules} />
}

export default function FixtureRulesList({ rules }) {
  return (
    <IssuesContainer>
      <IssuesTitle>Fixture Compliance Rules & Issues:</IssuesTitle>
      {renderFixtureRules(rules)}
    </IssuesContainer>
  )
}

FixtureRulesList.propTypes = {
  rules: PropTypes.array,
}
