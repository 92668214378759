// Data handling
import { DeviceConfiguration } from './model'

// Component building
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Composed components
import DeviceConfigurationForm from './DeviceConfigurationForm'
import Checkbox from '../Checkbox'
import HelpIcon from '../HelpIcon'
import { Container, Row, Col, Card } from '../Bootstrap'
import {
  CheckboxAndInputContainer,
  CheckboxContainer,
} from '../../containers/TemplateFixture/index.screen'
import { FormattedMessage, injectIntl } from 'react-intl'

// Behaviors
import { openModal } from '../../containers/App/actions'

const intlPath = (strings) =>
  `components.deviceConfiguration.list.${strings[0]}`

/**
 * A reusable component for displaying security device firmware configuration
 * options. (Update this comment when you add more functionality.)
 *
 * Example use:
 *   <DeviceConfigurationList
 *     allowDeviceConfigOverride={false}
 *     onChange={onChange}
 *     resourceType="Organization"
 *     resourceId={this.props.organization.id}
 *   />
 *
 * Params:
 *   allowDeviceConfigOverride: (boolean) whether the checkbox is initially set.
 *   onChange: (function) This function is called when the data are changed.
 *             It will be passed a new dictionary of parameters. (Currently, only
 *             { allowDeviceConfigOverride: true/false }) If this is omitted,
 *             the editable fields on the component will be in read-only mode.
 *   resourceType: (string, requried) one of "Organization", "Region" or "Store".
 *                 If this is not "Organization", the editable fields on the component
 *                 will be in read-only mode.
 *   resourceId: (number, required) the ID of the organization, region, or store to filter by.
 */
class DeviceConfigurationList extends React.Component {
  /**
   * @constructor
   * @param {boolean} allowDeviceConfigOverride - whether the org allows manager overrides
   * @param {string} resourceType - the type of the owning resource (Organization, Region, or Store)
   * @param {number} resourceId - the ID of the owning resource
   * @param {Array<DeviceConfiguration>} deviceConfigurations - The list of DeviceConfiguration objects to display.
   * @param {func} onChange - The callback function to call when any editable fields are changed. If omitted, the form will be read-only.
   */
  constructor(props) {
    super(props)
    this.state = {
      allowDeviceConfigOverride: props.allowDeviceConfigOverride,
    }
    // this.handleSelectDevConf =
    //   this.handleSelectDevConf.bind(this)
  }

  componentDidMount() {
    this.props.sendLoadDevConfs(this.props.resourceType, this.props.resourceId)
  }

  /** Whether to allow edits.
   * @returns {boolean}
   */
  editMode() {
    return typeof this.props.onChange !== 'undefined'
  }

  /** Whether to display some alternate controls specific to organizations.
   * @returns {boolean}
   */
  orgMode() {
    return (
      this.props.resourceType === DeviceConfiguration.resourceTypes.ORGANIZATION
    )
  }

  onChange = ({ target: { checked } }) => {
    let data = {
      allowDeviceConfigOverride: checked,
    }
    this.setState(data, () => this.props.onChange(data))
  }

  /** Open a form to view/edit the clicked-on device configuration.
   * @param {DeviceConfiguration} devConf - the device configuration to display.
   */
  handleSelectDevConf(devConf) {
    const { sendOpenModal, currentModal } = this.props
    sendOpenModal({
      id: devConf.editFormId(),
      type: 'bootstrap',
      appearance: 'modal-lg',
      content: (
        <DeviceConfigurationForm
          deviceConfiguration={devConf}
          previousModal={currentModal}
        />
      ),
    })
  }

  /** Render the link or drop-down list of firmware versions for the
   * device type.
   * @param {Array<DeviceConfiguration>} group - An array of DeviceConfiguration objects
   *   for which to create links.
   */
  deviceConfigButtons(group) {
    const { intl } = this.props
    const earlier = intl.formatMessage({
      id: intlPath`earlier`,
    })
    const allVersions = intl.formatMessage({
      id: intlPath`allVersions`,
    })
    const dfltCfg = group.shift()
    const dfltBtn = (
      <React.Fragment>
        <span className="firmware-name">
          {dfltCfg.firmwareVersion || allVersions}
        </span>
        &nbsp;
        <a
          className="firmware-action"
          onClick={() => this.handleSelectDevConf(dfltCfg)}
          role="button"
          tabIndex={0}
        >
          View
        </a>
      </React.Fragment>
    )
    if (group.length == 0) return dfltBtn

    const buttons = group.map((config) => (
      <a
        className="dropdown-item"
        key={config.id}
        onClick={() => this.handleSelectDevConf(config)}
        role="button"
        tabIndex={0}
      >
        {config.firmwareVersion || earlier}
      </a>
    ))

    return (
      <div className="btn-group">
        {dfltBtn}
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-sm btn-primary dropdown-toggle"
            data-toggle="dropdown"
          >
            <FormattedMessage id={intlPath`older`} />
          </button>
          <div className="dropdown-menu">{buttons}</div>
        </div>
      </div>
    )
  }

  /** Render rows for each device type. */
  deviceConfigRows() {
    const { list: deviceConfigurations, status, message } = this.props

    if (deviceConfigurations.length === 0 && status === 'PENDING') {
      return (
        <tr>
          <td colSpan="2">
            <span className="card-text text-muted">LOADING ...</span>
          </td>
        </tr>
      )
    }

    if (status === 'FAILED') {
      return (
        <tr>
          <td colSpan="2">
            <span className="card-text text-danger">{message}</span>
          </td>
        </tr>
      )
    }

    const grouped = deviceConfigurations.reduce((agg, devConf) => {
      agg[devConf.deviceType] = [...(agg[devConf.deviceType] || []), devConf]
      return agg
    }, {})
    return Object.keys(grouped).map((deviceType) => {
      const buttons = this.deviceConfigButtons(
        grouped[deviceType].sort(
          (a, b) => (a.firmwareVersion < b.firmwareVersion ? 1 : -1)
        )
      )
      return (
        <tr key={deviceType}>
          <td>
            <FormattedMessage id={`deviceTypes.${deviceType}`} />
          </td>
          <td>{buttons}</td>
        </tr>
      )
    })
  }

  /** The title of the area of UI. It will also display any transient
   * status during load or failure.
   * @returns {string}
   */
  title() {
    const { status } = this.props
    return `Device Configurations${status !== 'READY' ? ` (${status})` : ''}`
  }

  render() {
    return (
      <Card title={this.title()} className="device-configuration-list">
        <Container>
          <Row>
            <Col span={4}>
              <CheckboxAndInputContainer>
                <CheckboxContainer>
                  <Checkbox
                    name="allowDeviceConfigurationOverride"
                    onChange={this.onChange}
                    checked={this.state.allowDeviceConfigOverride}
                    disabled={!(this.editMode() && this.orgMode())}
                  />
                </CheckboxContainer>
                <FormattedMessage id={intlPath`allowOverrides`} />
                <HelpIcon helpKey="deviceConfigurations.allowManagerOverrides" />
              </CheckboxAndInputContainer>
            </Col>
            <Col span={8}>
              <FormattedMessage id={intlPath`manageConfigurations`} />
              <HelpIcon helpKey="deviceConfigurations.deviceTypes" />
            </Col>
          </Row>
          <Row>
            <Col span={4} />
            <Col span={8}>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id={intlPath`deviceType`} />
                    </th>
                    <th>
                      <FormattedMessage id={intlPath`minimumFirmwareVersion`} />
                    </th>
                  </tr>
                </thead>
                <tbody>{this.deviceConfigRows()}</tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </Card>
    )
  }
}

DeviceConfigurationList.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.number.isRequired,
  deviceConfigurations: PropTypes.array,
  onChange: PropTypes.func,
}

DeviceConfigurationList.defaultProps = {}

function mapStateToProps(state) {
  const deviceConfigurations = state.get('deviceConfigurations')
  return {
    list: deviceConfigurations.get('list'),
    status: deviceConfigurations.get('status'),
    message: deviceConfigurations.get('message'),
    currentModal: state
      .get('modal')
      .get('modals')
      .first(),
    allowDeviceConfigOverride: state.get('organization').get('organization')
      .organizations[0].allowDeviceConfigOverride,
  }
}
const mapDispatchToProps = {
  sendLoadDevConfs: DeviceConfiguration.actionMakers.loadDevConfs,
  sendOpenModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(DeviceConfigurationList)
)
