import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectHasMtiRole,
  makeSelectMtiPermission,
} from '../../../containers/App/selectors'
import {
  fetchAndUpsertStore,
  notifyModal,
  storeDetailsAction,
} from '../../../containers/App/actions'
import { connect } from 'react-redux'
import { StoreManagerOrStaff } from '../../../containers/HomePage/StoreManagerOrStaff'
import DrawerLinksScreen from './index.screen'
import { closeDrawer } from '../Button'
import { makeSelectLoading } from '../../../containers/HomePage/StoreManagerOrStaff/selectors'
import { selectStoreById } from '../../Breadcrumbs/selectors'
import { withRouter } from 'react-router-dom'
import { selectStoreHasMissingSecuredProducts } from '../../../containers/ExceptionsList/SecuredProducts/selectors'

class StoreManagerDrawer extends React.PureComponent {
  onItem = (event, item) => {
    closeDrawer()
    StoreManagerOrStaff.onItem(event, item, this.props)
  }

  render() {
    const { store, history } = this.props
    const items = StoreManagerOrStaff.getItemsData(store, this.props)
    if (!store) {
      return null
    }

    return (
      <DrawerLinksScreen
        quickActionsItems={items}
        onItem={this.onItem}
        history={history}
      />
    )
  }
}

export function storeIdFromUrl() {
  let path = window.location.pathname
  const pathParams = path.split('/') || []

  switch (pathParams[1]) {
    case 'organizations':
      return pathParams[5]
    case 'stores':
      return pathParams[2]
  }
}

export function getStoreId(state) {
  const sId = storeIdFromUrl()
  if (sId) {
    return sId
  }

  const reduxStore = state.get('editStores')
  return reduxStore && (reduxStore.get('store') || {}).id
}

const mapStateToProps = createStructuredSelector({
  hasStoreManagerRole: (state, ownProps) =>
    makeSelectHasMtiRole('Store Manager', getStoreId(state))(state, ownProps),
  hasViewKeysPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Index (list) Records',
      getStoreId(state)
    )(state, ownProps), // deprecated name 'viewKeys'
  hasViewStoreReportsPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Saved Report: Index (list) Records',
      getStoreId(state)
    )(state, ownProps), // deprecated name 'viewStoreReports'
  hasViewUsersPermission: (state, ownProps) =>
    makeSelectMtiPermission('User: Index (list) Records', getStoreId(state))(
      state,
      ownProps
    ), // deprecated name 'viewUsers'
  hasViewSchedulesPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Schedule: Index (list) Records',
      getStoreId(state)
    )(state, ownProps), // deprecated name 'viewUsers'
  store: (state, ownProps) =>
    selectStoreById({
      sId: getStoreId(state),
    })(state, ownProps),
  loading: makeSelectLoading(),
  hasMissingSecuredProducts: (state) =>
    selectStoreHasMissingSecuredProducts(state, getStoreId(state)),
})

const mapDispatchToProps = {
  notifyModal,
  storeDetailsAction,
  fetchAndUpsertStore,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

StoreManagerDrawer.propTypes = {
  history: PropTypes.object.isRequired,
  notifyModal: PropTypes.func,
  storeDetailsAction: PropTypes.func,
  hasViewKeysPermission: PropTypes.bool,
  hasViewStoreReportsPermission: PropTypes.bool,
  hasViewUsersPermission: PropTypes.bool,
  hasViewSchedulesPermission: PropTypes.bool,
  store: PropTypes.object,
}

export default compose(withConnect)(withRouter(StoreManagerDrawer))
