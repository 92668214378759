import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import Chance from 'chance'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import styled from 'styled-components'
import { fullTextFilter } from '../../../utils/utils'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../../UsersPage/index.screen'
import TrComponent from '../../../components/TrComponent'
import TooltipCell from '../../../components/TooltipCell'

const CheckboxTable = checkboxHOC(ReactTable)
const StyledCheckboxTable = styled(CheckboxTable)`
  max-height: 345px;
`

const chance = new Chance()

class StoresScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    const storeIds = props.selectedStoreIds
    const columns = this.getColumns()
    const data = this.getData(props.stores)
    const selection = data
      .filter(({ id }) => storeIds.includes(id))
      .map(({ _id }) => _id)
    this.state = {
      data,
      columns,
      selection,
      selectAll: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const storeIds = this.props.selectedStoreIds
    const columns = this.getColumns()
    if (
      JSON.stringify(prevProps.stores) !== JSON.stringify(this.props.stores)
    ) {
      const data = this.getData(this.props.stores).sort(
        (a, b) => storeIds.includes(b.id) - storeIds.includes(a.id)
      )
      const selection = data
        .filter(({ id }) => storeIds.includes(id))
        .map(({ _id }) => _id)

      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        data,
        columns,
        selection,
        selectAll: false,
      })
    }

    const { selection, data } = this.state
    if (prevState.selection !== selection) {
      const storeIds = data
        .filter(({ _id }) => selection.includes(_id))
        .map((s) => s.id)
      this.props.onStore(storeIds)
    }
  }

  getData(keys) {
    return (keys || []).map((item) => {
      const _id = chance.guid()
      return {
        _id,
        ...item,
      }
    })
  }

  sortStickyStores = (a, b, isDesc, key) => {
    const { selectedStoreIds: selectedIds } = this.props
    const firstEl = a[key] || ''
    const secondEl = b[key] || ''

    if (isDesc) {
      const result = selectedIds.includes(b.id) || selectedIds.includes(a.id)
      if (!result) {
        return firstEl.localeCompare(secondEl)
      }
      return -1
    } else {
      const result = selectedIds.includes(b.id) - selectedIds.includes(a.id)
      if (result === 0) {
        return firstEl.localeCompare(secondEl)
      }
      return result
    }
  }

  getColumns = () => {
    const columns = [
      {
        id: 'name',
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Store'),
        accessor: (store) => ({ id: store.id, name: store.name }),
        width: 200,
        filterMethod: (filter, row) =>
          fullTextFilter(filter, { name: (row.name || {}).name }),
        Cell: ({ original: { name } }) => <CellContainer>{name}</CellContainer>,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'storeName',
          }),
        sortMethod: (a, b, isDesc) =>
          this.sortStickyStores(a, b, isDesc, 'name'),
      },
      {
        id: 'regionName',
        sortable: true,
        width: 180,
        filterable: true,
        Header: renderHeaderCell('Current Region'),
        accessor: (store) => ({ id: store.id, regionName: store.regionName }),
        filterMethod: (filter, row) =>
          fullTextFilter(filter, {
            regionName: (row.regionName || {}).regionName,
          }),
        Cell: ({ original: { regionName } }) => (
          <CellContainer>{regionName || 'Not Assigned'}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'currentRegion',
          }),
        sortMethod: (a, b, isDesc) =>
          this.sortStickyStores(a, b, isDesc, 'regionName'),
      },
      {
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Store Managers'),
        accessor: 'rolesTitle',
        Cell: () => (
          <TooltipCell
            text="TBD..."
            render={(tooltip) => (
              <CellContainer ref={(ref) => tooltip.target(ref)}>
                {'TBD...'}
              </CellContainer>
            )}
          />
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'storeManagers',
          }),
      },
    ]

    return columns
  }

  getSelectedStores = () => {
    const { data, selection } = this.state
    return data.filter((key) => !!selection.find((id) => id === key._id))
  }

  toggleAll = () => {
    /*
    Selection of visible (filtered) rows.
    */
    const selectAll = !this.state.selectAll
    const selection = []
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance()
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(item._original._id) // eslint-disable-line
      })
    }
    this.setState({ selectAll, selection })
  }

  isSelected = (key) => this.state.selection.includes(key)

  toggleSelection = (key) => {
    // start off with the existing state
    let selection = [...this.state.selection]
    const keyIndex = selection.indexOf(key)
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      // it does not exist so add it
      selection.push(key)
    }
    // update the state
    this.setState({ selection })
  }

  render() {
    const { toggleSelection, toggleAll, isSelected } = this
    const { selectAll, data /*, columns*/ } = this.state

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        // background color change
        const selected = this.isSelected(((r || {}).original || {})._id) // eslint-disable-line
        return {
          style: {
            backgroundColor: selected ? 'lightblue' : 'inherit',
          },
        }
      },
    }

    return (
      <StyledCheckboxTable
        ref={(r) => {
          this.checkboxTable = r
        }}
        data={data}
        columns={this.getColumns()}
        className="-striped -highlight"
        defaultFilterMethod={fullTextFilter}
        defaultPageSize={10}
        TrComponent={TrComponent}
        defaultSorted={[
          {
            id: 'name',
          },
        ]}
        {...checkboxProps}
      />
    )
  }
}

StoresScreen.propTypes = {
  stores: PropTypes.array.isRequired,
  selectedStoreIds: PropTypes.array.isRequired,
  onStore: PropTypes.func.isRequired,
}

export default StoresScreen
