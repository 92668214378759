/**
 *
 * Templates Saga
 *
 */
import { select, call, put, all, takeLatest } from 'redux-saga/effects'
import { modelActionCreators } from 'mti-jsclient-shared'
import { makeSelectToken, makeSelectSubdomain } from '../App/selectors'
import { getTemplates, deleteTemplate, getProducts } from '../../api'
import { upsert, fetchAndUpsertPosition } from '../App/sagas/ormSaga'
import {
  fetchTemplatesFailed,
  fetchTemplatesFulfilled,
  fetchTemplatesPending,
  fetchTemplatesStartTime,
} from './actions'
import { errorToast, successToast } from '../../utils/utils'
import {
  makeSelectTemplatesLoading as makeSelectLoading,
  makeSelectTemplateFixtures as makeSelectTemplates,
  makeSelectTemplatesFetchStartTime as makeSelectFetchStartTime,
} from './selectors'
import { ActionTypes } from './constants'

export function* loadTemplatesData() {
  const token = yield select(makeSelectToken())
  const subdomain = yield select(makeSelectSubdomain()) // TODO: temporary
  const data = yield call(getTemplates, token, subdomain)
  const { manufacturers, products } = yield call(getProducts, token)
  return {
    ...data,
    manufacturers,
    products,
  }
}

export function* loadTemplates({ payload }) {
  // const hasOrganizationAdminRole = yield select(
  //   makeSelectHasMtiRole('Organization Administrator')
  // )
  // const hasStoreManagerRole = yield select(
  //   makeSelectHasMtiRoleAnyResource('Store Manager')
  // )
  // if (!(hasOrganizationAdminRole || hasStoreManagerRole)) {
  //   return
  // }
  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Templates')
    return
  } else {
    // console.log('get Templates')
  }
  const { force, tId } = payload
  const isFetchTemplatesPending = yield select(makeSelectLoading())
  if (isFetchTemplatesPending && !force) return
  const templatesLoaded = yield select(makeSelectTemplates())
  const isTemplatesLoaded = templatesLoaded && templatesLoaded.length > 1
  try {
    if (!isTemplatesLoaded) {
      yield put(fetchTemplatesPending())
    }
    const startTime = new Date().getTime()
    yield put(fetchTemplatesStartTime(startTime))
    const data = yield call(loadTemplatesData, startTime)
    const startTimeStored = yield select(makeSelectFetchStartTime())
    if (startTimeStored > startTime) {
      return // Because we are waiting on more recent request for templates
    }
    yield call(upsert, { payload: data })
    // TODO: This is a workaround that fixes /api/templates/
    // which returns positions without geometry ids and without geometry objects
    if (tId) {
      const { positionIds = [] } =
        (data.fixtures || []).find(
          ({ id }) => id === parseInt(tId || '-1', 10)
        ) || {}
      for (let i = 0; i < positionIds.length; i++) {
        const pId = positionIds[i]
        yield call(fetchAndUpsertPosition, { payload: pId })
      }
    }
    yield put(fetchTemplatesFulfilled())
  } catch (error) {
    console.error(error)
    yield put(fetchTemplatesFailed(error))
    errorToast('Load templates failed')
  }
}

function* destroyTemplate({ payload }) {
  try {
    const token = yield select(makeSelectToken())
    const { id } = payload
    yield put(fetchTemplatesPending())
    yield call(deleteTemplate, token, id)
    successToast('Template Deleted')
    yield put(modelActionCreators.deleteFixtureWithId(id))
    yield call(loadTemplates, { payload: { force: true } }) // Reload templates list
    yield put(fetchTemplatesFulfilled())
  } catch (error) {
    console.warn(error)
    yield put(fetchTemplatesFailed(error))
    errorToast('Delete template failed')
  }
}

export default function* root() {
  yield all([yield takeLatest(ActionTypes.DELETE_TEMPLATE, destroyTemplate)])
}
