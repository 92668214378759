import { createSelector } from 'reselect'

const selectApp = (state) => state.get('app')

const makeSelectOrganizations = () =>
  createSelector(selectApp, (app) => {
    const organizations = app.get('organizations')
    return organizations
  })

const makeSelectFailed = () =>
  createSelector(selectApp, (app) => app.get('organizationsFailed'))

const makeSelectLoading = () =>
  createSelector(selectApp, (app) => app.get('organizationsLoading'))

export {
  selectApp,
  makeSelectOrganizations,
  makeSelectFailed,
  makeSelectLoading,
}
