import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ArmState,
  HealthStatus,
  ComplianceStatus,
  PowerState,
} from 'mti-jsclient-shared'
import armedGreen from 'images/issues/lock/armed_green.png'
import disarmedRed from 'images/issues/lock/disarmed_red.png'
import lockedGreen from 'images/issues/lock/locked_green.png'
import unlockedYellow from 'images/issues/lock/unlocked_yellow.png'
import alarm from 'images/issues/alarming/alarm.png'
import armedOrange from 'images/issues/lock/armed_orange.png'
import unpoweredGreen from 'images/issues/lock/unpowered_green.png'
import DropdownButton from './DropdownButton'

export const IssueContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: 64px;
  padding: 0 25px;
  border-bottom: 1px solid #d9d9d9;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 180px;
`

export const TextTop = styled.span`
  font-size: 14px;
  text-align: left;
  width: 180px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
`

export const TextBottom = styled.span`
  font-size: 12px;
  text-align: left;
  width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Status = styled.span`
  font-size: 12px;
  text-align: right;
  width: 61px;
`

export const Image = styled.img`
  text-align: right;
  width: 18px;
`

const transparentImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

const RED_COLOR = 'red'
const GREEN_COLOR = 'green'

const getAdditionalElementsForStatus = state => {
  switch (state) {
    // arm/lock
    case ArmState.ALARMING:
      return { icon: alarm, color: GREEN_COLOR }
    case ArmState.ARMED:
      return { icon: armedGreen, color: RED_COLOR }
    case ArmState.SILENT_ALARMING:
      return { icon: null, color: RED_COLOR }
    case ArmState.DISARMED:
      return { icon: disarmedRed, color: GREEN_COLOR }
    case ArmState.LOCKED:
      return { icon: lockedGreen, color: RED_COLOR }
    case ArmState.OPEN:
    case ArmState.UNLOCKED:
      return { icon: unlockedYellow, color: GREEN_COLOR }
    case ArmState.OTHER_STATE:
    case ArmState.MISSING_SECURITY_DEVICE:
    case ArmState.UNKNOWN_DEVICE:
      return { icon: null, color: 'white' }
    // health
    case HealthStatus.HEALTHY:
      return { icon: armedGreen, color: RED_COLOR }
    case HealthStatus.UNHEALTHY:
    case HealthStatus.WARNING:
      return { icon: armedOrange, color: RED_COLOR }
    case HealthStatus.MISSING_SECURITY_DEVICE:
    case HealthStatus.UNKNOWN:
      return { icon: null, color: 'white' }
    // compliance
    case ComplianceStatus.COMPLIANT:
      return { icon: armedGreen, color: RED_COLOR }
    case ComplianceStatus.NON_COMPLIANT:
      return { icon: armedOrange, color: RED_COLOR }
    // power
    case PowerState.UNPOWERED:
      return { icon: unpoweredGreen, color: GREEN_COLOR }
    default:
      return { icon: null, color: 'white' }
  }
}

class Issue extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    if (this.textBottomRef && this.textBottomRef.popover) {
      this.textBottomRef.popover('dispose')
    }
  }

  textBottomRef = null

  onOver() {
    this.setState({ isHighlighted: true })
  }

  onOut() {
    this.setState({ isHighlighted: false })
  }

  popoverForLongText = (ref, msg) => {
    if (!msg) {
      return
    }

    this.textBottomRef = $(ref) // eslint-disable-line
    this.textBottomRef.popover('dispose').popover({
      placement: 'left',
      trigger: 'hover focus',
      html: true,
      content: msg,
    })
  }

  renderCell = icon => {
    const { issue, onMouseEnter, onMouseLeave } = this.props
    const { id, title, device, status, statusTitle } = issue
    const { isHighlighted } = this.state

    return (
      <IssueContainer
        style={isHighlighted ? { backgroundColor: 'grey' } : {}}
        onMouseEnter={() => {
          if (onMouseEnter) {
            onMouseEnter(id)
            this.setState({ isHighlighted: true })
          }
        }}
        onMouseLeave={() => {
          if (onMouseLeave) {
            onMouseLeave(id)
            this.setState({ isHighlighted: false })
          }
        }}
      >
        <Column>
          <TextTop
            ref={ref => {
              this.popoverForLongText(ref, title)
            }}
          >
            {title}
          </TextTop>
          <TextBottom
            name="deviceName"
            ref={ref => {
              this.popoverForLongText(ref, device)
            }}
          >
            {device}
          </TextBottom>
        </Column>
        <Status name="issueText">{statusTitle || status}</Status>
        <Image src={icon || transparentImg} name="issueIcon" />
      </IssueContainer>
    )
  }

  render() {
    const { status } = this.props.issue
    const { color, icon } = getAdditionalElementsForStatus(status)
    const cell = this.renderCell(icon)

    return (
      <DropdownButton
        issue={this.props.issue}
        color={color}
        openPosition={this.props.openPosition}
      >
        {cell}
      </DropdownButton>
    )
  }
}

Issue.propTypes = {
  issue: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  openPosition: PropTypes.func.isRequired,
}

export default Issue
