import { Record, List } from 'immutable'
import moment from 'moment'
import { createUsers } from '../UsersPage/users'

export const Key = new Record({
  id: null,
  createdAt: null,
  updatedAt: null,
  storeId: null,
  serialNumber: null,
  status: null,
  expirationDate: null,
  showExpirationDate: null,
  // user
  userName: null,
  userId: null,
  // user for modal
  user: null,
  // loading
  loading: null,
  backupKey: null,
})

export function createKeys(physicalKeys) {
  const { physicalKeys: keys } = physicalKeys || {}
  const usersExtended = createUsers(physicalKeys || {})
  return List(
    (keys || []).map((data) => {
      const user =
        (usersExtended || []).find((obj) => obj.id === data.userId) || {}
      const createdAt = data.createdAt
      const updatedAt = data.updatedAt
      const attrs = {
        id: data.id,
        createdAt:
          createdAt &&
          moment(createdAt)
            .utcOffset(0)
            .format('MM/DD/YYYY'),
        updatedAt:
          updatedAt &&
          moment(updatedAt)
            .utcOffset(0)
            .format('MM/DD/YYYY H:mm'),
        storeId: data.storeId,
        serialNumber: data.serialNumber,
        status: data.status,
        // user
        userName: `${user.firstName || ''} ${user.lastName || ''}`,
        userId: user.id,
        expirationDate: data.expirationDate,
        showExpirationDate: data.showExpirationDate,
        // user for modal
        user,
        // loading
        loading: false,
        backupKey: data.backupKey,
      }
      return new Key(attrs)
    })
  )
}

export function createKey(attrs) {
  return new Key(attrs)
}
