import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InfoIcon from '../../../../images/svg/buttons/info.svg'
import Checkbox from '../../../Switch'

const Container = styled.div``
const StyledCheckbox = styled(Checkbox)`
  top: 6px;
`

const Label = styled.span`
  color: ${({ disabled }) => (disabled ? '#666;' : '#4E92DF;')};
  font-size: 14px;
  line-height: 17px;
  height: 17px;
  vertical-align: text-top;
`

class ShowBackupKeys extends React.PureComponent {
  infoForShowKeys() {
    const { infoMsg } = this.props
    const text = this.tooltipText(infoMsg)
    return (
      <img
        ref={(ref) => this.tooltip(ref, text)}
        className="ml-2 mb-2"
        src={InfoIcon}
        height="26"
        alt="Aspect ratio"
      />
    )
  }

  tooltip = (element, msg) => {
    $(element) // eslint-disable-line
      .popover('dispose')
      .popover({
        placement: 'bottom',
        trigger: 'hover focus',
        html: true,
        content: msg,
      })
  }

  tooltipText = () => this.props.message

  render() {
    const { disabled, checked, onChange } = this.props
    return (
      <Container className="d-inline-block">
        {disabled && (
          <React.Fragment>
            <span
              ref={(ref) => (this.container = ref)}
              className="align-items-center d-inline-flex"
            />
            {this.infoForShowKeys()}
          </React.Fragment>
        )}
        <Label disabled={disabled} className="mx-2">
          SHOW BACKUP KEYS
        </Label>
        <StyledCheckbox
          id="show-backup-keys-switch"
          onChange={(checked) => onChange(checked)}
          checked={checked && !disabled}
          disabled={disabled}
        />
      </Container>
    )
  }
}

ShowBackupKeys.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  message: PropTypes.string.isRequired,
}

export default ShowBackupKeys
