/**
 * @module Sagas/User
 * @desc User
 */

import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  signIn,
  signOut,
  adminSignIn,
  getOrganization,
  getRegions,
  fwManagementSignIn,
} from '../../../api'
import { ActionTypes } from '../constants'
import { makeSelectToken } from '../selectors'
import { restoreUser, saveUser, destroyUser } from '../localAuth'
import { resetOrm } from '../actions/orm'
import { loginSuccess } from '../actions/user'
import { fixApiRolesCache } from '../../../utils/mtiUtils'
import { storeImages, loadDataForOrganization } from '../actions'
import { errorToast } from '../../../utils/utils'

// const noPermissionText =
//   "You don't have appropriate rights to access Adming App.\nPlease contact your corporate manager."

/**
 * User login
 * @param payload
 */
export function* login({ payload }) {
  try {
    const data = yield call(signIn, payload)
    console.log('login', data)
    const {
      authentications: [{ token, expiresAt, tokenId }],
      users: [
        {
          id,
          firstName,
          lastName,
          phoneNumber,
          employeeIdentifier,
          rolesCache,
          avatarId,
        },
      ],
      organizations,
      images,
    } = data
    const { id: organizationId, name: organizationName } = (organizations || [
      {},
    ])[0]
    const roles = fixApiRolesCache(rolesCache)
    console.warn('roles', roles)

    let regions
    try {
      regions = yield call(getRegions, token)
    } catch (error) {
      console.error(error)
      errorToast('Get regions failed')
    }

    let orgId
    let orgName
    if (!organizationId) {
      try {
        const { organizations: orgs } = yield call(getOrganization, token)
        const org = (orgs || [{}])[0]
        orgId = org.id
        orgName = org.name
      } catch (error) {
        console.error(error)
        errorToast('Get organization failed')
      }
    }

    yield images && put(storeImages(images))
    const avatar = images && images.find(i => i.id === avatarId)

    const user = {
      id,
      token,
      tokenId,
      expiresAt,
      phoneNumber,
      firstName,
      lastName,
      employeeIdentifier,
      avatarId,
      avatar,
      name: `${firstName} ${lastName}`,
      mtiRoles: roles,
      regions: (regions || {}).regions,
      organizationId: organizationId || orgId,
      organizationName: organizationName || orgName,
    }

    saveUser(user)
    yield put(loginSuccess(user))
    yield put(loadDataForOrganization())
  } catch (err) {
    console.error(err)
    destroyUser()
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: 'Login failed, try again',
    })
  }
}

/**
 * Admin login
 * @param payload
 */
export function* adminLogin({ payload }) {
  try {
    const data = yield call(adminSignIn, payload)
    console.log('Admin Login ---->', data)
    const {
      authentications: [{ token, expiresAt, tokenId }],
      adminUsers: [
        {
          id,
          firstName,
          lastName,
          avatarId,
          rolesCache = [
            {
              name: 'mti_admin',
              storeId: null,
              resourceId: -100,
              resourceType: 'MTI',
            },
          ],
        },
      ], // phoneNumber, employeeIdentifier }],
      images,
      // organizations,
    } = data
    const roles = fixApiRolesCache(rolesCache)
    console.warn('roles', roles)
    // FW Management Authentication

    let saltAuthData = {}
    try {
      const authResult = yield call(fwManagementSignIn, token)
      saltAuthData = authResult.fwManagementAuthentications[0]
    } catch (e) {
      //
    }

    // const { id: organizationId, name: organizationName } = (organizations || [
    // {},
    // ])[0]

    // TODO: Good place to check if user has admin app access
    //    if (!hasAdminAppAccess) { // deprecated name adminAppAccess
    //      destroyUser()
    //      yield put({
    //        type: ActionTypes.USER_LOGIN_FAILURE,
    //        payload: noPermissionText,
    //      })
    //      return
    //    }

    // let mtiRoles
    // try {
    //   mtiRoles = yield call(getPersonaeAssignedTo, token, id)
    //   console.log('Roles: ', mtiRoles)
    // } catch (error) {
    //   errorToast('Get personae failed')
    //   console.error(error)
    // }

    // let orgId
    // let orgName
    // if (!organizationId) {
    //   try {
    //     const { organizations: orgs } = yield call(getOrganization, token)
    //     const org = (orgs || [{}])[0]
    //     orgId = org.id
    //     orgName = org.name
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }

    yield images && put(storeImages(images))
    const avatar = images && images.find(i => i.id === avatarId)

    const user = {
      id,
      token,
      tokenId,
      expiresAt,
      avatarId,
      avatar,
      saltToken: saltAuthData.token,
      saltTokenExpiresAt: saltAuthData.expiresAt,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      mtiRoles: roles,
    }

    saveUser(user)
    yield put(loginSuccess(user))
  } catch (err) {
    console.error(err)
    destroyUser()
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: 'Login failed, try again',
    })
  }
}

/**
 * User Logout
 */
export function* logout() {
  try {
    const token = yield select(makeSelectToken())
    if (token) {
      yield call(signOut, token)
    }
    yield put(resetOrm())
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    })
  } catch (err) {
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: 'Logout failed, try again',
    })
    errorToast('Logout failed')
  } finally {
    destroyUser()
  }
}

/**
 * Restore user auth object from local storage
 */
export function* restoreUserAuth() {
  try {
    const user = restoreUser()
    if (user) {
      yield put({
        type: ActionTypes.USER_RESTORE_SUCCESS,
        payload: user,
      })
    } else {
      yield put({
        type: ActionTypes.USER_RESTORE_FAILURE,
      })
    }
    const { mtiRoles } = user || {}
    const isMtiAdmin =
      (mtiRoles || []).filter(({ name }) => name === 'MTI Admin').length > 0
    if (isMtiAdmin && window.location.pathname === '/') return

    yield put(loadDataForOrganization())
  } catch (e) {
    console.error(e)
    destroyUser()
    yield put({
      type: ActionTypes.USER_RESTORE_FAILURE,
    })
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_RESTORE_REQUEST, restoreUserAuth),
    takeLatest(ActionTypes.USER_LOGIN_REQUEST, login),
    takeLatest(ActionTypes.ADMIN_LOGIN_REQUEST, adminLogin),
    takeLatest(ActionTypes.USER_LOGOUT_REQUEST, logout),
  ])
}
