import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { ExportToCsv } from 'export-to-csv'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import DatePicker from 'react-datepicker/es'
import moment from 'moment'
import {
  userDetailsAction,
  openModal,
  closeModal,
  notifyModal,
} from '../App/actions'
import { makeSelectMtiPermissionAnyResource } from '../App/selectors'
import reportsReducer from './reducer'
import reportsSaga from './sagas'
import { loadReportsAsync, asyncLoadingCancel } from './actions'
import {
  makeSelectReports,
  makeSelectAsyncLoadingStatus,
  makeSelectAsyncLoadingErrors,
} from './selectors'
import {
  makeSelectCombination,
  makeSelectDate,
  // makeSelectStoresLoading,
} from '../ExceptionsPage/CreateFiltersModal/selectors'
import {
  makeSelectLoading as makeSelectStoresLoading,
  makeSelectStores,
} from '../StoresPage/selectors'
import filterReducer from '../ExceptionsPage/CreateFiltersModal/reducer'
import ReportsPageScreen from './index.screen'
import UserManagement from '../UsersPage/userManagement'
import CheckboxFilter from '../ExceptionsPage/CreateFiltersModal/CheckboxFilter'
import CreateFiltersModal from '../ExceptionsPage/CreateFiltersModal'
import { resetStoreToDefaults as resetFiltersInModal } from '../ExceptionsPage/CreateFiltersModal/actions'
import { CustomInput } from '../../components/DatePickerInput/CustomInput'
import { CSVOptions } from '../../utils/utils'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class ReportsPage extends React.Component {
  componentDidMount() {
    const { hasViewReportsPermission, history } = this.props
    if (
      this.noPermission(
        hasViewReportsPermission,
        permText('the Historical Logs')
      )
    ) {
      history.push('/')
      return
    }

    if (this.props.stores && this.props.stores.length) {
      this.onCreateFilter()
    }
  }

  componentWillUnmount() {
    this.props.resetFiltersInModal()
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loading && prevProps.loading) {
      this.onCreateFilter()
    }
  }

  onProfile = (item) => {
    const { user } = item
    const { userDetailsAction: doUserDetailsAction } = this.props
    doUserDetailsAction(user)
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  exportAsCSV = () => {
    const data = this.onExportExceptions()
    const options = CSVOptions(this.fileName())
    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(data)
  }

  onExportExceptions = () => {
    const { reports: data } = this.props
    const exceptionsData = data.map((r) => [
      r.id,
      r.fixtureId,
      r.storeName,
      r.fixtureName,
      r.positionName,
      r.resourceType,
      r.userName,
      r.eventTypeMessage,
      r.occurredAt,
      r.duration,
    ])

    const columnName = [
      'id',
      'fixtureId',
      'storeName',
      'fixtureName',
      'positionName',
      'resourceType',
      'userName',
      'eventTypeMessage',
      'occurredAt',
      'duration',
    ]

    exceptionsData.unshift(columnName)

    return exceptionsData
  }

  fileName() {
    const date = moment().format('MM-DD-YYYY-HH-mm-ss')
    return `Historical_Logs_${date}`
  }

  onCreateFilter = () => {
    const {
      date,
      openModal: doOpenModal,
      closeModal: doCloseModal,
    } = this.props

    const initialCombination = {
      health: false,
      compliance: false,
      operations: false,
      marketing: false,
    }
    const { startDate, endDate } = date || {}

    doOpenModal({
      id: 'create-filters',
      type: 'bootstrap',
      content: (
        <CreateFiltersModal
          initialCombination={initialCombination}
          combinationNodes={this.combination}
          dateFiltersNode={this.dateFilter}
          dateFilters={{ startDate, endDate }}
          onClose={() => {
            doCloseModal({ id: 'create-filters' })
          }}
          onConfirm={() => {
            this.props.loadReportsAsync()
          }}
          id={'create-filters'}
        />
      ),
    })
  }

  combination = (combination, onChange) => (
    <React.Fragment>
      <div className="form-group mb-2">
        <CheckboxFilter
          name="health"
          label="Health"
          isChecked={combination.health}
          onChange={onChange}
        />
      </div>
      <div className="form-group mb-2 mx-sm-3">
        <CheckboxFilter
          name="operations"
          label="Operations"
          isChecked={combination.operations}
          onChange={onChange}
        />
      </div>
      <div className="form-group mb-2 mx-sm-3">
        <CheckboxFilter
          name="compliance"
          label="Compliance"
          isChecked={combination.compliance}
          onChange={onChange}
        />
      </div>
      <div className="form-group mb-2">
        <CheckboxFilter
          name="marketing"
          label="Marketing"
          isChecked={combination.marketing}
          onChange={onChange}
        />
      </div>
    </React.Fragment>
  )

  dateFilter = (startDate, endDate, onChangeStart, onChangeEnd) => (
    <React.Fragment>
      <div>
        <DatePicker
          customInput={<CustomInput />}
          style={{ width: '110px' }}
          maxDate={moment()}
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          onChange={(d) => onChangeStart({ startDate: d })}
        />
      </div>
      <div className="mx-sm-3">
        <DatePicker
          customInput={<CustomInput />}
          style={{ width: '110px' }}
          maxDate={moment()}
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          onChange={(d) => onChangeEnd({ endDate: d })}
        />
      </div>
    </React.Fragment>
  )

  render() {
    const {
      reports,
      status,
      errors,
      date,
      asyncLoadingCancel: onAsyncLoadingCancel,
      loading,
    } = this.props
    if (status.currentName) {
      console.warn(
        `Loading ${status.currentName} (${status.currentNumber} of ${
          status.total
        })`
      )
    }

    if (errors && errors.length) {
      errors.map((e) => {
        const { error = {} } = e
        const msg = error.message ? error.message : (error.error || {}).message
        console.warn(msg, e.storeName)
        return { name: e.storeName, message: msg }
      })
    }

    return (
      <div>
        <UserManagement />
        <ReportsPageScreen
          reports={reports}
          date={date}
          onAsyncLoadingCancel={
            status.currentName ? () => onAsyncLoadingCancel() : undefined
          }
          loading={loading}
          csvData={this.onExportExceptions()}
          onExportAsCSV={this.exportAsCSV}
          onProfile={this.onProfile}
          createFilter={this.onCreateFilter}
          loadingStatus={status}
          errors={errors}
        />
      </div>
    )
  }
}

ReportsPage.propTypes = {
  loadReportsAsync: PropTypes.func.isRequired,
  asyncLoadingCancel: PropTypes.func.isRequired,
  reports: PropTypes.array,
  status: PropTypes.object,
  errors: PropTypes.array,
  loading: PropTypes.bool,
  combination: PropTypes.object,
  date: PropTypes.object,
  notifyModal: PropTypes.func,
  userDetailsAction: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  resetFiltersInModal: PropTypes.func,
  hasViewReportsPermission: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  reports: makeSelectReports(),
  status: makeSelectAsyncLoadingStatus(),
  errors: makeSelectAsyncLoadingErrors(),
  loading: makeSelectStoresLoading(),
  stores: makeSelectStores(),
  combination: makeSelectCombination(),
  date: makeSelectDate(),
  hasViewReportsPermission: makeSelectMtiPermissionAnyResource(
    'Saved Report: Index (list) Records'
  ),
})

const mapDispatchToProps = {
  loadReportsAsync,
  asyncLoadingCancel,
  userDetailsAction,
  openModal,
  closeModal,
  notifyModal,
  resetFiltersInModal,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReportsReducer = injectReducer({
  key: 'reports',
  reducer: reportsReducer,
})
const withReportsSaga = injectSaga({ key: 'reports', saga: reportsSaga })

const withFilterReducer = injectReducer({
  key: 'resourceFilter',
  reducer: filterReducer,
})

export default compose(
  withReportsReducer,
  withReportsSaga,
  withFilterReducer,
  withConnect
)(ReportsPage)
