import { all, takeLatest, select, put, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { makeSelectToken, makeSelectSchedule } from '../selectors'
import { ActionTypes } from '../constants'
import {
  deleteScheduleFailed,
  deleteScheduleFinished,
  deleteSchedulePending,
  patchScheduleFailed,
  patchScheduleFulfilled,
  patchSchedulePending,
  postScheduleFailed,
  postScheduleFulfilled,
  postSchedulePending,
  scheduleDetailsAction,
} from '../actions'

import { postSchedule, deleteScheduleById, patchSchedule } from '../../../api'
import { errorToast, successToast } from '../../../utils/utils'
import { makeSelectSchedules } from '../../SchedulesPage/selectors'
import { loadSchedules } from '../../SchedulesPage/sagas'

export function* post({ payload }) {
  const { storeId } = payload
  const schedule = yield select(makeSelectSchedule())
  if (schedule) {
    const token = yield select(makeSelectToken())
    try {
      yield put(postSchedulePending())
      const data = yield call(postSchedule, token, schedule, storeId)
      const id = data.schedules[0].id
      yield put(postScheduleFulfilled())
      yield call(loadSchedules, { payload })
      const schedulesStored = yield select(makeSelectSchedules())
      const scheduleStored = schedulesStored.find(({ id: uId }) => uId === id)
      yield delay(500)
      yield put(scheduleDetailsAction(scheduleStored)) // Open schedule details modal
      successToast('Schedule Created')
    } catch (error) {
      // react-boilerplate way to get the error body
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(postScheduleFailed(errorObj))
      errorToast('Create schedule failed')
    }
  }

  yield select(makeSelectToken())
}

export function* deleteSchedule({ payload }) {
  const token = yield select(makeSelectToken())
  try {
    yield put(deleteSchedulePending())
    const data = yield call(deleteScheduleById, token, payload.id)
    successToast('Schedule Deleted')
    yield put(deleteScheduleFinished(data))
    yield call(loadSchedules, { payload })
  } catch (error) {
    yield put(deleteScheduleFailed(error))
    errorToast('Delete schedule failed')
  }
}

export function* patch({ payload }) {
  const schedule = yield select(makeSelectSchedule())
  if (schedule) {
    const token = yield select(makeSelectToken())
    try {
      yield put(patchSchedulePending())
      yield call(patchSchedule, token, payload.id, schedule)
      yield call(loadSchedules, { payload })
      yield put(patchScheduleFulfilled())
      const schedulesStored = yield select(makeSelectSchedules())
      const scheduleStored = schedulesStored.find(
        ({ id: uId }) => uId === payload.id
      )
      yield delay(500)
      yield put(scheduleDetailsAction(scheduleStored)) // Open schedule details modal
      successToast('Schedule Updated')
    } catch (error) {
      // react-boilerplate way to get the error body
      console.log('error', error)
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(patchScheduleFailed(errorObj))
      errorToast('Update schedule failed')
    }
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.POST_SCHEDULE, post),
    yield takeLatest(ActionTypes.DELETE_SCHEDULE, deleteSchedule),
    yield takeLatest(ActionTypes.PATCH_SCHEDULE, patch),
  ])
}
