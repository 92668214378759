import {
  all,
  takeEvery,
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects'
import {
  getStoreById,
  pingHub as pingHubRequest,
  runSaltCommandWithArg,
} from '../../../api'
import { ActionTypes } from '../constants'
import {
  fetchHubFailed,
  fetchHubPending,
  fetchHubFulfilled,
  pingHubPending,
  pingHubFulfilled,
  pingHubFailed,
  fetchHubStatusPending,
  fetchHubStatusFulfilled,
  fetchHubStatusFailed,
} from '../actions'
import {
  makeSelectToken,
  makeSelectSaltToken,
  makeSelectHubByStore,
} from '../selectors'

const COMMAND_TO_PRINT_OTAU_STATUS_FILE =
  'cat /opt/gateway/firmware/otau_status.txt'

function* getHub({ payload: { storeId } }) {
  const token = yield select(makeSelectToken())
  const saltToken = yield select(makeSelectSaltToken())
  try {
    let hub = yield select(makeSelectHubByStore(storeId))
    if (!hub) {
      yield put(fetchHubPending(storeId))
      const data = yield call(getStoreById, token, storeId, ['connect_hubs'])
      const { stores: [storeData] } = data

      if (storeData.connectHubIds.length > 0) {
        // TODO: rewrite logic to support multiple hubs for one store (currently 1 store with 1 hub)
        const { connectHubIds: [hubId] } = storeData
        const { connectHubs } = data
        hub = connectHubs.find((h) => h.id === hubId)
      } else {
        yield put(fetchHubFailed(storeId, new Error('No Hub')))
        return
      }
    } else {
      yield put(fetchHubPending(hub.storeId))
    }

    // Get Ping Status
    const { return: [pingData] } = yield call(
      pingHubRequest,
      saltToken,
      hub.identifier
    )
    const pingStatus = Object.values(pingData)[0]

    // Get OTAU Status
    const { return: [OTAU] } = yield call(
      runSaltCommandWithArg,
      saltToken,
      hub.identifier,
      COMMAND_TO_PRINT_OTAU_STATUS_FILE
    )
    const otauStatus = Object.values(OTAU)[0]

    const hubData = { [hub.identifier]: { ...hub, pingStatus, otauStatus } }
    yield put(fetchHubFulfilled(storeId, hubData))
  } catch (error) {
    yield put(fetchHubFailed(storeId, error))
  }
}

function* pingHub({ payload: { hubIdentifier } }) {
  const saltToken = yield select(makeSelectSaltToken())
  try {
    yield put(pingHubPending(hubIdentifier))
    const { return: [data] } = yield call(
      pingHubRequest,
      saltToken,
      hubIdentifier
    )

    const pingStatus = Object.values(data)[0]
    yield put(pingHubFulfilled(hubIdentifier, pingStatus))
  } catch (error) {
    yield put(pingHubFailed(hubIdentifier, error))
  }
}

function* fetchHubStatus({ payload: { hubIdentifier } }) {
  const saltToken = yield select(makeSelectSaltToken())
  try {
    yield put(fetchHubStatusPending())
    const { return: [data] } = yield call(
      runSaltCommandWithArg,
      saltToken,
      hubIdentifier,
      COMMAND_TO_PRINT_OTAU_STATUS_FILE
    )

    const otauStatus = Object.values(data)[0]
    yield put(fetchHubStatusFulfilled(hubIdentifier, otauStatus))
  } catch (error) {
    yield put(fetchHubStatusFailed(error))
  }
}

function* rebootHub({ payload: { hubIdentifier } }) {
  const saltToken = yield select(makeSelectSaltToken())
  try {
    const commandArg = 'sudo reboot'
    const { return: [reboot] } = yield call(
      runSaltCommandWithArg,
      saltToken,
      hubIdentifier,
      commandArg
    )
    console.log('Reboot: ', reboot)
  } catch (error) {
    console.error('Reboot error: ', error)
  }
}

function* restartLode({ payload: { hubIdentifier } }) {
  const saltToken = yield select(makeSelectSaltToken())
  try {
    const commandArg = 'sudo systemctl restart lode'
    const { return: [restartLodeData] } = yield call(
      runSaltCommandWithArg,
      saltToken,
      hubIdentifier,
      commandArg
    )
    console.log('Restart lode', restartLodeData)
  } catch (error) {
    console.error('Reboot error: ', error)
  }
}

export default function* root() {
  yield all([
    yield takeEvery(ActionTypes.FETCH_HUB, getHub),
    yield takeLatest(ActionTypes.PING_HUB, pingHub),
    yield takeLatest(ActionTypes.FETCH_HUB_STATUS, fetchHubStatus),
    yield takeLatest(ActionTypes.REBOOT_HUB, rebootHub),
    yield takeLatest(ActionTypes.RESTART_LODE, restartLode),
  ])
}
