import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { modelActionCreators as mac } from 'mti-jsclient-shared'
import { upsert } from './ormSaga'
import {
  postRegion,
  deleteRegionById,
  patchRegion,
  patchStore,
} from '../../../api'
import {
  makeSelectToken,
  makeSelectRegion,
  makeSelectRegions,
} from '../selectors'
import { ActionTypes } from '../constants'
import {
  postRegionPending,
  postRegionFulfilled,
  postRegionFailed,
  deleteRegionPending,
  deleteRegionFinished,
  deleteRegionFailed,
  patchRegionPending,
  patchRegionFulfilled,
  patchRegionFailed,
  regionDetailsAction,
} from '../actions'
import { errorToast, successToast } from '../../../utils/utils'
import { loadRegions } from '../../RegionsPage/sagas'

export function* post() {
  const region = yield select(makeSelectRegion())
  if (region) {
    const token = yield select(makeSelectToken())
    try {
      yield put(postRegionPending())
      const regionsResponse = yield call(postRegion, token, region)
      const newRegionId = regionsResponse.regions[0].id
      yield all(
        region.assignedStores.map((store) =>
          call(
            patchStore,
            token,
            store.id,
            Object.assign(store, { regionId: newRegionId })
          )
        )
      )
      yield call(loadRegions)
      yield put(postRegionFulfilled())
      const regionsStored = yield select(makeSelectRegions())
      const regionStored = regionsStored.find(
        ({ id: uId }) => uId === newRegionId
      )

      yield put(regionDetailsAction(regionStored)) // Open region details modal
      successToast('Region Created')
    } catch (error) {
      // react-boilerplate way to get the error body
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(postRegionFailed(errorObj))
      errorToast('Create region failed')
    }
  }
}

export function* deleteRegion({ payload }) {
  const token = yield select(makeSelectToken())
  try {
    yield put(deleteRegionPending())
    const data = yield call(deleteRegionById, token, payload.id)
    yield put(deleteRegionFinished(data))
    successToast('Region Deleted')
    yield all(
      payload.stores.map((store) =>
        call(patchStore, token, store.id, { regionId: null })
      )
    )
    yield call(loadRegions)
  } catch (error) {
    yield put(deleteRegionFailed(error))
    errorToast('Delete region failed')
  }
}

export function* patch({ payload: id }) {
  const region = yield select(makeSelectRegion())
  if (region) {
    const token = yield select(makeSelectToken())
    try {
      yield put(patchRegionPending())
      yield all(
        region.assignedStores.map((store) => patchAndUpsert(store, token))
      )
      yield all(
        region.removedStores.map((store) => patchAndUpsert(store, token))
      )
      const patchedRegion = yield call(patchRegion, token, id, region)
      yield put(patchRegionFulfilled(patchedRegion))
      const regionsStored = yield select(makeSelectRegions())
      const regionStored = regionsStored.find(({ id: uId }) => uId === id)
      yield put(regionDetailsAction(regionStored)) // Open region details modal
      successToast('Region Updated')
    } catch (error) {
      // react-boilerplate way to get the error body
      console.log('error', error)
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(patchRegionFailed(errorObj))
      errorToast('Update region failed')
    }
  }
}

function* patchAndUpsert(store, token) {
  const data = yield call(patchStore, token, store.id, store)
  yield put(mac.updateStore(data.stores[0]))
  yield call(upsert, { payload: data })
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.POST_REGION, post),
    yield takeLatest(ActionTypes.DELETE_REGION_ACT, deleteRegion),
    yield takeLatest(ActionTypes.PATCH_REGION, patch),
  ])
}
