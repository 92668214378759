import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ModalHeader } from '../../styles/styled'

const intlPath = (string) => `components.idleTimer.${string}`

class IdleTimerModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = { logoutTime: props.logoutTime }
  }

  componentDidUpdate() {
    if (this.state.logoutTime <= 0) {
      this.hideModal()
      this.clearLogoutTimer()
      this.props.logout()
    }
  }

  componentDidMount() {
    this.startLogoutTimer()
  }

  clearLogoutTimer = () => {
    clearInterval(this.logoutTimer)
  }

  startLogoutTimer = () => {
    this.logoutTimer = setInterval(() => {
      this.setState((state) => ({
        logoutTime: state.logoutTime - 1,
      }))
    }, 1000)
  }

  hideModal = () => {
    $(`#modal-${this.props.id}`).modal('hide') // eslint-disable-line
  }

  onCloseModal = () => {
    this.hideModal()
    this.clearLogoutTimer()
  }

  render() {
    const { logout, onClose } = this.props

    return (
      <div className="modal-content">
        <ModalHeader className="modal-header border-bottom">
          <FormattedMessage id={intlPath('title')} />
        </ModalHeader>
        <div className="modal-body">
          <FormattedMessage
            id={intlPath('inactivityMessage')}
            values={{ timer: this.state.logoutTime }}
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              this.onCloseModal()
              logout()
            }}
          >
            <FormattedMessage id={intlPath('logoutAction')} />
          </button>
          <button
            type="button"
            className="btn btn-primary btn-raised"
            onClick={() => {
              this.onCloseModal()
              onClose()
            }}
          >
            <FormattedMessage id={intlPath('stayLoggedIn')} />
          </button>
        </div>
      </div>
    )
  }
}

IdleTimerModal.propTypes = {
  id: PropTypes.string,
  logout: PropTypes.func,
  onClose: PropTypes.func,
  logoutTime: PropTypes.number,
}

export default IdleTimerModal
