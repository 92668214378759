import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const BugsnagClient = (function() {
  if (process.env.BUGSNAG_API_KEY) {
    const client = bugsnag({
      apiKey: process.env.BUGSNAG_API_KEY,
      releaseStage: process.env.APP_ENV,
      appVersion: process.env.GIT_HASH,
      // beforeSend: function(report) {
      // TODO: Find a way to get the logged-in user's id
      // const userId = getUserIdSomehow()
      // if (userId != null) {
      //   report.user = { id: userId }
      // }
      // },
    })
    client.use(bugsnagReact, React)
    return client
  } else {
    return {
      notify: () => {
        /* no operation */
      },
    }
  }
})()

/* An example of how to pass caught errors to bugsnag:
import { BugsnagClient } from 'utils/bugsnag'
try {
  something.risky()
} catch (e) {
  BugsnagClient.notify(e)
}
*/

export { BugsnagClient }
