/*
 *
 * AreaCanvas reducer
 *
 */

import { fromJS } from 'immutable'
import { ActionTypes } from './constants'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import { getWidthHeight } from '../../components/AreaCanvas/fixture'
import { loadingType as loading } from '../../utils/utils'
import { toFabricFixtureSvi } from './utils'
import { applyTemplateForCanvasObject } from './utilsSave'

export const initialState = fromJS({
  selectedId: -1,
  canvasObject: {
    objects: [],
    background: '',
    isInitial: true,
  },
  screen: null,
  isStatic: true,
  prototypes: null,
  loading: loading.silent,
  selectedTemplate: undefined,
  fixtureToRemoveRules: null,
  template: null,
})

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.STORE_AREA_PROTOTYPES:
      return state.set('prototypes', payload)
    case ActionTypes.STORE_AREA_LOADING:
      return state.set('loading', payload)
    case ActionTypes.STORE_AREA_FB:
      return state.set('areaFb', payload)
    case ActionTypes.STORE_PARAMS:
      return state
        .set('screen', payload.screen)
        .set('isStatic', payload.isStatic)
    case ActionTypes.SAVE_AREA_FIXTURE: {
      const { selectedId } = payload
      const prevSelectedId = state.get('selectedId')
      if (prevSelectedId === selectedId) {
        return state.set('selectedId', -1)
      } else {
        return state
      }
    }
    case ActionTypes.POST_AREA_CANVAS_FINISHED:
      return state
        .set('loading', false)
        .set('failed', null)
        .set('isStatic', false)
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.update(
            objects.findIndex(
              (item) => item.get('id') === (payload.oldFxId || payload.fxId)
            ),
            (obj) => obj && obj.set('id', payload.fxId || obj.get('id'))
          )
        )
    //.set('selectedId', -1)
    case ActionTypes.POST_AREA_CANVAS_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.POST_AREA_CANVAS_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.AREA_CANVAS_FIXTURE_REMOVE:
      return state
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.filter((obj) => obj.get('id') !== payload.selectedId)
        )
        .set('selectedId', -1)
    case ActionTypes.FETCH_AREA_CANVAS_FULFILLED: {
      const {
        canvas: {
          canvasObject = {},
          canvasObject: { selectedId: selectedIdentifier } = {},
        } = {},
        selectedId: selId,
        isStatic,
      } = payload
      const selectedId =
        selId === undefined || selId === -1 ? selectedIdentifier : selId
      return state
        .set('canvasObject', fromJS(canvasObject))
        .set('selectedId', selectedId)
        .set('loading', false)
        .set('failed', null)
        .set('isStatic', isStatic)
        .set('fixtureToRemoveRules', null)
    }
    case ActionTypes.FETCH_AREA_CANVAS_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_AREA_CANVAS_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.AREA_CANVAS_APPLY_TEMPLATE:
      return state
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.update(
            objects.findIndex(
              (item) => item.get('id') === state.get('selectedId')
            ),
            (obj) =>
              fromJS(
                applyTemplateForCanvasObject(
                  obj.toJS(),
                  payload,
                  state.get('screen'),
                  state.get('isStatic'),
                  false,
                  state.get('prototypes'),
                  mtiJsclientShared
                )
              )
          )
        )
        .set('selectedTemplate', payload)
    case ActionTypes.AREA_CANVAS_CHANGED: {
      const isStaticPayload = payload.isStatic
      const prevSelectedId = state.get('selectedId')
      const isStatic = !isStaticPayload
        ? isStaticPayload
        : state.get('isStatic')
      // Deselect already selected fixtures
      const selectedId =
        prevSelectedId === payload.selectedId && isStatic
          ? -1
          : payload.selectedId

      return state
        .set('canvasObject', fromJS(payload.canvasObject))
        .set('selectedId', selectedId)
        .set('isStatic', isStatic)
    }
    case ActionTypes.AREA_CANVAS_NAME_CHANGED:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => transformFixtureName(obj, payload)
        )
      )
    case ActionTypes.AREA_CANVAS_FIXTURE_NAME_TOGGLED:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => transformFixtureToggleName(state, obj, payload)
        )
      )
    case ActionTypes.AREA_CANVAS_FIGURE_BEGIN_TRANSFORMATION:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => transformFixtureType(state, obj, payload)
        )
      )
    case ActionTypes.AREA_CANVAS_FIGURE_SIZE_CHANGED:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => transformFixtureSize(state, obj, payload)
        )
      )
    case ActionTypes.AREA_CANVAS_IS_FURNITURE_CHANGED:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => transformFixtureFurniture(state, obj, payload)
        )
      )
    case ActionTypes.AREA_CANVAS_FIGURE_BEGIN_CUSTOM_ROTATION:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => transformFixtureAngle(state, obj, payload)
        )
      )
    case ActionTypes.AREA_CANVAS_APPLY_FUTURE_TEMPLATE_UPDATES_TOGGLED:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => obj.set('applyFutureTemplateUpdates', payload)
        )
      )
    case ActionTypes.AREA_CANVAS_FIGURE_UNSELECT_TEMPLATE:
      return state
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.update(
            objects.findIndex(
              (item) => item.get('id') === state.get('selectedId')
            ),
            (obj) =>
              obj &&
              obj
                .set(
                  'createdFromParentId',
                  obj.get('parentId') || obj.get('createdFromParentId')
                )
                .set('parentId', null)
          )
        )
        .set('selectedTemplate', null)
    case ActionTypes.DELETE_ALL_COMPLIANCE_RULES:
      return state.set('fixtureToRemoveRules', payload.fixture)
    case ActionTypes.LOAD_FIXTURE_TEMPLATE_FULFILLED:
      return state.set('selectedTemplate', payload.template)
    case ActionTypes.RESET_FIXTURE_TEMPLATE:
      return state.set('selectedTemplate', null)
    default:
      return state
  }
}

function getTransformParams(state, obj) {
  const screen = state.get('screen')
  const isStatic = state.get('isStatic')
  const prototypes = state.get('prototypes')

  const objJS = obj.toJS()
  const {
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition,
  } = objJS

  const o = {
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition,
  }

  return { screen, isStatic, prototypes, o }
}

export function transformFixtureName(obj, payload) {
  return obj
    .set('name', payload)
    .updateIn(['objects'], (items) =>
      items.update(1, (item) =>
        item.set('fullText', payload).set('text', payload)
      )
    )
}

export function transformFixtureToggleName(state, obj, payload) {
  const { screen, isStatic, prototypes, o } = getTransformParams(state, obj)
  o.nameHasShown = payload

  const newObj = toFabricFixtureSvi(o, screen, isStatic, false, prototypes)
  return fromJS(newObj)
}

export function transformFixtureType(state, obj, payload) {
  const { screen, isStatic, prototypes, o } = getTransformParams(state, obj)
  o.layoutPosition.type = payload

  const { width, height, sizeType } = getWidthHeight(payload)

  const rWidth = width / screen.width
  const rHeight = height / screen.width
  o.layoutPosition.width = rWidth
  o.layoutPosition.height = rHeight
  o.layoutPosition.size = sizeType

  const newObj = toFabricFixtureSvi(o, screen, isStatic, false, prototypes)
  return fromJS(newObj)
}

export function transformFixtureSize(state, obj, payload) {
  const { screen, isStatic, prototypes, o } = getTransformParams(state, obj)
  o.layoutPosition.size = payload

  const { width, height } = getWidthHeight(o.layoutPosition.type, payload)

  const rWidth = width / screen.width
  const rHeight = height / screen.width
  o.layoutPosition.width = rWidth
  o.layoutPosition.height = rHeight

  const newObj = toFabricFixtureSvi(o, screen, isStatic, false, prototypes)
  return fromJS(newObj)
}

export function transformFixtureFurniture(state, obj, payload) {
  const { screen, isStatic, prototypes, o } = getTransformParams(state, obj)
  o.noninteractive = payload

  const newObj = toFabricFixtureSvi(o, screen, isStatic, false, prototypes)
  return fromJS(newObj)
}

export function transformFixtureAngle(state, obj, payload) {
  const { screen, isStatic, prototypes, o } = getTransformParams(state, obj)
  o.layoutPosition.theta = payload

  const newObj = toFabricFixtureSvi(o, screen, isStatic, false, prototypes)
  return fromJS(newObj)
}

export default reducer
