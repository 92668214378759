import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { hasAccess, getOrgTags } from '../../utils/mtiUtils'
import { ActionTypes } from '../App/constants'
import {
  openModal,
  closeModal,
  notifyModal,
  editStore,
  clearStore,
  clearTags,
  postTaggings,
  editTags,
  patchStore,
  postStore,
  resetStoreManagement,
} from '../App/actions'
import {
  makeSelectOrganization,
  makeSelectMtiPermissionAccessData,
  makeSelectMtiPermissionAnyResource,
  makeSelectHasMtiRoleAnyResource,
  makeSelectTags,
  makeSelectTagsEditIsLoading,
  makeSelectStoreManagement,
  makeSelectStoreDetails,
  makeSelectPatchLoading,
  makeSelectPatchFailed,
  makeSelectPostLoading,
  makeSelectPostFailed,
} from '../App/selectors'
import StoreDetailsModal from './StoreDetailsModal'
import CreateEditStoreModal from './CreateEditStoreModal'
import EditStoreTagsModal from './EditStoreTagsModal'
import { makeSelectRegions } from '../RegionsPage/selectors'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class StoreManagement extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      storeManagement,
      storeDetails,
      resetStoreManagement: doResetStoreManagement,
      patchStoreLoading,
      patchStoreFailed,
      postStoreLoading,
      postStoreFailed,
      editTagsLoading,
    } = this.props

    if (storeManagement === ActionTypes.STORE_DETAILS) {
      this.openStoreDetailsModal(storeDetails)
      doResetStoreManagement()
      return
    }
    if (storeManagement === ActionTypes.CREATE_STORE) {
      this.openCreateStoreModal()
      doResetStoreManagement()
      return
    }
    if (postStoreFailed && this.createStoreModal) {
      this.createStoreModal.setServerValidity(postStoreFailed)
    }

    if (patchStoreFailed && this.editStoreModal) {
      this.editStoreModal.setServerValidity(patchStoreFailed)
    }

    if (
      !postStoreLoading &&
      !postStoreFailed &&
      prevProps.postStoreLoading &&
      this.createStoreModal
    ) {
      this.createStoreModal.close()
    }

    if (
      !patchStoreLoading &&
      !patchStoreFailed &&
      this.props.patchStoreLoading &&
      this.editStoreModal
    ) {
      this.editStoreModal.close()
    }

    if (editTagsLoading && !prevProps.editTagsLoading && this.editTagsModal) {
      this.editTagsModal.setLoading(true)
    }
  }

  storeEditLoading = () =>
    this.props.postStoreLoading || this.props.patchStoreLoading

  openStoreDetailsModal = (store) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      clearStore: doClearStore,
      clearTags: doClearTags,
      organization,
      showStorePermissionData,
      listTaggingsPermissionData,
      hasRegionalManagerRole,
      onClose,
      hideButtons,
    } = this.props
    const hasShowStorePermission = hasAccess({
      ...showStorePermissionData,
      resourceId: store.id,
    })
    if (this.noPermission(hasShowStorePermission, permText('View Store'))) {
      return
    }
    const org = organization ? organization.organizations[0] : undefined
    const storeDetailsId = 'store-details'
    this.setState({ selectedStoreId: store.id })

    // Taggings view mode permissions:
    const hasListTaggingsPermission = hasAccess({
      ...listTaggingsPermissionData,
      resourceId: store.id,
    })

    doClearStore()
    doOpenModal({
      id: storeDetailsId,
      type: 'bootstrap',
      content: (
        <StoreDetailsModal
          id={storeDetailsId}
          store={store}
          organization={org}
          onEditStorePressed={() => {
            doCloseModal({ id: storeDetailsId })
            doClearStore()
            setTimeout(() => this.openEditExistingStoreModal(store), 500)
          }}
          onClose={() => {
            doCloseModal({ id: storeDetailsId })

            if (onClose) {
              onClose()
            }
          }}
          onRedirectToLayout={(path) => {
            doCloseModal({ id: storeDetailsId })
            setTimeout(() => this.redirectToStoreLayout(store, path), 500)
          }}
          onRedirectToSecuredProducts={(path) => {
            doCloseModal({ id: storeDetailsId })
            setTimeout(
              () => this.redirectToStoreSecuredProducts(store, path),
              500
            )
          }}
          onRedirectToTableView={(path) => {
            doCloseModal({ id: storeDetailsId })
            setTimeout(
              () => this.props.history.push(path),
              500
            )
          }}
          onEditTags={() => {
            doCloseModal({ id: 'store-details' })
            doClearTags()
            setTimeout(() => this.openEditStoreTagsModal(store), 500)
          }}
          hasListTaggingsPermission={hasListTaggingsPermission}
          hasRegionalManagerRole={hasRegionalManagerRole}
          hideButtons={hideButtons}
        />
      ),
    })
  }

  openEditStoreTagsModal = (store) => {
    const {
      tags,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      postTaggings: doPostTaggings,
      editTags: doEditTags,
      createTaggingsPermissionData,
      showTaggingsPermissionData,
      updateTaggingsPermissionData,
      destroyTaggingsPermissionData,
    } = this.props
    // Taggings edit mode permissions:
    const hasCreateTaggingsPermission = hasAccess({
      ...createTaggingsPermissionData,
      resourceId: store.id,
    })
    const hasShowTaggingsPermission = hasAccess({
      ...showTaggingsPermissionData,
      resourceId: store.id,
    })
    const hasUpdateTaggingsPermission = hasAccess({
      ...updateTaggingsPermissionData,
      resourceId: store.id,
    })
    const hasDestroyTaggingsPermission = hasAccess({
      ...destroyTaggingsPermissionData,
      resourceId: store.id,
    })
    const hasEditTaggingsAccess =
      hasCreateTaggingsPermission &&
      hasShowTaggingsPermission &&
      hasUpdateTaggingsPermission &&
      hasDestroyTaggingsPermission
    if (this.noPermission(hasEditTaggingsAccess, permText('Edit Taggings'))) {
      return
    }

    doOpenModal({
      id: 'edit-store-tags',
      type: 'bootstrap',
      content: (
        <EditStoreTagsModal
          ref={(ref) => (this.editTagsModal = ref)}
          id={'edit-store-tags'}
          storeId={store.id}
          taggings={store.tags}
          tags={getOrgTags(tags)}
          onChange={(o) => doEditTags(o)}
          title={`Edit Tags (${store.name})`}
          onClose={() => doCloseModal({ id: 'edit-store-tags' })}
          onConfirm={() => doPostTaggings()}
        />
      ),
    })
  }

  openEditExistingStoreModal = (store) => {
    const {
      organization,
      regions,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      editStore: doEditStore,
      patchStore: doPatchStore,
      updateStorePermissionData,
      hasMtiAdminRole,
      hasOrganizationAdminRole,
      hasRegionalManagerRole,
      hasStoreManagerRole,
    } = this.props
    const hasUpdateStorePermission = hasAccess({
      ...updateStorePermissionData,
      resourceId: store.id,
    })
    if (this.noPermission(hasUpdateStorePermission, permText('Edit Store'))) {
      return
    }

    const org = organization ? organization.organizations[0] : undefined
    const editStoreId = 'edit-store'

    this.initAddress(store)
    doOpenModal({
      id: editStoreId,
      type: 'bootstrap',
      content: (
        <CreateEditStoreModal
          ref={(ref) => (this.editStoreModal = ref)}
          title={store.name}
          id={editStoreId}
          store={store}
          regions={regions}
          organization={org}
          onChange={(o) => doEditStore(o)}
          onConfirm={() => doPatchStore(store.id)}
          onClose={() => doCloseModal({ id: editStoreId })}
          storeEditLoading={this.storeEditLoading}
          hasMtiAdminRole={hasMtiAdminRole}
          hasOrganizationAdminRole={hasOrganizationAdminRole}
          hasRegionalManagerRole={hasRegionalManagerRole}
          hasStoreManagerRole={hasStoreManagerRole}
        />
      ),
    })
  }

  // TODO: check PATCH api/stores, is it required to send address if there was no changes?
  initAddress = (store) => {
    const { editStore: doEditStore } = this.props
    const {
      address: { country, state, address1, city, address2, address3, zip } = {},
    } = store
    doEditStore({ address: { country } })
    doEditStore({ address: { state } })
    doEditStore({ address: { address1 } })
    doEditStore({ address: { city } })
    doEditStore({ address: { address2 } })
    doEditStore({ address: { address3 } })
    doEditStore({ address: { zip } })
  }

  openCreateStoreModal = () => {
    const {
      organization,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      editStore: doEditStore,
      postStore: doPostStore,
      clearStore: doClearStore,
      hasCreateStorePermission,
      hasRegionalManagerRole,
      regions,
    } = this.props
    if (this.noPermission(hasCreateStorePermission, permText('Create Store'))) {
      return
    }

    const org = organization ? organization.organizations[0] : undefined
    const addStoreId = 'add-store'

    doClearStore()
    doOpenModal({
      id: addStoreId,
      type: 'bootstrap',
      content: (
        <CreateEditStoreModal
          ref={(ref) => (this.createStoreModal = ref)}
          id={addStoreId}
          title="Add New Store"
          store={{}}
          regions={regions}
          organization={org}
          onChange={(o) => doEditStore(o)}
          onConfirm={() => doPostStore()}
          onClose={() => doCloseModal({ id: addStoreId })}
          onRemoveAll={() => doClearStore()}
          storeEditLoading={this.storeEditLoading}
          hasRegionalManagerRole={hasRegionalManagerRole}
        />
      ),
    })
  }

  redirectToStoreLayout = (store, path) => {
    const { history, storeLayoutPermissionData } = this.props
    const hasStoreLayoutPermission = hasAccess({
      ...storeLayoutPermissionData,
      resourceId: store.id,
    })
    if (this.noPermission(hasStoreLayoutPermission, permText('Store Layout'))) {
      return
    }
    history.push(path)
  }

  redirectToStoreSecuredProducts = (store, path) => {
    const { history, storeSecuredProductsPermissionData } = this.props
    const hasSecuredProductsPermission = hasAccess({
      ...storeSecuredProductsPermissionData,
      resourceId: store.id,
    })
    if (
      this.noPermission(
        hasSecuredProductsPermission,
        permText('Store Secured Products')
      )
    ) {
      return
    }
    history.push(path)
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  render() {
    return <div />
  }
}

StoreManagement.propTypes = {
  storeManagement: PropTypes.string,
  storeDetails: PropTypes.object,
  resetStoreManagement: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  notifyModal: PropTypes.func,
  clearTags: PropTypes.func,
  postTaggings: PropTypes.func,
  editTags: PropTypes.func,
  tags: PropTypes.object,
  editTagsLoading: PropTypes.bool,
  regions: PropTypes.any,
  history: PropTypes.object,
  organization: PropTypes.object,
  clearStore: PropTypes.func,
  editStore: PropTypes.func,
  patchStore: PropTypes.func,
  postStore: PropTypes.func,
  patchStoreLoading: PropTypes.bool,
  patchStoreFailed: PropTypes.object,
  postStoreLoading: PropTypes.bool,
  postStoreFailed: PropTypes.bool,
  hasCreateStorePermission: PropTypes.bool,
  showStorePermissionData: PropTypes.object,
  updateStorePermissionData: PropTypes.object,
  storeLayoutPermissionData: PropTypes.object,
  storeSecuredProductsPermissionData: PropTypes.object,
  createTaggingsPermissionData: PropTypes.object,
  showTaggingsPermissionData: PropTypes.object,
  updateTaggingsPermissionData: PropTypes.object,
  destroyTaggingsPermissionData: PropTypes.object,
  listTaggingsPermissionData: PropTypes.object,
  hasMtiAdminRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasStoreManagerRole: PropTypes.bool,
  onClose: PropTypes.func,
  hideButtons: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  storeManagement: makeSelectStoreManagement(),
  storeDetails: makeSelectStoreDetails(),
  patchStoreLoading: makeSelectPatchLoading(),
  patchStoreFailed: makeSelectPatchFailed(),
  postStoreLoading: makeSelectPostLoading(),
  postStoreFailed: makeSelectPostFailed(),
  organization: makeSelectOrganization(),
  tags: makeSelectTags(),
  editTagsLoading: makeSelectTagsEditIsLoading(),
  regions: makeSelectRegions(),
  hasCreateStorePermission: makeSelectMtiPermissionAnyResource(
    'Store: Create New Objects'
  ),
  showStorePermissionData: makeSelectMtiPermissionAccessData(
    'Store: Read/Show Details'
  ),
  updateStorePermissionData: makeSelectMtiPermissionAccessData(
    'Store: Update Details of Existing Records'
  ),
  storeLayoutPermissionData: makeSelectMtiPermissionAccessData('Store: Layout'),
  storeSecuredProductsPermissionData: makeSelectMtiPermissionAccessData(
    'Store: Secured Products'
  ),
  createTaggingsPermissionData: makeSelectMtiPermissionAccessData(
    'Tagging: Create New Objects'
  ),
  showTaggingsPermissionData: makeSelectMtiPermissionAccessData(
    'Tagging: Read/Show Details'
  ),
  updateTaggingsPermissionData: makeSelectMtiPermissionAccessData(
    'Tagging: Update Details of Existing Records'
  ),
  destroyTaggingsPermissionData: makeSelectMtiPermissionAccessData(
    'Tagging: Destroy Existing Records'
  ),
  listTaggingsPermissionData: makeSelectMtiPermissionAccessData(
    'Tagging: Index (list) Records'
  ),
  hasMtiAdminRole: makeSelectHasMtiRoleAnyResource('MTI Admin'),
  hasOrganizationAdminRole: makeSelectHasMtiRoleAnyResource(
    'Organization Administrator'
  ),
  hasRegionalManagerRole: makeSelectHasMtiRoleAnyResource('Regional Manager'),
  hasStoreManagerRole: makeSelectHasMtiRoleAnyResource('Store Manager'),
})

const mapDispatchToProps = {
  resetStoreManagement,
  openModal,
  closeModal,
  notifyModal,
  clearStore,
  editStore,
  clearTags,
  postTaggings,
  editTags,
  patchStore,
  postStore,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(StoreManagement)
