/**
 * Organization Sagas
 */
import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  all,
} from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { postFloor, postArea } from '../../../api'
import { upsert, fetchAndUpsert, fetchGeometryAndUpsert } from './ormSaga'
import { makeSelectToken, makeSelectStore } from '../selectors'
import { ActionTypes } from '../constants'
import { defaultArea, defaultFloor } from '../../../utils/mtiUtils'
import { loadUsers } from '../../UsersPage/sagas'
// import { loadMe } from './meSaga'
import { loadPersonae } from './personaeSagas'
import { loadOrganizations } from '../../HomePage/MtiAdmin/OrganizationsList/sagas'
import { loadStores } from '../../StoresPage/sagas'
import { loadRegions } from '../../RegionsPage/sagas'
import { loadTemplates } from '../../Templates/sagas'
// Store Levels
import { loadFloors } from '../../FloorsPage/sagas'
import { loadFloor } from '../../FloorPage/sagas'
import { loadArea } from '../../AreaPage/sagas'
import { loadFixture } from '../../FixturePage/sagas'
//
import { restoreInitialGeometryCall } from '../localStorageOptions'
import { loadSecuredProducts } from '../../SecuredProducts/sagas'
import { loadSchedules } from '../../SchedulesPage/sagas'
//
import {
  loadOrganization as loadOrganizationAction,
  loadPersonae as loadPersonaeAction,
  loadTags as loadTagsAction,
  loadMe as loadMeAction,
} from '../actions'
import { loadUsers as loadUsersAction } from '../../UsersPage/actions'
import { loadStores as loadStoresAction } from '../../StoresPage/actions'
import { loadRegions as loadRegionsAction } from '../../RegionsPage/actions'

export function* loadDataForOrganization() {
  yield put(loadOrganizationAction())
  yield put(loadMeAction())
  yield put(loadUsersAction())
  yield put(loadPersonaeAction())
  yield put(loadStoresAction())
  yield put(loadRegionsAction())
  yield put(loadTagsAction())
}

/**
 * Creates default floor
 *
 * @param token
 * @param sId
 * @returns {IterableIterator<*>}
 */
function* createDefaultFloor(token, sId) {
  const data = yield call(postFloor, token, defaultFloor, sId)
  console.log('DEFAULT FLOOR IS BEING CREATED', data)
  yield call(upsert, { payload: data })
  return data
}

/**
 * Creates default area
 *
 * @param token
 * @param sId
 * @param fId
 * @returns {IterableIterator<*>}
 */
function* createDefaultArea(token, sId, fId) {
  const data = yield call(postArea, token, defaultArea, sId, fId)
  console.log('DEFAULT AREA IS BEING CREATED', data)
  yield call(upsert, { payload: data })
  return data
}

export function* createDefaultFloorArea({ payload }) {
  if (isNaN(payload)) return
  const token = yield select(makeSelectToken())
  if (!token) return
  try {
    const store = yield select(makeSelectStore(payload))
    const {
      floors = [],
      areas = [],
      id: storeId,
      floorsCount,
      areasCount,
    } = store
    let data
    let path = window.location.pathname
    if (!floors.length && floorsCount === 0) {
      data = yield createDefaultFloor(token, storeId)
      const { id: fId } = ((data || {}).floors || [])[0]
      if (path.includes('floors/default')) {
        console.log('UPDATING FLOOR PATH', fId)
        path = path.replace('floors/default', `floors/${fId}`)
        yield put(push(path))
      }
    }

    if (!areas.length && areasCount === 0) {
      const dFloor = ((data || {}).floors || []).find(
        (floor) => floor.name === defaultFloor.name
      ) || { id: floors[0] }
      data = yield createDefaultArea(token, storeId, dFloor.id)
      const { id: aId } = ((data || {}).areas || [])[0]
      if (path.includes('areas/default')) {
        console.log('UPDATING AREA PATH', aId)
        path = path.replace('areas/default', `areas/${aId}`)
        yield put(push(path))
      }
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function* loadStoreLayout({ payload }) {
  const { id } = payload
  const isInitialGeometryCall = restoreInitialGeometryCall()
  if (isInitialGeometryCall) {
    yield call(fetchGeometryAndUpsert, { payload: { sId: id } })
  }
  yield call(fetchAndUpsert, { payload: { sId: id } })
}

export default function* root() {
  yield all([
    yield takeEvery(ActionTypes.LOAD_USERS, loadUsers),
    // yield takeEvery(ActionTypes.LOAD_ME, loadMe),
    yield takeEvery(ActionTypes.LOAD_PERSONAE, loadPersonae),
    yield takeEvery(ActionTypes.LOAD_ORGANIZATIONS, loadOrganizations),
    yield takeEvery(ActionTypes.LOAD_STORES, loadStores),
    yield takeEvery(ActionTypes.LOAD_REGIONS, loadRegions),
    yield takeEvery(ActionTypes.LOAD_TEMPLATES, loadTemplates),
    yield takeLatest(
      ActionTypes.STORE_CREATE_DEFAULT_FLOOR_AND_AREA,
      createDefaultFloorArea
    ),
    // Store Levels
    yield takeEvery(ActionTypes.LOAD_FLOORS, loadFloors),
    yield takeEvery(ActionTypes.LOAD_FLOOR_CANVAS, loadFloor),
    yield takeEvery(ActionTypes.LOAD_AREA_CANVAS, loadArea),
    yield takeEvery(ActionTypes.LOAD_FIXTURE_CANVAS, loadFixture),
    //
    yield takeEvery(ActionTypes.LOAD_SECURED_PRODUCTS, loadSecuredProducts),
    yield takeEvery(ActionTypes.LOAD_SCHEDULES, loadSchedules),
    //
    yield takeLatest(ActionTypes.STORE_DETAILS, loadStoreLayout),
    //
    yield takeLatest(
      ActionTypes.LOAD_DATA_FOR_ORGANIZATION,
      loadDataForOrganization
    ),
  ])
}
