/**
 *
 * Select
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './css/style.css'

class Select extends React.PureComponent {
  componentDidUpdate() {
    const { value } = this.props
    this.select.value = value
  }

  addFocusClass = () => {
    this.formGroupSelect.classList.add('is-focused')
  }

  removeFocusClass = () => {
    if (this.props.value && this.props.value.length) return
    this.formGroupSelect.classList.remove('is-focused')
  }

  renderSelect = () => {
    const {
      name,
      label,
      onChange,
      value,
      children,
      disabled,
      customStyle,
    } = this.props
    let className = styles.selectWrapper
    let inputClassName = `form-control ${styles.select}`
    if (label) {
      className = `${className} ${customStyle ||
        styles.fixPadding} form-group bmd-form-group`
      inputClassName = `${inputClassName} ${styles.selectWithLabel} `
    }

    return (
      <div className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <select
          className={inputClassName}
          style={disabled ? { backgroundImage: 'none' } : {}}
          id={name}
          key={name}
          defaultValue={value}
          disabled={disabled}
          onChange={onChange}
          ref={(i) => {
            this.select = i
          }}
        >
          {children}
        </select>
      </div>
    )
  }

  renderFloatLabel() {
    const {
      name,
      floatingLabel: label,
      onChange,
      value,
      children,
      disabled,
    } = this.props

    return (
      <div
        className={`form-group bmd-form-group is-filled ${
          styles.selectWrapper
        } ${value ? 'is-focused' : ''}`}
        ref={(i) => {
          this.formGroupSelect = i
        }}
      >
        <label htmlFor={name} className="bmd-label-floating">
          {label}
        </label>
        <select
          className="form-control"
          style={disabled ? { backgroundImage: 'none' } : {}}
          id={name}
          key={name}
          defaultValue={value}
          disabled={disabled}
          onChange={onChange}
          onFocus={this.addFocusClass}
          onBlur={this.removeFocusClass}
          ref={(i) => {
            this.select = i
          }}
        >
          {children}
        </select>
      </div>
    )
  }

  render() {
    if (this.props.floatingLabel) {
      return this.renderFloatLabel()
    }
    return this.renderSelect()
  }
}

Select.propTypes = {
  children: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  floatingLabel: PropTypes.string,
  customStyle: PropTypes.string,
  onChange: PropTypes.func,
}

export default Select
