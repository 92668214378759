import React from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  makeSelectHasMtiRole,
  makeSelectHasMtiRoleAnyResource,
  makeSelectMtiPermissionResources,
  makeSelectOrganization,
  makeSelectOrganizationError,
  makeSelectOrganizationLoading,
} from '../../App/selectors'
import {
  makeSelectCombination,
  makeSelectDate,
  makeSelectSelectedStores,
  makeSelectIsDefaultFilters,
} from './selectors'
import {
  makeSelectLoading as makeSelectRegionsLoading,
  makeSelectFailed as makeSelectRegionsFailed,
  makeSelectRegions,
} from '../../RegionsPage/selectors'
import {
  makeSelectLoading as makeSelectStoresLoading,
  makeSelectFailed as makeSelectStoresFailed,
  makeSelectStores,
} from '../../StoresPage/selectors'
import { updateCombination, updateDates, updateStores } from './actions'
import ProgressIndicator from '../../../components/ProgressIndicator'
import SelectFiltersPreloader from '../../../components/Preloaders/SelectFiltersPreloader'
import CreateIndexModalScreen from './index.screen'
import injectReducer from '../../../utils/injectReducer'
import reducer from './reducer'

class CreateFiltersModal extends React.PureComponent {
  /* eslint-disable no-undef, no-shadow */
  componentWillMount() {
    const {
      updateDates,
      dateFilters,
      updateCombination,
      initialCombination,
      isDefaultFilters,
    } = this.props

    if (isDefaultFilters) {
      if (dateFilters) {
        updateDates(dateFilters)
      }

      if (initialCombination) {
        updateCombination(initialCombination)
      }
    }
  }

  isOrganizationFilter = () => {
    const { hasMtiAdminRole, hasOrganizationAdminRole } = this.props
    return hasMtiAdminRole || hasOrganizationAdminRole
  }

  accessibleRegions = () => {
    const { listRegionalManagerRole } = this.props
    const regions = this.props.regions.map(r => r.toJS())

    if (this.isOrganizationFilter()) {
      return regions
    }

    if (listRegionalManagerRole) {
      return regions.filter(({ id }) => listRegionalManagerRole.includes(id))
    }

    return []
  }

  selectedStoresWithNames = storesIds => {
    const stores = this.accessibleStores()
    return stores
      .filter(({ id }) => storesIds.includes(id))
      .map(({ id, name }) => ({ id, name }))
  }

  accessibleStores = () => {
    const {
      listRegionalManagerRole,
      listStoreManagerRole,
      hasRegionalManagerRole,
      stores,
      regions,
    } = this.props

    if (this.isOrganizationFilter()) {
      return stores
    }

    if (listRegionalManagerRole && hasRegionalManagerRole) {
      const accessibleRegions = regions.filter(({ id: regionId }) =>
        listRegionalManagerRole.includes(regionId)
      )
      const accessibleRegionsIds = accessibleRegions.map(({ id }) => id)
      const accessibleStores = stores.filter(({ regionId }) =>
        accessibleRegionsIds.includes(regionId)
      )
      return accessibleStores
    }

    if (listStoreManagerRole) {
      const accessibleStores = stores.filter(({ id }) =>
        listStoreManagerRole.includes(id)
      )
      return accessibleStores
    }

    return []
  }

  render() {
    const {
      id,
      onClose,
      organization,
      combinationNodes,
      dateFiltersNode,
      selectedStores,
      initialCombination,
      exceptionsCombination,
      selectedDates,
      dateFilters,
      isDefaultFilters,
      updateCombination,
      updateStores,
      updateDates,
      onConfirm,
      organizationLoading,
      // organizationError,
      regions,
      stores,
      regionsLoading,
      storesLoading,
      // regionsError,
      hasRegionalManagerRole,
    } = this.props

    if (organizationLoading || regionsLoading || storesLoading) {
      const multiTable = hasRegionalManagerRole || this.isOrganizationFilter()
      const isLogs = !!dateFilters
      return (
        <div className="modal-content">
          <React.Fragment>
            <ProgressIndicator text="Loading ..." />
            <SelectFiltersPreloader isLogs={isLogs} multiTable={multiTable} />
          </React.Fragment>
        </div>
      )
    }

    if (!organization || !regions || !stores) {
      return null
    }

    if (isDefaultFilters) {
      console.error('isDefaultFilters')
      return null
    }

    const combination = isDefaultFilters
      ? initialCombination
      : exceptionsCombination
    const dates = isDefaultFilters ? dateFilters : selectedDates
    console.warn('render', combination, dates)

    return (
      <CreateIndexModalScreen
        id={id}
        organization={organization}
        regions={this.accessibleRegions()}
        stores={this.accessibleStores()}
        combination={combinationNodes(combination, data => {
          updateCombination(data)
        })}
        combinationValues={combination}
        dateFilters={
          dateFiltersNode
            ? dateFiltersNode(
              dates.startDate,
              dates.endDate,
              updateDates,
              updateDates
            )
            : null
        }
        onClose={onClose}
        onConfirm={storesIds => {
          const stores = this.selectedStoresWithNames(storesIds)
          updateStores(stores)
          onConfirm()
        }}
        selectedStoresIds={
          selectedStores ? selectedStores.map(({ id }) => id) : selectedStores
        }
        organizationFilter={this.isOrganizationFilter()}
        regionsFilter={hasRegionalManagerRole || this.isOrganizationFilter()}
      />
    )
  }
}

CreateFiltersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  organization: PropTypes.object,
  organizationLoading: PropTypes.bool,
  // organizationError: PropTypes.object,
  regions: PropTypes.array,
  stores: PropTypes.array,
  isDefaultFilters: PropTypes.bool.isRequired,
  regionsLoading: PropTypes.bool,
  storesLoading: PropTypes.bool,
  // regionsError: PropTypes.object,
  initialCombination: PropTypes.object.isRequired,
  combinationNodes: PropTypes.func.isRequired,
  dateFiltersNode: PropTypes.func,
  dateFilters: PropTypes.object,
  exceptionsCombination: PropTypes.object.isRequired,
  selectedDates: PropTypes.object,
  updateCombination: PropTypes.func.isRequired,
  updateStores: PropTypes.func.isRequired,
  updateDates: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedStores: PropTypes.array,
  hasMtiAdminRole: PropTypes.bool.isRequired,
  hasOrganizationAdminRole: PropTypes.bool.isRequired,
  listStoreManagerRole: PropTypes.array,
  listRegionalManagerRole: PropTypes.array,
  hasRegionalManagerRole: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  exceptionsCombination: makeSelectCombination(),
  selectedDates: makeSelectDate(),
  organizationLoading: makeSelectOrganizationLoading(),
  organizationError: makeSelectOrganizationError(),
  organization: makeSelectOrganization(),
  selectedStores: makeSelectSelectedStores(),
  regions: makeSelectRegions(),
  stores: makeSelectStores(),
  isDefaultFilters: makeSelectIsDefaultFilters(),
  regionsLoading: makeSelectRegionsLoading(),
  storesLoading: makeSelectStoresLoading(),
  regionsError: makeSelectRegionsFailed(),
  storesError: makeSelectStoresFailed(),
  hasMtiAdminRole: makeSelectHasMtiRoleAnyResource('MTI Admin'),
  hasOrganizationAdminRole: makeSelectHasMtiRole('Organization Administrator'),
  listStoreManagerRole: makeSelectMtiPermissionResources('Store Manager'),
  listRegionalManagerRole: makeSelectMtiPermissionResources('Regional Manager'),
  hasRegionalManagerRole: makeSelectHasMtiRoleAnyResource('Regional Manager'),
})

const mapDispatchToProps = {
  updateCombination,
  updateStores,
  updateDates,
}

export const withRegionsReducer = injectReducer({
  key: 'resourceFilter',
  reducer,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRegionsReducer, withConnect)(CreateFiltersModal)
