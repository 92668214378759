/**
 * Organization Sagas
 */
import { delay } from 'redux-saga'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { modelActionCreators } from 'mti-jsclient-shared'
import {
  getOrganizationById,
  getOrganization,
  patchOrganization,
} from '../../../api'
import {
  makeSelectToken,
  makeSelectData,
  makeSelectOrganization,
  makeSelectOrganizationEdit,
  makeSelectOrganizationDetails,
} from '../selectors'
import { ActionTypes } from '../constants'
import {
  organizationLoaded,
  organizationLoadError,
  patchOrganizationFailed,
  patchOrganizationFulfilled,
  patchOrganizationPending,
  organizationDetailsAction,
} from '../actions'
import { errorToast, successToast } from '../../../utils/utils'

export function* loadOrganization() {
  try {
    const user = yield select(makeSelectData())
    const { organizationId: userOrgId } = user || {}
    const isMtiAdmin =
      (window.location.pathname.split('/') || [])[1] === 'organizations'
    const mtiAdminOrgId = isMtiAdmin
      ? (window.location.pathname.split('/') || [])[2]
      : undefined
    const organizationId = userOrgId || mtiAdminOrgId
    if (!organizationId) return
    const organization = yield select(makeSelectOrganization())
    const token = yield select(makeSelectToken())

    if (!organization || organization.organizations[0].id !== organizationId) {
      if (!token) {
        console.log('Skipping get Organization')
        return
      } else {
        // console.log('get Organization')
      }
      let data
      if (isMtiAdmin) {
        data = yield call(getOrganizationById, token, mtiAdminOrgId)
      } else {
        data = yield call(getOrganization, token)
      }
      yield put(modelActionCreators.updateOrganizations(data.organizations))

      yield put(organizationLoaded(data))
    } else {
      // TODO: simplify
      yield put(organizationLoaded(organization))
    }
  } catch (error) {
    console.error(error)
    yield put(organizationLoadError(error))
    errorToast('Load organization failed')
  }
}

export function* patch() {
  const organization = yield select(makeSelectOrganizationEdit())
  try {
    const token = yield select(makeSelectToken())
    yield put(patchOrganizationPending())
    if (!organization.maxBackupKeysLimit) {
      organization.maxBackupKeys = null
      organization.maxBackupKeysLimit = undefined
    }
    const response = yield call(patchOrganization, token, organization)
    successToast('Organization Updated')
    yield put(patchOrganizationFulfilled(response))
    yield delay(500)
    const organizationDetails = yield select(makeSelectOrganizationDetails())
    yield put(organizationDetailsAction(organizationDetails)) // Open organization details modal
  } catch (error) {
    const res = error.response
    const errorObj = (res && (yield call([res, res.json]))) || error
    yield put(patchOrganizationFailed(errorObj))
    errorToast('Save organization failed')
  }
}

export default function* root() {
  yield takeLatest(ActionTypes.LOAD_ORGANIZATION_REQUEST, loadOrganization)
  yield takeLatest(ActionTypes.PATCH_ORGANIZATION, patch)
}
