import { createGlobalStyle } from 'styled-components'
import { isMobile } from './utils/screen'

/* eslint no-unused-expressions: 0 */
export default createGlobalStyle`
  ${
  isMobile()
    ? `@font-face {
      font-family: 'Arial';
      src: url('/arial.ttf');
    }`
    : ''
}
  html,
  body {
    height: 100%;
    width: 100%;
    min-width: 1024px !important;
  }

  #app {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  p,
  label {
    line-height: 1.5em;
  }

body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Segoe, sans-serif;
}
.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.navigation {
    flex: 1 0 60%;
    width: 60%;
    background-color: #263238;
    min-height: 30px;
    display: flex;
}

.navigation a, .authNavigation a {
    color: white;
    text-decoration: none;
    padding: 10px 10px 10px 10px;
}
.authNavigation {
    flex: 0 1 20%;
    width: 20%;
    background-color: #263238;
    min-height: 30px;
    display: flex;
    flex-direction: row-reverse;
}
.login {
    width: 50%;
    margin: 40px auto;
}
.login .username {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    padding: 5px 10px;
    border-color: #dbdbdb;
    color: #363636;
    -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
}
.checkbox {
    width: 100%;
}
.checkbox input {
    margin-right: 10px;
}
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    position: relative;
    vertical-align: top;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    border-color: #dbdbdb;
    color: #363636;
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0.75em;
    padding-right: 0.75em;
    text-align: center;
    white-space: nowrap;
    margin-top: 20px;
    background-color: #4E92DF;
    border-color: transparent;
    color: #fff;
}
.content {
    flex: 1 0 100%;
    padding: 10px;
}

.active {
    background-color: #4E92DF;
}

.code {
    font-family: courier;
    padding: 1em;
    border: 1px solid #ccc;
}

.username {
    text-decoration: none;
    padding: 10px 10px 10px 10px;
    font-weight: bold;
    color: coral;
}

.react-datepicker-ignore-onclickoutside {
  width: 110px;
}

.react-datepicker__input-container input {
  width: 110px;
}

.react-datepicker__input-container {
  border: 2px solid #64b5f6;
  border-radius: 4px;
  width: 110px;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.react-datepicker-wrapper {
  width: 110px;
}

/* Bootstrap overrides */

.breadcrumb-item:empty {
  display: none;
}

.breadcrumb-item,
.breadcrumb-item:hover {
  color: #FFFFFF;
}

.breadcrumb-control,
.breadcrumb-control:hover {
  margin-left: auto;
  color: #FFFFFF;
}

.breadcrumb-action,
.breadcrumb-action:hover {
  padding-top: 2px;
  padding-bottom: 2px;
  color: #FFFFFF;
}

.btn-primary {
  color: #4E92DF !important;
  background-color: #FFFFFF !important;
}

.flat-bordered-button.disabled {
  color: #cccccc;
  border-color: #cccccc;
}

.bg-dark {
  background-color: #000000 !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:visited,
.btn-primary:focus {
  color: #4E92DF !important;
  background-color: transparent !important;
}

.btn-raised,
.btn-raised:hover,
.btn-raised:visited,
.btn-raised:focus {
  color: #FFFFFF !important;
  background-color: #4E92DF !important;
}

.border-primary {
  color: #4E92DF !important;
  border-color: #4E92DF !important;
}

.checkbox label input[type=checkbox]:checked+.checkbox-decorator .check, label.checkbox-inline input[type=checkbox]:checked+.checkbox-decorator .check {
  border-color: #4E92DF !important;
}

.checkbox label input[type=checkbox]:checked+.checkbox-decorator .check:before, label.checkbox-inline input[type=checkbox]:checked+.checkbox-decorator .check:before {
  color: #4E92DF !important;
}

.btn-primary:disabled {
    color: #666666 !important;
}

.btn-raised:disabled {
    color: #FFFFFF !important;
    background-color: #666666 !important;
}

.ReactTable .rt-td {
  overflow: visible;
  align-items: center;
  display: flex;
}

.ReactTable .rt-th .header-cell {
  position: relative;
}

.ReactTable .rt-th .sort-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.ReactTable .rt-th.-sort-asc .sort-icon.sort-alphabetical {
  background-image: url('/alphabetical_sorting.svg');
}

.ReactTable .rt-th.-sort-desc .sort-icon.sort-alphabetical {
  background-image: url('/alphabetical_sorting_2.svg');
}

.ReactTable .rt-th.-sort-asc .sort-icon.sort-numerical {
  background-image: url('/numerical_sorting_12.svg');
}

.ReactTable .rt-th.-sort-desc .sort-icon.sort-numerical {
  background-image: url('/numerical_sorting_21.svg');
}

.ReactTable.full-screen .rt-thead {
  overflow-y: scroll;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
}

.ReactTable .-pagination .-center {
  flex: 3.5;
}

.react-phone-number-input__icon-image {
  vertical-align: top;
}

.popover {
    max-width: 500px;
    white-space: pre-wrap;
}

input:checked ~ .checkbox-title {
  color: black;
}

.rdtPicker {
  background-color: #4E92DF;
  width: 120px;
}
/* This fixed blured text in modal dialogs. Safe to remove if it produces a bug */
.modal-body {
  position: initial;
}

.modal-confirmation .modal-footer {
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.modal-confirmation.modal-sm .modal-footer {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 8px;
  justify-content: space-between;
}

.modal-confirmation .modal-footer .btn {
  width: 100%;
}

.modal-confirmation.modal-sm .modal-body {
  text-align: center;
}

.modal-confirmation .modal-footer .btn {
  width: auto;
  min-width: 30%;
}

.modal-confirmation.modal-sm .modal-footer .btn {
  width: 100%;
}

.modal-confirmation .modal-footer .btn-secondary {
  border: 1px solid #64b5f6;
}

.slick-prev:before, .slick-next:before {
  color: rgb(78, 146, 223);
  font-size: 40px;
}

.slick-prev, .slick-next {
  height: 36px;
  width: 36px;
}

.slick-prev {
  left: -52px;
}
.slick-next {
  right: -32px;
}

.slick-slide:first-child {
  margin-left: 0px;
}

.slick-slide:last-child {
  margin-right: 0px;
}

.react-tel-input .flag-dropdown {
  background: none !important;
  border: none !important;
}
.react-tel-input input[type=text], .react-tel-input input[type=tel] {
  width: 100% !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  font-size: inherit !important;
  letter-spacing: inherit !important;
  background: none !important;
  border: none !important;
  height: 33px !important;
  line-height: 33px !important;
}

.custom-file-control:read-only, input.form-control:read-only {
  background-image: linear-gradient(to right, transparent 80%, #223049 50%) !important;
  background-size: 3px 1px !important;
  background-repeat: repeat-x !important;
  background-position: bottom !important;
  color: #AEAEAE !important;
}

.mb-3-mti {
  margin-bottom: 1rem !important;
  text-align: left;
  color: #808080;
}

.link {
  color: #64b5f6;
}

.help-icon {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  font-family: serif;
  line-height: calc(100% + 0.2em);

  margin-top: 0.25em;
  margin-left: 1em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 0.6em;
  background-color: #4E92DF;
  color: white;
  display: inline-block;
}

.help-icon:after {
  content: 'i'
}

.device-configuration-list .firmware-name
{
  font-family: monospace;
  font-size: 115%;
}
.device-configuration-list .firmware-name:before
{
  content: '['
}
.device-configuration-list .firmware-name:after
{
  content: ']'
}

.device-configuration-list .firmware-action
{
  background-color: #4E92DF;
  color: white;
  transition: background-color 0.5s
  text-decoration: none;
  margin-top: 0;
  padding: 0.25em 0.75em;
  border-radius: 0.25em;
  border: none;
  cursor: pointer;
}

.device-configuration-list .firmware-action:hover
{
  background-color: #287ad7;
}

.device-configuration-list .firmware-action:active
{

  background-color: #2061ac;
}

.device-configuration-list .btn-group
{
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.device-configuration-form .form-row {
  border-bottom: 1px solid silver;
  margin-bottom: 1rem;
}
.device-configuration-form .form-row:last-of-type {
  border-bottom: none;
  margin-bottom: inherit;
}

select#managed-store {
  height: 33px;
  padding-top: 7px;
  padding-bottom: 7px;
}

div.hide-select-button > div > div:nth-of-type(2) {
  display: none;
}
div.hide-select-button > div {
  background-color: inherit;
}
div.hide-select-button > div > div:nth-of-type(1) > div {
  color: inherit;
}

.assignable-roles-read-only-list,
.assignable-roles-read-only {
  border-color: #CCCCCC;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
}

.assignable-roles-read-only {
  padding: 6px 0.5em;
}

.assignable-roles-read-only-list {
  padding: 7px 0.5em;
}

.assignable-roles-read-only-list span {
  background-color: #E6E6E6;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  white-space: nowrap;
  margin-right: 0.5em;
  display: inline-block;
}
.assignable-roles-read-only-list span:last-of-type {
  margin-right: 0;
}

.assignable-roles-read-only-list span:last-of-type {
  margin-right: none;
}

.full-width-dropdown {
  width: 100%;
}
`
