/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectHasMtiRoleAnyResource } from '../App/selectors'
import MtiAdmin from './MtiAdmin'
import CorporateUserPage from './CorporateUser'
import StoreManagerOrStaff from './StoreManagerOrStaff'

export class HomePage extends React.PureComponent {
  checkUserRole = () => {
    const {
      hasMtiAdminRole,
      hasOrganizationAdminRole,
      hasRegionalManagerRole,
    } = this.props

    if (hasMtiAdminRole) {
      return <MtiAdmin history={this.props.history} />
    }
    if (hasOrganizationAdminRole || hasRegionalManagerRole) {
      return <CorporateUserPage history={this.props.history} />
    }
    return <StoreManagerOrStaff history={this.props.history} />
  }

  render() {
    const homeScreens = this.checkUserRole()

    return (
      <div className="Home-Page" style={{ flex: 1 }}>
        {homeScreens}
      </div>
    )
  }
}

HomePage.propTypes = {
  history: PropTypes.any,
  hasMtiAdminRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  hasMtiAdminRole: makeSelectHasMtiRoleAnyResource('MTI Admin'),
  hasOrganizationAdminRole: makeSelectHasMtiRoleAnyResource(
    'Organization Administrator'
  ),
  hasRegionalManagerRole: makeSelectHasMtiRoleAnyResource('Regional Manager'),
})

export default connect(mapStateToProps)(HomePage)
