import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import KeyExpirationInput from '../../../components/KeyExpirationInput'
import BackupKeysForStores from '../../../components/BackupKeysForStores'
import BackupKeysLimit from '../../../components/BackupKeysLimit'
import ProgressIndicator from '../../../components/ProgressIndicator'
import OrganizationDetailsPreloader from '../../../components/Preloaders/OrganizationDetailsPreloader'
import { dottedBackground } from '../../../styles/styled'
import { DeviceConfigurationList } from '../../../components/DeviceConfiguration'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const BorderLabel = styled.label`
  width: 100%;
  min-height: 38px;
  padding: 10px 0;
  ${dottedBackground};
`

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`

export const ColumnContainer = styled.div`
  padding-top: 0px;
`

const renderMainContent = (organization) => {
  const {
    id,
    name,
    subdomain,
    contactPhone,
    contactName,
    contactEmail,
    supportPhone,
    createdAt,
    updatedAt,
    timeZone,
    keyExpTimeInterval,
    keyExpTimeIntervalEnabled,
    backupKeyEnabled,
    maxBackupKeys,
  } = organization
  return (
    <div className="col-xs-6">
      <div className="form-row">
        <ColumnContainer className="form-group col-md-8 bmd-form-group">
          <Column>
            <div>Organization name</div>
            <BorderLabel>{name || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Subdomain</div>
            <BorderLabel>{subdomain || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
      </div>

      <div className="form-row">
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Contact name</div>
            <BorderLabel>{contactName || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Contact email</div>
            <BorderLabel>{contactEmail || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Contact phone</div>
            <BorderLabel>{contactPhone || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
      </div>

      <div className="form-row">
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Staff Key Expiration</div>
            <KeyExpirationInput
              name="storeKeyExpiration-disabled"
              checked={keyExpTimeIntervalEnabled}
              interval={keyExpTimeInterval}
              disabled
            />
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Time zone</div>
            <BorderLabel>{timeZone || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Support phone</div>
            <BorderLabel>{supportPhone || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
      </div>
      <div className="form-row">
        <ColumnContainer className="form-group col-md-6 bmd-form-group">
          <Column>
            <div>Allow Backup Keys for Stores</div>
            <BackupKeysForStores
              name="backup-keys-for-stores"
              checked={backupKeyEnabled}
              disabled
            />
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-6 bmd-form-group">
          <Column>
            <div>Limit number of Backup Keys - check to set limit</div>
            <BackupKeysLimit
              name="limit-backup-keys"
              checked={!!maxBackupKeys}
              limit={maxBackupKeys}
              disabled
            />
          </Column>
        </ColumnContainer>
      </div>

      <div className="form-row">
        <DeviceConfigurationList resourceType="Organization" resourceId={id} />
      </div>
      <br />

      <div className="form-row">
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Created at</div>
            <BorderLabel>{createdAt || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>Updated at</div>
            <BorderLabel>{updatedAt || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
      </div>
    </div>
  )
}

const renderAddresses = (organization) => {
  const {
    address: { address1, address2, address3, city, country, state, zip } = {},
  } = organization
  return (
    <div className="col-xs-6">
      <div className="form-row">
        <ColumnContainer className="form-group col-md-8 bmd-form-group">
          <Column>
            <div>Address 1</div>
            <BorderLabel>{address1 || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>City</div>
            <BorderLabel>{city || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
      </div>

      <div className="form-row">
        <ColumnContainer className="form-group col-md-8 bmd-form-group">
          <Column>
            <div>Address 2</div>
            <BorderLabel>{address2 || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-4 bmd-form-group">
          <Column>
            <div>State/Province</div>
            <BorderLabel>{state || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
      </div>

      <div className="form-row">
        <ColumnContainer className="form-group col-md-5 bmd-form-group">
          <Column>
            <div>Address 3</div>
            <BorderLabel>{address3 || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-5 bmd-form-group">
          <Column>
            <div>Country</div>
            <BorderLabel>{country || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-2 bmd-form-group">
          <Column>
            <div>Postal code</div>
            <BorderLabel>{zip || ''}</BorderLabel>
          </Column>
        </ColumnContainer>
      </div>
    </div>
  )
}

class OrganizationDetailsScreen extends React.Component {
  setLoading(loading) {
    this.setState({ loading })
  }

  render() {
    const { id, organization, onConfirm, onClose } = this.props

    const { loading } = this.state || {}

    if (loading)
      return (
        <div className="modal-content">
          <React.Fragment>
            <OrganizationDetailsPreloader />
            <ProgressIndicator text={'Loading...'} />
          </React.Fragment>
        </div>
      )

    const { name } = organization
    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {name}
          </h5>
          <div>
            <button
              name="close"
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
              }}
            >
              Edit Details
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onClose()
              }}
            >
              Close
            </button>
          </div>
        </div>
        <ModalBody className="modal-body">
          {renderMainContent(organization)}
        </ModalBody>
        <ModalBody className="modal-body">
          {renderAddresses(organization)}
        </ModalBody>
      </div>
    )
  }
}

OrganizationDetailsScreen.propTypes = {
  id: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default OrganizationDetailsScreen
