/**
 * Exceptions Sagas
 */
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { getReports, getProductsByIds } from '../../api'
import { makeSelectToken } from '../../containers/App/selectors'
// import { loadStores } from './CreateFiltersModal/sagas'
import { makeSelectAsyncLoadingCanceling } from './selectors'
import {
  makeSelectSelectedStores,
  makeSelectCombination,
} from './CreateFiltersModal/selectors'
import { ActionTypes } from './constants'
import {
  fetchExceptionsFulfilled,
  fetchExceptionsFailed,
  clearExceptions,
  asyncLoadingStoresToLoad,
  asyncLoadingCurrentStore,
  asyncLoadingFinished,
} from './actions'
import { mergeExceptions, getDateFormatted } from './utils'
import { createExceptions } from './exceptions'
import { errorToast } from '../../utils/utils'
import { makeSelectStores } from '../StoresPage/selectors'
import { isComplianceExceptionAction } from '../../utils/mtiUtils'

export function* loadExceptionsAsync() {
  console.log('loadExceptionsAsync')
  let stores = yield select(makeSelectSelectedStores())
  // Handle initialization case
  if (!stores) {
    stores = yield select(makeSelectStores())
  }
  yield put(clearExceptions())
  const storesCount = (stores || []).length
  yield put(asyncLoadingStoresToLoad(storesCount))
  for (let i = 0; i < storesCount; i += 1) {
    const store = stores[i]
    const isCanceling = yield select(makeSelectAsyncLoadingCanceling())
    if (isCanceling) {
      yield put(asyncLoadingFinished())
      break
    }
    yield put(asyncLoadingCurrentStore(store, i))
    try {
      yield call(loadExceptions, {
        payload: { storeId: (store || {}).id },
      })
    } catch (error) {
      errorToast('Load exceptions failed')
      try {
        const res = error.response
        const errorObj = res ? yield call([res, res.json]) : error
        console.log(errorObj)
        yield put(
          fetchExceptionsFailed((store || {}).id, (store || {}).name, errorObj)
        )
      } catch (e) {
        console.log(e)
        yield put(
          fetchExceptionsFailed((store || {}).id, (store || {}).name, error)
        )
      }
    }
  }
  yield put(asyncLoadingFinished())
}

export function* loadExceptions({ payload }) {
  console.log('loadExceptions')
  const { storeId } = payload
  const token = yield select(makeSelectToken())
  const combination = yield select(makeSelectCombination())
  const {
    health: cHealth,
    operations: cOperations,
    compliance: cCompliance,
  } = combination
  // Inn case dates are needed, use ReportsPage example of dates selelcted
  const date = {
    startDate: null,
    endDate: null,
  }
  const { startDate, endDate } = getDateFormatted(date)
  const reportsTypes = [
    cHealth && 'health_exception',
    cOperations && 'operations_exception',
    cCompliance && 'compliance_exception',
  ].filter((e) => e)
  const data = yield call(
    getReports,
    token,
    storeId,
    startDate,
    endDate,
    reportsTypes
  )

  const {
    operationsException: operations = {},
    healthException: health = {},
    complianceException: compliance = {},
  } = data

  yield call(addProductNameToComplianceException, token, data)

  const exceptions = mergeExceptions(
    combination,
    health,
    operations,
    compliance
  )

  yield put(fetchExceptionsFulfilled(storeId, createExceptions(exceptions)))
}

function* addProductNameToComplianceException(token, data) {
  if (!data) {
    return
  }

  const { complianceException: { results = [] } = {} } = data
  const sourceAttribute = results
    .filter(
      ({ source_attribute, last_value }) =>
        (source_attribute === 'product_ids' ||
          source_attribute === 'live_product_ids') &&
        last_value.length
    )
    .map((e) => {
      if (Array.isArray(e.last_value)) {
        e.last_value = e.last_value.filter((o) => o)

        if (isComplianceExceptionAction(e.action) && !e.last_value.length) {
          e.last_value = ['Unknown Product']
        }
      }

      return e
    })

  const productIds = sourceAttribute
    .map(({ last_value }) =>
      last_value.filter((e) => e && e !== 'Unknown Product')
    )
    .reduce((acc, val) => acc.concat(val), []) //.flat()
  const ids = sourceAttribute.map(({ id }) => id)

  if (productIds.length) {
    const products = yield call(getProductsByIds, token, productIds)
    results.forEach((e) => {
      if (ids.includes(e.id)) {
        e.last_value = products.products
          .filter(({ id }) => e.last_value.includes(id))
          .map(({ name }) => name)
      }
    })
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.LOAD_EXCEPTIONS_ASYNC, loadExceptionsAsync),
  ])
}
