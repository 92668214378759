import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactTable from 'react-table'
import styled from 'styled-components'
import styles from '../../../styles/App.css'
import { fullTextFilter } from '../../../utils/utils'
import { showStoreLink } from '../../ExceptionsPage/utils'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../../UsersPage/index.screen'
import { Flex1 } from '../index.screen'
import TooltipCell from '../../../components/TooltipCell'

const StyledTable = styled(ReactTable)`
  max-height: 385px;
`

/* eslint-disable react/prop-types, no-underscore-dangle */
const renderContent = (stores, onStore) => {
  const columns = [
    {
      sortable: true,
      width: 200,
      filterable: true,
      Header: renderHeaderCell('Store'),
      accessor: 'name',
      Cell: ({ original: { name } }) => (
        <CellContainer>
          <span className={styles.link}>{name}</span>
        </CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
    {
      id: 'goTo',
      width: 80,
      sortable: false,
      filterable: false,
      Header: 'Go To',
      Cell: ({ original: { id: storeId, name: storeName } }) => (
        <Flex1 className="text-center">
          <CellContainer>
            <a
              className={styles.link}
              target="_blank"
              href={showStoreLink({ storeId, storeName })}
            >
              Open
            </a>
          </CellContainer>
        </Flex1>
      ),
    },
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Store Managers'),
      accessor: 'rolesTitle',
      Cell: () => (
        <TooltipCell
          text="TBD..."
          render={(tooltip) => (
            <span ref={(ref) => tooltip.target(ref)}>TBD...</span>
          )}
        />
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
    {
      sortable: true,
      width: 120,
      filterable: true,
      Header: renderHeaderCell('Date Added', true),
      accessor: 'lastSignedInAt',
      Cell: ({ original: { lastSignedInAt } }) => (
        <span>
          {lastSignedInAt
            ? moment(lastSignedInAt)
              .utcOffset(0)
              .format('MM/DD/YYYY H:mm')
            : 'TBD...'}
        </span>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    },
  ]

  return (
    <StyledTable
      data={stores}
      columns={columns}
      defaultFilterMethod={fullTextFilter}
      getTdProps={(state, rowInfo, column) => ({
        onClick: (e, handleOriginal) => {
          if (column.id === 'name') {
            onStore(rowInfo.original)
          }

          if (handleOriginal) {
            handleOriginal()
          }
        },
      })}
      defaultPageSize={10}
    />
  )
}

function StoresScreen({ stores, onStore, id }) {
  return renderContent(stores, onStore, id)
}

StoresScreen.propTypes = {
  stores: PropTypes.array.isRequired,
  onStore: PropTypes.func.isRequired,
}

export default StoresScreen
