import { ActionTypes } from '../constants'

/**
 * User Details Action
 *
 * @returns {Object}
 */
export function userDetailsAction(user) {
  return {
    type: ActionTypes.USER_DETAILS,
    payload: user,
  }
}

/**
 * Change User Status
 *
 * @returns {Object}
 */
export function changeUserStatusAction(user, newStatus, showInactiveUsersOnReload) {
  return {
    type: ActionTypes.CHANGE_USER_STATUS,
    payload: { user, newStatus, showInactiveUsersOnReload },
  }
}

/**
 * User Details Action
 *
 * @returns {Object}
 */
export function createUserAction() {
  return {
    type: ActionTypes.CREATE_USER,
  }
}

/**
 * Reset User Management
 *
 * @returns {Object}
 */
export function resetUserManagement() {
  return {
    type: ActionTypes.RESET_USER_MANAGEMENT,
  }
}

/**
 * Edit User
 *
 * @returns {Object}
 */
export function editUser(o) {
  return {
    type: ActionTypes.USER_EDIT,
    payload: o,
  }
}

/**
 * Clear User
 *
 * @returns {Object}
 */
export function clearUser() {
  return {
    type: ActionTypes.USER_CLEAR,
  }
}

/**
 * Post User Failed
 *
 * @returns {Object}
 */
export function postUserFailed(error) {
  return {
    type: ActionTypes.POST_USER_FAILED,
    payload: error,
  }
}

/**
 * Post User Fulfilled
 *
 * @returns {Object}
 */
export function postUserFulfilled() {
  return {
    type: ActionTypes.POST_USER_FULFILLED,
  }
}

/**
 * Post User Pending
 *
 * @returns {Object}
 */
export function postUserPending() {
  return {
    type: ActionTypes.POST_USER_PENDING,
  }
}

/**
 * Post User
 *
 * @returns {Object}
 */
export function postUser() {
  return {
    type: ActionTypes.POST_USER,
  }
}

/**
 * Delete User
 *
 * @returns {Object}
 */
export function deleteUser(id) {
  return {
    type: ActionTypes.DELETE_USER,
    payload: id,
  }
}

/**
 * Delete User Failed
 *
 * @returns {Object}
 */
export function deleteUserFailed(error) {
  return {
    type: ActionTypes.DELETE_USER_FAILED,
    payload: error,
  }
}

/**
 * Delete User Fulfilled
 *
 * @returns {Object}
 */
export function deleteUserFinished() {
  return {
    type: ActionTypes.DELETE_USER_FINISHED,
  }
}

/**
 * Delete User Pending
 *
 * @returns {Object}
 */
export function deleteUserPending() {
  return {
    type: ActionTypes.DELETE_USER_PENDING,
  }
}

/**
 * Patch User Failed
 *
 * @returns {Object}
 */
export function patchUserFailed(error) {
  return {
    type: ActionTypes.PATCH_USER_FAILED,
    payload: error,
  }
}

/**
 * Patch User Fulfilled
 *
 * @returns {Object}
 */
export function patchUserFulfilled() {
  return {
    type: ActionTypes.PATCH_USER_FULFILLED,
  }
}

/**
 * Patch User Pending
 *
 * @returns {Object}
 */
export function patchUserPending() {
  return {
    type: ActionTypes.PATCH_USER_PENDING,
  }
}

/**
 * Patch User
 *
 * @returns {Object}
 */
export function patchUser(id) {
  return {
    type: ActionTypes.PATCH_USER,
    payload: id,
  }
}

/**
 * Find User Failed
 *
 * @returns {Object}
 */
export function findUserFailed(error) {
  return {
    type: ActionTypes.FIND_USER_FAILED,
    payload: error,
  }
}

/**
 * Find User Fulfilled
 *
 * @returns {Object}
 */
export function findUserFulfilled() {
  return {
    type: ActionTypes.FIND_USER_FULFILLED,
  }
}

/**
 * Find User Pending
 *
 * @returns {Object}
 */
export function findUserPending() {
  return {
    type: ActionTypes.FIND_USER_PENDING,
  }
}

/**
 * Find User
 *
 * @returns {Object}
 */
export function findUser() {
  return {
    type: ActionTypes.FIND_USER,
  }
}

/**
 * Find User Found Handled
 *
 * @returns {Object}
 */
export function findUserFoundHandled() {
  return {
    type: ActionTypes.FIND_USER_FOUND_HANDLED,
  }
}

/**
 * Add organization
 *
 * @returns {Object}
 */
export function addOrganization(organization) {
  return {
    type: ActionTypes.ADD_NEW_ORGANIZATION,
    payload: organization,
  }
}

/**
 * Delete Organization
 *
 *
 */
export function deleteOrganization(payload) {
  return {
    type: ActionTypes.DELETE_ORGANIZATION,
    payload,
  }
}

/**
 * Add region
 *
 * @returns {Object}
 */
export function addRegion(region) {
  return {
    type: ActionTypes.ADD_NEW_REGION,
    payload: region,
  }
}

/**
 * Delete Region
 *
 *
 */
export function deleteRegion(payload) {
  return {
    type: ActionTypes.DELETE_REGION,
    payload,
  }
}

/**
 * Add store
 *
 * @returns {Object}
 */
export function addStore(store) {
  return {
    type: ActionTypes.ADD_NEW_STORE,
    payload: store,
  }
}

/**
 * Delete Store
 *
 *
 */
export function deleteStore(payload) {
  return {
    type: ActionTypes.DELETE_STORE,
    payload,
  }
}

/**
 * Clear Role
 *
 * @returns {Object}
 */
export function clearRole() {
  return {
    type: ActionTypes.EDIT_ROLE_CLEAR,
  }
}

/**
 * Reset Password Failed
 *
 * @returns {Object}
 */
export function resetPasswordFailed(error) {
  return {
    type: ActionTypes.RESET_PASSWORD_FAILED,
    payload: error,
  }
}

/**
 * Reset Password Pending
 *
 * @returns {Object}
 */
export function resetPasswordPending() {
  return {
    type: ActionTypes.RESET_PASSWORD_PENDING,
  }
}

/**
 * Reset Password Fulfilled
 *
 * @returns {Object}
 */
export function resetPasswordFulfilled() {
  return {
    type: ActionTypes.RESET_PASSWORD_FULFILLED,
  }
}

/**
 * Reset Password
 *
 * @returns {Object}
 */
export function resetPassword(id) {
  return {
    type: ActionTypes.RESET_PASSWORD,
    payload: id,
  }
}
