import React from 'react'
import DrawerLinksScreen from './index.screen'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import {
  CorporateUser,
  getQuickActionsItems,
  withConnect,
} from '../../../containers/HomePage/CorporateUser'
import { compose } from 'redux'
import { closeDrawer } from '../Button'
import { storeIdFromUrl } from '../StoreManagerOrStaff'

class CorporateUserDrawer extends React.PureComponent {
  state = {
    storeItems: false,
  }

  unlistenHistory = function() {}

  componentDidMount() {
    this.listenHistory(this.props.history)
  }

  componentWillUnmount() {
    this.unlistenHistory()
  }

  listenHistory(history) {
    this.unlistenHistory = history.listen(() => {
      this.setState({ storeItems: !!storeIdFromUrl() })
    })
  }

  onItem = (event, item) => {
    closeDrawer()
    CorporateUser.onItem(event, item, this.props)
  }
  render() {
    return (
      <DrawerLinksScreen
        quickActionsItems={getQuickActionsItems(this.props.store, this.props)}
        onItem={this.onItem}
        history={this.props.history}
      />
    )
  }
}

CorporateUserDrawer.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object.isRequired,
}

export default compose(withConnect)(withRouter(CorporateUserDrawer))
