import React from 'react'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import 'react-datepicker/dist/react-datepicker.css'
import {
  fullTextFilter,
  getTableHeight,
  showHidablePopover,
} from '../../utils/utils'
import { areaLink, fixtureLink, positionLink, showStoreLink } from './utils'
import {
  HeaderContainer,
  Header,
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import AsyncLoadingStatus from './asyncStatus'
import MobileNotSupport from '../../components/MobileNotSupport'
import ProgressIndicator from '../../components/ProgressIndicator'
import TablePreloader from '../../components/Preloaders/TablePreloader'

const HeaderButtonsContainer = styled.div`
  margin-right: 12px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const TextTop = styled.span`
  font-size: 14px;
  text-align: left;
`

export const TextBottom = styled.span`
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export class ExceptionsPageScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  csvDataLink = () => {
    const { loadingStatus, csvData, onExportAsCSV } = this.props
    const text = 'EXPORT AS CSV'
    const { currentName } = loadingStatus || {}
    if (currentName || csvData.length < 2) {
      return (
        <a
          role="button"
          className="btn btn-primary disabled mb-0"
          aria-disabled
        >
          {text}
        </a>
      )
    }

    return (
      <MobileNotSupport text={text}>
        <button
          type="button"
          className="btn btn-primary mb-0"
          onClick={onExportAsCSV}
        >
          {text}
        </button>
      </MobileNotSupport>
    )
  }

  statusText = ({
    sviException,
    currently,
    descriptionRule,
    descriptionAction,
    eventTypeMessage,
    unhealthyReason,
  }) => {
    if (sviException === 'compliance') {
      return `${currently} ${descriptionRule} ${descriptionAction}`
    }

    if (sviException === 'health') {
      return unhealthyReason
    }

    return eventTypeMessage
  }

  popupText = ({
    minimized,
    sviException,
    eventTypeMessage,
    unhealthyReason,
  }) => {
    if (minimized) {
      return <span className="link">{eventTypeMessage}</span>
    }

    if (sviException === 'health') {
      switch (unhealthyReason) {
        case 'security_device_disconnected':
          return 'Security Device Disconnected'
        default:
          return unhealthyReason
      }
    }

    return eventTypeMessage
  }

  render() {
    const {
      exceptions,
      onAsyncLoadingCancel,
      createFilter,
      loadingStatus,
      errors,
      loading,
    } = this.props
    const { tableHeight } = this.state
    const columns = [
      {
        id: 'storeName',
        filterable: true,
        Header: renderHeaderCell('Store'),
        accessor: 'storeName',
        Cell: ({ original, original: { storeName } }) => (
          <CellContainer>
            <a href={showStoreLink(original)} target="_blank">
              {storeName}
            </a>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'store',
          }),
      },
      {
        filterable: true,
        Header: 'Area',
        accessor: 'area.name',
        Cell: ({ original, original: { area } }) => (
          <CellContainer>
            <a href={areaLink(original)} target="_blank">
              {(area || {}).name}
            </a>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'area',
          }),
      },
      {
        filterable: true,
        Header: renderHeaderCell('Fixture'),
        accessor: 'fixtureName',
        Cell: ({ original, original: { fixtureName } }) => (
          <CellContainer>
            <a href={fixtureLink(original)} target="_blank">
              {fixtureName}
            </a>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'fixture',
          }),
      },
      {
        filterable: true,
        Header: renderHeaderCell('Position', true),
        accessor: 'resourceTypeResource',
        Cell: ({ original, original: { resourceTypeResource } }) => (
          <CellContainer>
            <a href={positionLink(original)} target="_blank">
              {resourceTypeResource}
            </a>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'position',
          }),
      },
      {
        id: 'status',
        filterable: true,
        Header: renderHeaderCell('Status'),
        accessor: (data) => this.statusText(data),
        Cell: ({
          original: {
            sviException,
            eventTypeMessage,
            descriptionRule,
            descriptionAction,
            lastValue,
            currently,
            unhealthyReason,
            additionalInformation,
          },
        }) => {
          const component = (color = 'black', minimized = false) =>
            sviException === 'compliance' ? (
              <Column>
                {!minimized && (
                  <TextTop style={{ color }}>
                    {currently && <b>Currently:</b>}
                    {currently && ` ${currently}`}
                  </TextTop>
                )}
                <TextBottom style={{ color }}>
                  {descriptionRule && <b>Rule:</b>}
                  {descriptionRule && ` ${descriptionRule}`}
                </TextBottom>
                <TextBottom style={{ color }}>
                  {additionalInformation && ` ${additionalInformation}`}
                </TextBottom>
                {!minimized && (
                  <React.Fragment>
                    <TextBottom style={{ color }}>
                      {descriptionAction && <b>Action:</b>}
                      {descriptionAction && ` ${descriptionAction}`}
                    </TextBottom>
                    <TextBottom style={{ color }}>
                      {lastValue && <b>Currently:</b>}
                      {lastValue && ` ${lastValue}`}
                    </TextBottom>
                  </React.Fragment>
                )}
              </Column>
            ) : (
              this.popupText({
                minimized,
                sviException,
                unhealthyReason,
                eventTypeMessage,
              })
            )
          const html = renderToString(component())
          let element
          return (
            <div
              ref={(ref) => (element = ref)}
              style={{ overflow: 'hidden' }}
              role="button"
              tabIndex="-1"
              onClick={() => showHidablePopover(element, html)}
            >
              {component('#4E92DF', true)}
            </div>
          )
        },
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'status',
          }),
      },
      {
        filterable: true,
        Header: renderHeaderCell('Status Began', true),
        accessor: 'occurredAt',
        Cell: ({ original: { occurredAt } }) => (
          <CellContainer>{occurredAt}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'statusBegan',
          }),
      },
      {
        id: 'duration',
        filterable: true,
        Header: renderHeaderCell('Duration', true),
        accessor: 'duration',
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'duration',
          }),
      },
    ]

    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Current Exceptions</Header>
          <AsyncLoadingStatus
            current={loadingStatus}
            cancel={onAsyncLoadingCancel}
            errors={errors}
          />
          <HeaderButtonsContainer>
            <div className="form-inline">
              <div className="form-group mx-sm-3">{this.csvDataLink()}</div>
              <div className="form-group mx-sm-3">
                <button
                  type="button"
                  className="btn btn-primary btn-raised mb-0"
                  onClick={createFilter}
                >
                  Create Filter
                </button>
              </div>
            </div>
          </HeaderButtonsContainer>
        </HeaderContainer>
        {loading ? (
          <React.Fragment>
            <TablePreloader columnsCount={7} loaderWidth={1200} />
            <ProgressIndicator text={'Loading...'} />
          </React.Fragment>
        ) : (
          <ReactTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            data={exceptions}
            columns={columns}
            defaultFilterMethod={fullTextFilter}
            getTdProps={() => ({
              onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            })}
          />
        )}
      </div>
    )
  }
}

ExceptionsPageScreen.propTypes = {
  exceptions: PropTypes.array.isRequired,
  onAsyncLoadingCancel: PropTypes.func,
  csvData: PropTypes.array.isRequired,
  createFilter: PropTypes.func.isRequired,
  loadingStatus: PropTypes.object,
  errors: PropTypes.array,
  loading: PropTypes.bool,
  onExportAsCSV: PropTypes.func.isRequired,
}

export default ExceptionsPageScreen
