import {
  DeviceType,
  ArmState,
  HealthStatus,
  ComplianceStatus,
  PositionRuleStatus,
  getMainPositionState,
  fixPositionState,
  fixAction,
} from 'mti-jsclient-shared'

import { removeDuplicates, safeUri } from './utils'

import {
  defaultName,
  sviFigureType,
  mtiFixtureType,
  isFixture,
} from './mtiCanvasUtils'

export const canvasSize = {
  width: 600,
  height: 400,
}

export const DbClickMilliseconds = 250

export const LongPressMilliseconds = 800
export class LongTapResolver {
  constructor() {
    this.lastTarget = undefined
  }

  mouseUp() {
    this.lastTarget = undefined
  }

  mouseDown(target) {
    const { id } = target || {}
    this.lastTarget = id
  }

  isLongTap(target) {
    const { id } = target || {}
    return id && id === this.lastTarget
  }
}

export function splitRolesByName(roles) {
  if (!roles) return roles
  return {
    organizationAdmin: roles.filter(
      ({ name }) => name === 'Organization Administrator'
    ),
    regionalManager: roles.filter(({ name }) => name === 'Regional Manager'),
    storeManager: roles.filter(({ name }) => name === 'Store Manager'),
    storeAssociate: roles.filter(({ name }) => name === 'Store Associate'),
  }
}

// API FIX: remove duplication, fix role names
export function fixApiRolesCache(rolesCache) {
  if (!rolesCache) return rolesCache
  return removeDuplicates(rolesCache, 'resourceId').map(i => ({
    ...i,
    name: fixRoleName(i.name),
  }))
}

export function fixRoleName(name) {
  switch (name) {
    case 'mti_admin':
      return 'MTI Admin'
    case 'organization_administrator':
      return 'Organization Administrator'
    case 'organization_technical_support':
      return 'Organization Technical Support'
    case 'regional_manager':
      return 'Regional Manager'
    case 'store_manager':
      return 'Store Manager'
    case 'store_associate':
      return 'Store Associate'
    default:
      return name
  }
}

export function handleSingleCreateEdit(
  o,
  selectedObject,
  canvas,
  onMessage,
  onChanged,
  name
) {
  const localObjsCount = localObjectsCount(canvas.getObjects())
  const selectedInstance = canvas.getActiveObject()
  const isCreation = !selectedInstance || isNaN(selectedInstance.id)

  if (o.target) {
    const selectedIndex = canvas.getObjects().indexOf(o.target)
    // TODO: simplification?
    if ((isCreation && selectedObject === -1) || selectedObject === -1) {
      onMessage(`Click and drag on empty area to create new ${name}`)
      onChanged()
    } else if (selectedObject !== selectedIndex) {
      // onMessage(`Save, Delete or Cancel edits for selected ${name}`) // handle silently
      onChanged()
    }
    return true
  }

  if (!o.target && (!isCreation || (isCreation && localObjsCount === 1))) {
    // onMessage(`Save, Delete or Cancel edits for selected ${name}`) // handle silently
    onChanged()
    return true
  } else if (!o.target && selectedObject !== -1) {
    // onMessage(`Save, Delete or Cancel edits for selected ${name}`) // handle silently
    onChanged()
    return true
  }

  return false
}

export function handleSingleCreateEditFixture(
  o,
  selectedObject,
  canvas,
  onMessage,
  onChanged,
  name
) {
  const localObjsCount = localObjectsCount(canvas.getObjects())
  const selectedInstance = canvas.getActiveObject()
  const isCreation = !selectedInstance || isNaN(selectedInstance.id)
  if (
    (localObjsCount > 0 && !selectedInstance && selectedObject === -1) ||
    (localObjsCount > 1 && o.target)
  ) {
    // autoplacement
    onMessage('Click Save All positions in sidepanel or Cancel')
    return true
  }
  if (o.target && !isFixture(o.target.mtiType)) {
    const selectedIndex = canvas.getObjects().indexOf(o.target)
    if ((isCreation && !selectedObject) || selectedObject === -1) {
      onMessage(`Click and drag on empty white area to create new ${name}`)
      onChanged()
    } else if (selectedObject !== selectedIndex) {
      onMessage(`Save, Delete or Cancel edits for selected ${name}`)
      onChanged()
    }
    return true
  }
  if (
    o.target &&
    isFixture(o.target.mtiType) &&
    (!isCreation || (isCreation && localObjsCount === 1))
  ) {
    // onMessage(`Save, Delete or Cancel edits for selected ${name}`) // handle silently
    onChanged()
    return true
  } else if (o.target && isFixture(o.target.mtiType) && selectedObject !== -1) {
    // onMessage(`Save, Delete or Cancel edits for selected ${name}`)
    onChanged()
    return true
  } else if (
    !o.target &&
    (!isCreation || (isCreation && localObjsCount === 1))
  ) {
    // onMessage(`Save, Delete or Cancel edits for selected ${name}`) // handle silently
    onChanged()
    return true
  } else if (o.target && selectedObject !== -1) {
    onMessage(`Save, Delete or Cancel edits for selected ${name}`)
    onChanged()
    return true
  } else if (
    !o.target &&
    (!isCreation || (isCreation && localObjsCount === 1))
  ) {
    onMessage(`Save, Delete or Cancel edits for selected ${name}`)
    onChanged()
    return true
  } else if (!o.target && localObjsCount === 0) {
    // onMessage(`Save, Delete or Cancel edits for selected ${name}`) // handle silently
    onChanged()
    return true
  }
  return false
}

export const sviFigureRotationAngles = [0, 90, 180, 270]

export const sviRectRotationAngles = [0, 90]

export function isVerticalRect(figure) {
  const width = figure.width * figure.scaleX
  const height = figure.height * figure.scaleY
  const aRatio = height / width

  if (aRatio > 1) {
    return true
  } else {
    return false
  }
}

export function fromGeometryToLayoutPosition(geometry) {
  if (!geometry || !geometry.width) return geometry
  const { x, y, width, height, angle, resourceId, type, size } = geometry
  // xCenter, yCenter, width, height, theta, targetId
  return {
    ...geometry,
    xCenter: x ? x.toString() : x,
    yCenter: y ? y.toString() : y,
    width: width ? width.toString() : width,
    height: height ? height.toString() : height,
    theta: angle ? angle.toString() : angle,
    targetId: resourceId,
    type,
    size,
    // geometryType: "layout_position",
  }
}

export function fromLayoutPositionToGeometry(layoutPosition) {
  if (!layoutPosition) return layoutPosition
  const { xCenter, yCenter, width, height, theta, targetId } = layoutPosition
  // x, y, width, height, angle, resourceId
  return {
    ...layoutPosition,
    x: xCenter ? parseFloat(xCenter) : xCenter,
    y: yCenter ? parseFloat(yCenter) : yCenter,
    width: width ? parseFloat(width) : width,
    height: height ? parseFloat(height) : height,
    angle: theta ? parseFloat(theta) : theta,
    resourceId: targetId,
  }
}

export function mutateLayoutPositions(layoutPositions, geometries) {
  if (!geometries || geometries.length === 0) return layoutPositions
  if (!layoutPositions || layoutPositions.length === 0) {
    return geometries.map(g => fromGeometryToLayoutPosition(g))
  }
  let geometriesFiltered = geometries
  const lpsMutated = layoutPositions.map(lp => {
    const geometry = geometries.filter(g => g.resourceId === lp.targetId)
    if (!geometry || geometry.length === 0 || !geometry[0].width) {
      return lp
    }
    geometriesFiltered = geometriesFiltered.filter(
      gf => gf.resourceId !== geometry.resourceId
    )
    return fromGeometryToLayoutPosition(geometry)
  })
  const lpsFromGeometries = geometriesFiltered.map(g =>
    fromGeometryToLayoutPosition(g)
  )
  return lpsMutated.concat(lpsFromGeometries)
}

export function fixGeometryId(o, geometries) {
  if (!geometries) return o
  return {
    ...o,
    geometryId:
      (geometries.find(g => g.resourceId === o.id) || {}).width ||
      (geometries.find(g => g.id === o.geometryId) || {}).width
        ? o.geometryId
        : undefined,
  }
}

// API FIX: Delete empty geometries objects
export function deleteEmptyGeometryRefs(store) {
  if (!store) return store
  const { geometries } = store
  if (!geometries) return store

  let s = store
  if (s.areas) {
    s = { ...s, areas: s.areas.map(o => fixGeometryId(o, geometries)) }
  }
  if (s.fixtures) {
    s = {
      ...s,
      fixtures: s.fixtures.map(o => fixGeometryId(o, geometries)),
    }
  }
  if (s.positions) {
    s = {
      ...s,
      positions: s.positions.map(o => fixGeometryId(o, geometries)),
    }
  }
  return s
}

export function filterOutEmptyGeometries(store) {
  if (!store) return store
  const { geometries } = store
  if (!geometries) return store
  return {
    ...store,
    geometries: geometries.filter(g => g.width),
  }
}

// API FIX: Add id to geometry objects
export function addIdsToGeometries(store) {
  if (!store) return store
  const { geometries, areas, fixtures, positions } = store
  if (!geometries) return store
  const objs = (areas || []).concat(fixtures || []).concat(positions || [])
  return {
    ...store,
    geometries: geometries.map(g => ({
      ...g,
      id: g.id || (objs.find(o => o.id === g.resourceId) || {}).geometryId,
    })),
  }
}

// API FIX: Add resourceId to geometry objects
export function addResourceIdsToGeometries(store) {
  if (!store) return store
  const { geometries, areas, fixtures, positions } = store
  if (!geometries) return store
  const objs = (areas || []).concat(fixtures || []).concat(positions || [])
  return {
    ...store,
    geometries: geometries.map(g => ({
      ...g,
      resourceId:
        (objs.find(o => o.geometryId === g.id) || {}).id || g.resourceId,
    })),
  }
}

export function fixStoreApi(store) {
  return addResourceIdsToGeometries(
    addIdsToGeometries(filterOutEmptyGeometries(deleteEmptyGeometryRefs(store)))
  )
}

export function mutateLpResourceId(o) {
  const { geometryId } = o
  if (geometryId) {
    return { ...o, layoutPositionId: geometryId }
  }
  return o
}

const securityDeviceTypeDescription =
  'This position must have a security device type of'

export function isSecurityDeviceTypeDescription(rule) {
  const { description } = rule
  if (description.startsWith(securityDeviceTypeDescription)) {
    return true
  } else {
    return false
  }
}

const complianceExceptionAction = 'Merchandise'

export function isComplianceExceptionAction(action) {
  return action.startsWith(complianceExceptionAction)
}

export function splitGeometries(store) {
  const { layoutPositions } = store
  if (!layoutPositions) return store
  return {
    ...store,
    areaLayoutPositions: layoutPositions.filter(
      ({ resourceType }) => resourceType === 'Area'
    ),
    fixtureLayoutPositions: layoutPositions.filter(
      ({ resourceType }) => resourceType === 'Fixture'
    ),
    positionLayoutPositions: layoutPositions.filter(
      ({ resourceType }) => resourceType === 'Position'
    ),
  }
}

const oldRuleDescriptions = [
  'This position must be placed in a specific location on the fixture.',
  'This position must not be placed in a specific location on the fixture.',
  'The fixture size must be',
  "This fixture must match the template's positions.",
]

export function isOldRule(rule, property = 'description') {
  const description = rule[property]
  if (!description) {
    return false
  }

  if (typeof description !== 'string') {
    return true
  }

  if (oldRuleDescriptions.find(d => description.startsWith(d))) {
    return true
  } else {
    return false
  }
}

// API FIX: remove old gen 1 rules from api response
export function removeOldRules(store) {
  const { rules, fixtures, positions } = store || {}
  const oldRuleIds = (rules || [])
    .filter(r => isOldRule(r))
    .map(({ id }) => id)
  if (!oldRuleIds || oldRuleIds.length === 0) return store
  const fs = (fixtures || []).map(f => {
    const { ruleIds } = f
    if (!ruleIds || ruleIds.length === 0) return f
    const rIds = ruleIds.filter(id => !oldRuleIds.includes(id))
    return { ...f, ruleIds: rIds }
  })
  const ps = (positions || []).map(p => {
    const { ruleIds } = p
    if (!ruleIds || ruleIds.length === 0) return p
    const rIds = ruleIds.filter(id => !oldRuleIds.includes(id))
    return { ...p, ruleIds: rIds }
  })
  const rs = (rules || []).filter(({ id }) => !oldRuleIds.includes(id))
  return {
    ...store,
    fixtures: fs,
    positions: ps,
    rules: rs,
  }
}

export function mutateStore(badStore) {
  if (!badStore) return badStore
  const store = fixStoreApi(badStore)
  let s = store
  if (s.areas) {
    s = { ...s, areas: s.areas.map(a => mutateLpResourceId(a)) }
  }
  if (s.fixtures) {
    s = { ...s, fixtures: s.fixtures.map(f => mutateLpResourceId(f)) }
  }
  if (s.positions) {
    s = { ...s, positions: s.positions.map(p => mutateLpResourceId(p)) }
  }
  if (s.layoutPositions || s.geometries) {
    s = {
      ...s,
      layoutPositions: mutateLayoutPositions(s.layoutPositions, s.geometries),
    }
  }
  s = removeOldRules(s)
  s = splitGeometries(s)
  s = fixFixtureRulesFieldName(s)
  return s
}

export function apiFixGeometryResourceId(store, name) {
  if (!store) return store
  const { geometries } = store
  return {
    ...store,
    geometries: (geometries || []).map(g => ({
      ...g,
      resourceId: ((store[name] || [])[0] || {}).id,
    })),
  }
}

export function filterLayoutPositionGeometry(store) {
  let s = store
  if (s.layoutPositions) {
    s = {
      ...s,
      layoutPositions: s.layoutPositions.filter(
        lp => lp.geometryType === 'layout_position'
      ),
    }
  }
  return s
}

export function setCoordsIfNot(store) {
  if (!store) return store
  const { geometries } = store
  return {
    ...store,
    geometries: (geometries || []).map(g => ({
      ...g,
      x: g.x ? g.x : 0.5 + (g.width || 0) / 2,
      y: g.y ? g.y : 0.5 - (g.height || 0) / 2,
    })),
  }
}

export function fixFixtureRulesFieldName(store) {
  if (!store) return store
  return {
    ...store,
    fixtures: (store.fixtures || []).map(f => ({
      ruleIds: f.ruleFixtureIds,
      ...f,
    })),
  }
}

export const defaultFloor = {
  order: 1,
  name: defaultName.defaultFloor,
}

export const defaultArea = {
  displayAreaName: true,
  height: 0.2667,
  name: defaultName.defaultArea,
  theta: 0,
  width: 0.4,
  xCenter: 0.215,
  yCenter: 0.333,
}

export function localObjectsCount(objects) {
  return (objects || []).filter(o => isNaN(o.id)).length
}

export function getAutoPlacementMax(type) {
  switch (type) {
    case sviFigureType.wideRectangle:
    case sviFigureType.square:
    case sviFigureType.circle:
    case mtiFixtureType.circle:
      return 16
    case sviFigureType.custom:
    case sviFigureType.narrowRectangle:
    case mtiFixtureType.table:
    case mtiFixtureType.extendedQuarterCircle:
    case sviFigureType.extendedRightQuarterCircle:
    case sviFigureType.extendedLeftQuarterCircle:
    case mtiFixtureType.wall:
    case mtiFixtureType.wallCap:
      return 12
    case mtiFixtureType.quarterCircle:
      return 10
    default:
      return 12
  }
}

export function friendlyDeviceType(deviceType) {
  switch (deviceType) {
    case DeviceType.BASE:
      return 'Base'
    case DeviceType.PUCK:
      return 'Puck'
    case DeviceType.LOCK:
      return 'Lock'
    case DeviceType.ALARM_MODULE:
      return 'Alarm Module'
    case DeviceType.DUMB_PUCK:
      return 'Puck'
    case DeviceType.RAM:
      return 'RAM'
    case DeviceType.SECURE_PLUG:
      return 'Secure Plug'
    case DeviceType.CX_FLEX:
      return 'CX Flex 1 Port'
    case DeviceType.CX_FLEX_ONE_PORT:
      return 'CX Flex 1 Port'
    case DeviceType.CX_FLEX_FOUR_PORT:
      return 'CX Flex 4 Port'
    case DeviceType.BASE_V2:
      return 'FM II Base'
    case DeviceType.PUCK_V2:
      return 'FM II Puck'
    case DeviceType.PROXIMITY_PUCK:
      return 'Proximity Puck'
    case DeviceType.PROXIMITY_BASE:
      return 'Proximity Base'
    case DeviceType.NXDI_PUCK:
      return 'Freedom Focus Puck'
    case DeviceType.NXDI_RISER:
      return 'Freedom Focus Riser'
    default:
      return deviceType
  }
}

export function getPositionItemData(p, storeId, storeName) {
  return fixAction({
    ...p.ref,
    storeId,
    storeName,
    fixtureName: ((p.fixtureId || {}).ref || {}).name,
    deviceType: ((p.securityDeviceId || {}).ref || {}).deviceType,
    status: getMainPositionState(fixPositionState(p.getStates())),
    path: `${p.areaName || ''} / ${((p.fixtureId || {}).ref || {}).name || ''}`,
  })
}

export function getPositionItemDataExtended(p, storeId, storeName) {
  const rules = p.ruleIds.toRefArray()

  return {
    ...getPositionItemData(p, storeId, storeName),
    rules,
  }
}

export function filterIssues(positions) {
  return positions.filter(p => {
    if (!p.status && !p.status.type) return true
    switch (p.status.type) {
      case ArmState.ARMED: // GREEN
      case ArmState.LOCKED: // GREEN
      case HealthStatus.HEALTHY: // GREEN
      case ComplianceStatus.COMPLIANT: // GREEN
        return false
      default:
        return true
    }
  })
}

/**
 * Create smart store layout link based on floors & positions count
 *
 * @param store
 * @returns {object}
 */
export function createSmartStoreLayoutLink(store) {
  const { id, name, floors, areas } = store || {}
  if (!floors || !areas) {
    return {
      path: '',
      text: '',
    }
  }
  const [{ name: fName = defaultName.floor, id: fId = 'default' } = {}] = floors
  const [
    { name: aName = defaultName.defaultArea, id: aId = 'default' } = {},
  ] = areas

  if (floors.length > 1) {
    return {
      path: `${orgUrl()}/stores/${id}/${safeUri(name)}/floors`,
      text: 'VIEW STORE LAYOUT',
    }
  }

  if (areas.length > 1) {
    return {
      path: `${orgUrl()}/stores/${id}/${safeUri(name)}/floors/${fId}/${safeUri(
        fName
      )}`,
      text: 'VIEW STORE LAYOUT',
    }
  }

  return {
    path: `${orgUrl()}/stores/${id}/${safeUri(name)}/floors/${fId}/${safeUri(
      fName
    )}/areas/${aId}/${safeUri(aName)}`,
    text: 'VIEW STORE LAYOUT',
  }
}

export function createStoreSecuredProductsLink(store) {
  const { id, name } = store || {}
  return {
    path: `stores/${id}/${safeUri(name)}/secured_products`,
    text: 'SECURED PRODUCTS',
  }
}

export function createStoreTableViewLink(store) {
  const { id, name } = store || {}
  return {
    path: `stores/${id}/${safeUri(name)}/layout_table_view`,
    text: 'TABLE VIEW',
  }
}

/**
 * Creates organization url if the user has a MTI Admin role
 * or an emty String in all other cases
 *
 * @returns {String}
 */
export function orgUrl() {
  const pathParams = window.location.pathname.split('/') || []
  const url =
    pathParams[1] === 'organizations'
      ? `/organizations/${pathParams[2]}/${pathParams[3]}`
      : ''
  return url
}

/**
 * Creates organization url crumbs if the user has a MTI Admin role
 * or an emty String in all other cases
 *
 * @returns {String}
 */
export function orgUrlCrumbs(isMtiAdmin) {
  if (isMtiAdmin) return '/organizations/:oId/:oName'
  const pathParams = window.location.pathname.split('/') || []
  const url =
    pathParams[1] === 'organizations' ? '/organizations/:oId/:oName' : ''
  return url
}

/**
 * Get Tags By Store
 *
 * @param taggings
 * @returns {array}
 */
export function getTagsByStore(taggings) {
  return ((taggings || {}).stores || []).map(store => ({
    id: store.id,
    taggings: ((taggings || {}).taggings || [])
      .filter(tagging => tagging.storeId === store.id)
      .map(tagging => ({
        ...tagging,
        tag: ((taggings || {}).tags || [])
          .filter(tag => tag.id === tagging.tagId)
          .map(tag => ({
            ...tag,
            tagset: ((taggings || {}).tagsets || []).filter(
              tagset => tagset.id === tag.tagsetId
            ),
          })),
      })),
  }))
}

/**
 * Get Org Tags
 *
 * @param tags
 * @returns {object}
 */
export function getOrgTags(tags) {
  return {
    tagsets: ((tags || {}).tagsets || []).map(tagset => ({
      ...tagset,
      tags: ((tags || {}).tags || []).filter(
        tag => tag.tagsetId === tagset.id
      ),
    })),
    tags: ((tags || {}).tags || []).filter(tag => tag.tagsetId === null),
  }
}

export function fixPersonae(data) {
  if (!data || !data.personae) return data
  // filter out 'Organization User' role, TODO: skip after api update
  return {
    ...data,
    personae: data.personae.filter(o => o.name !== 'Organization User'),
  }
}

export function getPermissionRegions(
  mtiRoles,
  mtiRolesPermission,
  sId,
  regions
) {
  if ((mtiRoles || []).filter(({ name }) => name === 'MTI Admin').length > 0)
    return true
  if (!sId || !mtiRoles) {
    return false
  }
  const resourceId = parseFloat(sId)
  const region = (regions || []).find(r =>
    r.storeIds.find(id => id === resourceId)
  )
  if (!region) return false
  const assignment = mtiRoles.find(a => a.resourceId === region.id)
  const regionalPermission =
    !!region && mtiRolesPermission[(assignment || {}).name]
  return regionalPermission
}

export function getPermission(mtiRoles, mtiRolesPermission, resId, regions) {
  if ((mtiRoles || []).filter(({ name }) => name === 'MTI Admin').length > 0)
    return true
  if (!resId || !mtiRoles) {
    return false
  }
  const resourceId = parseFloat(resId)
  const roles = combineRoles(mtiRoles, regions)
  const assignment = roles.find(a => a.resourceId === resourceId)

  const resourcePermission = mtiRolesPermission[(assignment || {}).name]
  return resourcePermission
}

export function getPermissionAnyResource(mtiRoles, mtiRolesPermission) {
  if ((mtiRoles || []).filter(({ name }) => name === 'MTI Admin').length > 0)
    return true
  if (!mtiRoles) {
    return false
  }
  const persona = mtiRoles.find(p => mtiRolesPermission[p.name])
  const resourcePermission = !!persona
  return resourcePermission
}

export function getPermissionResources(mtiRoles, role, regions) {
  if ((mtiRoles || []).filter(({ name }) => name === 'MTI Admin').length > 0)
    return true
  if (!mtiRoles) {
    return undefined
  }
  const roles = combineRoles(mtiRoles, regions)
  const resources = roles
    .filter(({ name }) => name === role)
    .map(({ resourceId }) => resourceId)
  return resources
}

export function hasRole(mtiRoles, role, resId, regions) {
  if (!resId || !mtiRoles) {
    return false
  }
  const resourceId = parseFloat(resId)
  const roles = combineRoles(mtiRoles, regions)
  const assignment = roles.find(a => a.resourceId === resourceId)
  const hasResourceRole = (assignment || {}).name === role
  return hasResourceRole
}

export function hasRoleAnyResource(mtiRoles, role) {
  if (!mtiRoles) {
    return false
  }
  const persona = mtiRoles.find(p => p.name === role)
  const hasResourceRole = !!persona
  return hasResourceRole
}

export function hasAccess({
  mtiRoles,
  mtiRolesPermission,
  organizationId,
  resourceId,
  regions,
}) {
  // Organization level access
  const orgPermission = getPermission(
    mtiRoles,
    mtiRolesPermission,
    organizationId
  )
  // Other than Organization level access
  const storePermission = getPermission(
    mtiRoles,
    mtiRolesPermission,
    resourceId
  )
  const regionalPermission = getPermissionRegions(
    mtiRoles,
    mtiRolesPermission,
    resourceId,
    regions
  )
  return orgPermission || storePermission || regionalPermission
}

export function flattenRegions(regions) {
  if (!regions || regions.length === 0) return []
  return regions
    .reduce((flat, toFlatten) => flat.concat(toFlatten.storeIds), [])
    .map(sId => ({ name: 'Regional Manager', resourceId: sId }))
}

export function combineRoles(mtiRoles, regions) {
  if (!mtiRoles || mtiRoles.length === 0) return []
  const regionalStoresRoles = flattenRegions(regions)
  return mtiRoles.concat(regionalStoresRoles)
}

/*
export function getPermissionRegions(
  mtiRoles,
  mtiRolesPermission,
  sId,
  regions
) {
  if (!sId || !mtiRoles || !mtiRoles.assignments || !mtiRoles.personae) {
    return false
  }
  const resourceId = parseFloat(sId)
  const region = regions.find((r) => r.stores.find((s) => s.id === resourceId))
  if (!region) return false
  const { assignments, personae } = mtiRoles
  const assignment = assignments.find((a) => a.resourceId === region.id)
  const persona = personae.find((p) => p.id === (assignment || {}).personaId)
  const regionalPermission =
    !!region && mtiRolesPermission[(persona || {}).name]
  return regionalPermission
}

export function getPermission(mtiRoles, mtiRolesPermission, resId) {
  if (!resId || !mtiRoles || !mtiRoles.assignments || !mtiRoles.personae) {
    return false
  }
  const resourceId = parseFloat(resId)
  const { assignments, personae } = mtiRoles
  const assignment = assignments.find((a) => a.resourceId === resourceId)
  const persona = personae.find((p) => p.id === (assignment || {}).personaId)
  const resourcePermission = mtiRolesPermission[(persona || {}).name]
  return resourcePermission
}

export function getPermissionAnyResource(mtiRoles, mtiRolesPermission) {
  if (!mtiRoles || !mtiRoles.personae) {
    return false
  }
  const { personae } = mtiRoles
  const persona = personae.find((p) => mtiRolesPermission[p.name])
  const resourcePermission = !!persona
  return resourcePermission
}

export function getPermissionResources(mtiRoles, role) {
  if (!mtiRoles || !mtiRoles.personae) {
    return undefined
  }
  const { personae, assignments } = mtiRoles
  const persona = (personae || []).find((p) => p.name === role)
  if (!persona) return undefined
  const resources = assignments
    .filter(({ personaId }) => personaId === persona.id)
    .map(({ resourceId }) => resourceId)
  return resources
}

export function hasRole(mtiRoles, role, resId) {
  if (!resId || !mtiRoles || !mtiRoles.assignments || !mtiRoles.personae) {
    return false
  }
  const resourceId = parseFloat(resId)
  const { assignments, personae } = mtiRoles
  const assignment = assignments.find((a) => a.resourceId === resourceId)
  const persona = personae.find((p) => p.id === (assignment || {}).personaId)
  const hasResourceRole = (persona || {}).name === role
  return hasResourceRole
}

export function hasRoleAnyResource(mtiRoles, role) {
  if (!mtiRoles || !mtiRoles.assignments || !mtiRoles.personae) {
    return false
  }
  const { personae } = mtiRoles
  const persona = personae.find((p) => p.name === role)
  const hasResourceRole = !!persona
  return hasResourceRole
}

export function hasAccess({
  mtiRoles,
  mtiRolesPermission,
  organizationId,
  resourceId,
  regions,
}) {
  // Organization level access
  const orgPermission = getPermission(
    mtiRoles,
    mtiRolesPermission,
    organizationId
  )
  // Other than Organization level access
  const storePermission = getPermission(
    mtiRoles,
    mtiRolesPermission,
    resourceId
  )
  const regionalPermission = getPermissionRegions(
    mtiRoles,
    mtiRolesPermission,
    resourceId,
    regions
  )
  return orgPermission || storePermission || regionalPermission
}
*/
// TODO: Use from shared
export const sortRules = rules =>
  rules.sort((first, second) => {
    if (
      first.status === PositionRuleStatus.OK &&
      second.status === PositionRuleStatus.VIOLATION
    ) {
      return 1
    } else if (
      first.status === PositionRuleStatus.VIOLATION &&
      second.status === PositionRuleStatus.OK
    ) {
      return -1
    }
    return 0
  })

/**
 * Get Edit User Grade Access
 * @param isCurrentUser
 * @param hasMtiAdminRole for current user
 * @param hasOrganizationAdminRole for current user
 * @param hasRegionalManagerRole for current user
 * @param hasStoreManagerRole for current user
 * @param hasEditUserPermission for current user
 * @param assignedTo roles of edit candidate
 * @return {Boolean}
 */
export function getEditUserGradeAccess(
  isCurrentUser,
  hasMtiAdminRole,
  hasOrganizationAdminRole,
  hasRegionalManagerRole,
  hasStoreManagerRole,
  hasEditUserPermission,
  assignedTo
) {
  if (isCurrentUser) return true
  if (!hasEditUserPermission) return false
  const { personae = [] } = assignedTo || {}

  const isOrganizationAdministrator =
    personae.filter(({ name }) => name === 'Organization Administrator')
      .length > 0
  const isRegionalManager =
    personae.filter(({ name }) => name === 'Regional Manager').length > 0
  const isStoreManager =
    personae.filter(({ name }) => name === 'Store Manager').length > 0
  // As a Mti admin I can edit everyone
  if (hasMtiAdminRole) return true
  // As an Organization administrator I can edit other Org admins and everyone below
  if (hasOrganizationAdminRole) return true
  // Regional Manager I can only edit Store Managers and Store Associates
  if (
    hasRegionalManagerRole &&
    !isOrganizationAdministrator &&
    !isRegionalManager
  )
    return true
  // As a Store Manager I can only edit Store Associates
  if (hasStoreManagerRole && !isStoreManager) return true
  //
  return false
}

/**
 * Get Add Role Button States
 * @param roles
 * @return {Boolean}
 */
export function getAddRoleButtonStates(
  roles,
  newOrganization,
  newRegions,
  newStores
) {
  const { personae = [] } = roles || {}

  const isOrganizationAdministrator =
    personae.filter(({ name }) => name === 'Organization Administrator')
      .length > 0
  const isRegionalManager =
    personae.filter(({ name }) => name === 'Regional Manager').length > 0
  const isStoreManager =
    personae.filter(({ name }) => name === 'Store Manager').length > 0
  const isStoreAssociate =
    personae.filter(({ name }) => name === 'Store Associate').length > 0
  //
  if (isOrganizationAdministrator)
    return {
      addStoreAssociate: false,
      addStoreManager: false,
      addRegion: false,
      addOrg: false,
    }
  //
  if (isRegionalManager)
    return {
      addStoreAssociate: false,
      addStoreManager: false,
      addRegion: true,
      addOrg: false,
    }
  //
  if (isStoreManager)
    return {
      addStoreAssociate: false,
      addStoreManager: true,
      addRegion: false,
      addOrg: false,
    }
  //
  if (isStoreAssociate)
    return {
      addStoreAssociate: true,
      addStoreManager: false,
      addRegion: false,
      addOrg: false,
    }

  //
  if ((newOrganization || []).length > 0)
    return {
      addStoreAssociate: false,
      addStoreManager: false,
      addRegion: false,
      addOrg: false,
    }
  //
  if ((newRegions || []).length > 0)
    return {
      addStoreAssociate: false,
      addStoreManager: false,
      addRegion: true,
      addOrg: false,
    }
  //
  if ((newStores || []).length > 0)
    return {
      addStoreAssociate: newStores[0].personaeNames.includes('Store Associate'),
      addStoreManager: newStores[0].personaeNames.includes('Store Manager'),
      addRegion: false,
      addOrg: false,
    }

  //
  return {
    addStoreAssociate: true,
    addStoreManager: true,
    addRegion: true,
    addOrg: true,
  }
}

export function overlayDimensions(areaDimensions, canvasSize) {
  const { layoutPosition: { width: aWidth, height: aHeight } } = areaDimensions
  const { width: cWidth, height: cHeight } = canvasSize
  const height = aHeight < aWidth ? cWidth * aHeight / aWidth : cHeight
  const width = aHeight > aWidth ? cHeight * aWidth / aHeight : cWidth
  const heightOverlay = height > cHeight ? cHeight : height
  const widthOverlay = height > cHeight ? cHeight * aWidth / aHeight : width
  return { height: heightOverlay, width: widthOverlay }
}
