import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects'
import {
  getKeys,
  putKey,
  postKey,
  deleteKey as deleteKeyApi,
} from '../../../api'
import { makeSelectToken, makeSelectKeys } from 'containers/App/selectors'
import { makeSelectNewKeys } from '../../Keys/selectors'
import { ActionTypes } from '../constants'
import {
  fetchKeysPending,
  fetchKeysFulfilled,
  fetchKeysFailed,
  updateKeyPending,
  updateKeyFinished,
  updateKeyFailed,
  postKeyPending,
  postKeyFinished,
  postKeyFailed,
  updateKeysData,
  deleteKeyPending,
  deleteKeyFailed,
  deleteKeyFinished,
} from '../actions'
import { errorToast, successToast } from '../../../utils/utils'

export function* loadKeys({ payload }) {
  const { storeId } = payload
  const token = yield select(makeSelectToken())
  try {
    const preloadedKeys = yield select(makeSelectKeys(storeId.toString()))
    if (!preloadedKeys || !preloadedKeys.size) {
      yield put(fetchKeysPending(storeId))
    }
    const keys = yield call(getKeys, token, storeId)
    yield put(fetchKeysFulfilled(storeId, keys))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fetchKeysFailed(errorObj))
    errorToast('Load keys failed')
  }
}

export function* addKeys({ payload }) {
  const { organizationId, storeId } = payload
  const keys = yield select(makeSelectNewKeys())
  yield all(
    keys.map((key) =>
      call(addKey, { payload: { ...key, organizationId, storeId } })
    )
  )
  yield call(loadKeys, { payload: { storeId } })
}

export function* addKey({ payload }) {
  const {
    status,
    organizationId,
    storeId,
    serialNumber,
    userId,
    id,
    backupKey,
  } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(postKeyPending(storeId))
    const data = yield call(
      postKey,
      token,
      status,
      organizationId,
      storeId,
      serialNumber,
      userId || null,
      backupKey || null
    )
    successToast('Key Added')
    yield put(postKeyFinished(storeId, { ...data, uiId: id }))
  } catch (error) {
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    console.log(errorObj)
    yield put(postKeyFailed(errorObj))
    errorToast('Add key failed')
  }
}

export function* updateKey({ payload }) {
  const { id, status, storeId, serialNumber, userId } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(updateKeyPending(storeId, id))
    const data = yield call(
      putKey,
      token,
      id,
      status,
      storeId,
      serialNumber,
      userId || null
    )
    successToast('Key Updated')
    yield put(updateKeyFinished(storeId, data, id))
    yield put(updateKeysData(storeId, data))
  } catch (error) {
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    console.log(errorObj)
    yield put(updateKeyFailed(errorObj))
    errorToast('Update key failed')
  }
}

export function* deleteKey({ payload }) {
  const { id, serialNumber } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(deleteKeyPending(id))
    yield call(deleteKeyApi, token, id, serialNumber)
    successToast(`${serialNumber} Key Deleted`)
    yield put(deleteKeyFinished())
  } catch (error) {
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(deleteKeyFailed(errorObj))
    errorToast(`Delete key ${serialNumber} failed`)
  }
}

export function* deleteKeys({ payload }) {
  const { keys } = payload
  yield all(keys.map((key) => call(deleteKey, { payload: key })))
  // yield call(loadKeys, { payload: { storeId } })
}

export function* activateKeys({ payload }) {
  const { items, status } = payload
  yield all(
    items
      .filter((i) => i.status !== status)
      .map((key) => call(updateKey, { payload: { ...key, status } }))
  )
  // yield call(loadKeys, { payload: { storeId } })
}

/**
 * Keys Sagas
 */
export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.LOAD_KEYS, loadKeys),
    yield takeEvery(ActionTypes.ADD_KEY, addKey),
    yield takeEvery(ActionTypes.ADD_KEYS, addKeys),
    yield takeEvery(ActionTypes.DELETE_KEY, deleteKey),
    yield takeEvery(ActionTypes.UPDATE_KEY, updateKey),
    yield takeEvery(ActionTypes.ACTIVATE_KEYS, activateKeys),
    yield takeEvery(ActionTypes.DELETE_KEYS, deleteKeys),
  ])
}
