/**
 *
 * ModeSwitcher
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Radio from '../../../components/Radio'

const HeightContainer = styled.div`
  height: 32px;
  position: relative;
`

const CenterContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

function ModeSwitcher({ isCreateNewUser, onChange }) {
  return (
    <HeightContainer>
      <CenterContainer>
        <Radio
          id={'create-new-user'}
          name={'create-new-user'}
          label={'Create New User'}
          onChange={() => onChange({ isCreateNewUser: !isCreateNewUser })}
          checked={isCreateNewUser}
        />
        <div style={{ width: 20 }} />
        <Radio
          id={'add-existing-user'}
          name={'add-existing-user'}
          label={'Add existing user to a selected Store'}
          onChange={() => onChange({ isCreateNewUser: !isCreateNewUser })}
          checked={!isCreateNewUser}
        />
      </CenterContainer>
    </HeightContainer>
  )
}

ModeSwitcher.propTypes = {
  isCreateNewUser: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ModeSwitcher
