import { call, put, select } from 'redux-saga/effects'
import { makeSelectToken } from '../App/selectors'
import { selectLoading, selectSecuredProductsByStoreId } from './selectors'
import {
  createDefaultFloorAndArea,
  upsertManufacturers,
  upsertSecuredProducts,
  upsertStore,
} from '../App/actions'

import {
  fetchSecuredProductsFailed,
  fetchSecuredProductsFulfilled,
  fetchSecuredProductsPending,
} from './actions'

import { getManufacturers, getSecuredProducts, getStoreById } from '../../api'

export function* loadSecuredProductsData(sId) {
  const token = yield select(makeSelectToken())
  const store = yield call(getStoreById, token, sId)
  const products = yield call(getSecuredProducts, token, sId)
  const { manufacturers } = yield call(getManufacturers, token)
  // Inject in orm
  yield put(upsertStore(store))
  yield put(upsertSecuredProducts(products))
  yield put(upsertManufacturers(manufacturers))
  yield put(createDefaultFloorAndArea(sId))
}

export function* loadSecuredProducts({ payload }) {
  const { sId } = payload
  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Secured Products')
    return
  }

  const isFetchSecuredProductsPending = yield select(selectLoading)
  if (isFetchSecuredProductsPending) return

  // TODO: Why is this calling a non-performant selector just to check if they're loaded?
  // Can't it just call makeSelectSecuredProducts?
  const securedProductsLoaded = yield select(
    selectSecuredProductsByStoreId,
    sId
  )
  const isSecuredProductsLoaded =
    securedProductsLoaded && securedProductsLoaded.length > 1

  try {
    if (!isSecuredProductsLoaded) {
      yield put(fetchSecuredProductsPending(sId))
    }

    yield call(loadSecuredProductsData, sId)
    yield put(fetchSecuredProductsFulfilled())
  } catch (e) {
    console.error(e)
    yield put(fetchSecuredProductsFailed(e))
  }
}
