export const alertTypes = {
  normal: 'normal',
  warning: 'warning',
  lowAlert: 'lowAlert', // disarmed, unlocked
  alert: 'alert', // alarming
}

export const defaultName = {
  floor: 'New Floor',
  area: 'New Area',
  fixture: 'New Fixture',
  position: 'New Position',
  defaultFloor: 'New Floor',
  defaultArea: 'New Area',
}

export const positionType = {
  alarming: 'Alarming',
  alarmingHC: 'AlarmingHC',
  alarmingLock: 'AlarmingLock',
  alarmingLockHC: 'AlarmingLockHC',
  armed: 'Armed',
  silentAlarming: 'SilentAlarming',
  silentAlarmingHC: 'SilentAlarmingHC',
  silentAlarmingLock: 'SilentAlarmingLock',
  silentAlarmingLockHC: 'SilentAlarmingLockHC',
  locked: 'Locked',
  disarmed: 'Disarmed',
  disarmedHC: 'DisarmedHC',
  unlocked: 'Unlocked',
  unlockedHC: 'UnlockedHC',
  open: 'Open',
  unlockedLockHC: 'UnlockedLockHC',
  unhealthy: 'Unhealthy',
  unhealthyLock: 'UnhealthyLock',
  warning: 'Warning',
  warningLock: 'WarningLock',
  nonCompliant: 'NonCompliant',
  nonCompliantLock: 'NonCompliantLock',
  unpowered: 'Unpowered',
  silenced: 'Silenced',
  securePlug: 'SecurePlug',
  cxFlexFourPort: 'CXFlexFourPort',
  cxFlexFourPortHC: 'CXFlexFourPortHC',
  missingSecurityDevice: 'MissingSecurityDevice',
  missingLockDevice: 'MissingLockDevice',
  missingSecurePlugDevice: 'MissingSecurePlugDevice',
  missingCXFlexDevice: 'MissingCXFlexDevice',
  unknown: 'Unknown',
}

// TODO: from shared
export const Color = {
  green: '#3AB34A',
  yellow: '#FF9600',
  red: '#EB252A',
  gray: '#DCDCDC',
}

export function getAlertParams(o) {
  if (!o) {
    return {
      alertCount: '',
      alertType: alertTypes.normal,
    }
  }
  const { alarming, red, yellow } = o
  if (alarming > 0) {
    return {
      alertCount: alarming.toString(),
      alertType: alertTypes.alert,
    }
  } else if (red > 0) {
    return {
      alertCount: red.toString(),
      alertType: alertTypes.lowAlert,
    }
  } else if (yellow > 0) {
    return {
      alertCount: yellow.toString(),
      alertType: alertTypes.warning,
    }
  }
  return {
    alertCount: '',
    alertType: alertTypes.normal,
  }
}

export const mtiFixtureType = {
  table: 'table',
  circle: 'circle',
  quarterCircle: 'quarterCircle',
  extendedQuarterCircle: 'extendedQuarterCircle',
  wall: 'wall',
  wallCap: 'wallCap',
  hurricane: 'hurricane',
}

// In case of new fixture type, we will need to update getMtiSviFixtureType
export const sviFigureType = {
  custom: 'custom',
  narrowRectangle: 'narrowRectangle',
  wideRectangle: 'wideRectangle',
  square: 'square',
  circle: 'circle',
  quarterCircle: 'quarterCircle',
  extendedQuarterCircle: 'extendedQuarterCircle',
  extendedLeftQuarterCircle: 'extendedLeftQuarterCircle',
  extendedRightQuarterCircle: 'extendedRightQuarterCircle',
}

export const sviFigureSizeType = {
  custom: 'custom',
  extraSmall: 'extraSmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  veryLarge: 'veryLarge',
}

export const fixtureRatio = {
  narrowRectangle: 3.698, // x / y
  wideRectangle: 3.227, // x / y
  extendedQuarterCircle: 1.528, // y / x
}

/**
 * Get nearest predefined aspect ratio size based on fixture height & width
 *
 * @param fixture
 * @returns {number}
 */
export function getNearestAspectRatio(fixture) {
  const { layoutPosition: { height: oHeight, width: oWidth } = {} } = fixture
  const lSide = Math.max(oHeight, oWidth)
  const sSide = Math.min(oHeight, oWidth)

  const counts = [
    fixtureRatio.wideRectangle,
    fixtureRatio.narrowRectangle,
    3,
    2,
    1.5,
    1,
  ]
  const goal = lSide / sSide

  return counts.reduce(
    (prev, curr) =>
      Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
  )
}

export function getMtiSviFixtureType(sviType) {
  switch (sviType) {
    case sviFigureType.custom:
      return { sviType, mtiType: mtiFixtureType.table }
    case sviFigureType.narrowRectangle:
      return { sviType, mtiType: mtiFixtureType.table }
    case sviFigureType.wideRectangle:
      return { sviType, mtiType: mtiFixtureType.table }
    case sviFigureType.square:
      return { sviType, mtiType: mtiFixtureType.table }
    case sviFigureType.circle:
      return { sviType, mtiType: mtiFixtureType.circle }
    case sviFigureType.quarterCircle:
      return { sviType, mtiType: mtiFixtureType.quarterCircle }
    case sviFigureType.extendedQuarterCircle:
      return { sviType, mtiType: mtiFixtureType.extendedQuarterCircle }
    case sviFigureType.extendedRightQuarterCircle:
      return { sviType, mtiType: mtiFixtureType.extendedQuarterCircle }
    case sviFigureType.extendedLeftQuarterCircle:
      return { sviType, mtiType: mtiFixtureType.extendedQuarterCircle }
    default:
      return { sviType: sviFigureType.custom, mtiType: mtiFixtureType.table }
  }
}

export function isFixture(type) {
  switch (type) {
    case sviFigureType.custom:
    case sviFigureType.narrowRectangle:
    case sviFigureType.wideRectangle:
    case sviFigureType.square:
    case sviFigureType.circle:
    case mtiFixtureType.table:
    case mtiFixtureType.circle:
    case mtiFixtureType.quarterCircle:
    case mtiFixtureType.extendedQuarterCircle:
    case sviFigureType.extendedRightQuarterCircle:
    case sviFigureType.extendedLeftQuarterCircle:
    case mtiFixtureType.wall:
    case mtiFixtureType.wallCap:
    case mtiFixtureType.hurricane:
      return true
    default:
      return false
  }
}

export function isRectFixture(type) {
  switch (type) {
    case sviFigureType.custom:
    case sviFigureType.narrowRectangle:
    case sviFigureType.wideRectangle:
      return true
  }
  return false
}

export function toSviCoordStructure(o) {
  const { width, height, theta, xCenter, yCenter } = o.layoutPosition || {}
  return { ...o, width, height, angle: theta, theta, xCenter, yCenter }
}

export function getImage(images, previewImageId, size) {
  if (!images || !previewImageId || !size) return {}
  const image = images.get(previewImageId)
  if (image) {
    const imageUrl = getImageUrlForSize(image, size)
    return { image: imageUrl, imageName: image.name }
  }
  return {}
}

export function getImageUrlForSize(image, size) {
  if (!image) return
  return ((image.versions || {})[size] || {}).href
}

export function incrementalObjectName(existing, defaultName) {
  const objectsCount = existing.length
  const names = existing.map(({ name }) => name)
  const regexp = new RegExp(`^${defaultName}`)
  const defaultNames = names.filter(name => regexp.test(name))
  const numbers = defaultNames
    .map(name => name.substring(defaultName.length, name.length))
    .map(str => parseInt(str, 10))
    .filter(number => !isNaN(number))

  let i = 1

  if (numbers.length) {
    while (i <= objectsCount) {
      if (numbers.indexOf(i) === -1) {
        break
      }
      i += 1
    }
  }

  return `${defaultName} ${i}`
}

let blinkingTimer
export function onFigureBlinking(canvas, mtiJsclientShared = {}) {
  const { ArmState = {} } = mtiJsclientShared
  function isBlink({ alertType, mtiType }) {
    return alertType === alertTypes.alert || mtiType === ArmState.ALARMING
  }

  let shouldBlink
  canvas.forEachObject(obj => {
    if (isBlink(obj)) {
      shouldBlink = true
    }
  })
  if (!shouldBlink) return

  function applyShadow(canvas, shadow) {
    canvas.forEachObject(obj => {
      if (isBlink(obj)) {
        obj.setShadow(shadow)
      }
    })
  }

  let is = true
  function blinking() {
    if (is) {
      applyShadow(canvas, {
        color: '#ff0000',
        blur: 10,
      })
    } else {
      applyShadow(canvas, null)
    }
    is = !is
    canvas.renderAll()
  }
  if (blinkingTimer) clearInterval(blinkingTimer)
  blinkingTimer = setInterval(blinking, 500)
}

export function onClearBlinking() {
  clearInterval(blinkingTimer)
}

export function highlight(canvas, id) {
  canvas.forEachObject(obj => {
    if (obj.id === id) {
      obj.setShadow({
        color: '#0000ff',
        blur: 10,
      })
    } else {
      obj.setShadow(null)
    }
  })
  canvas.renderAll()
}
