import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm } from 'react-validify'

export default class SignInForm extends React.Component {
  constructor({ values = {}, errors = {} }) {
    super()
    this.state = { values, errors }
  }

  render() {
    const { values, errors } = this.state
    const { children, onErrors, ...props } = this.props
    return (
      <BaseForm
        {...props}
        values={values}
        errors={errors}
        onValues={(v) => this.setState({ values: v })}
        onErrors={(e) => {
          this.setState({ errors: e })
          onErrors(e)
        }}
      >
        {children}
      </BaseForm>
    )
  }
}

SignInForm.propTypes = {
  children: PropTypes.array.isRequired,
  onErrors: PropTypes.func.isRequired,
}
