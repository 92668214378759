/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { login, adminLogin, loginError } from 'containers/App/actions'
import { makeSelectError } from 'containers/App/selectors'
import { Routes } from '../App/constants'
import styles from '../../styles/App.css'
import SignInForm from './SignInForm'
import ErrorMessage from './ErrorMessage'

export class LoginContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { errors: {} }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.email) {
        this.email.focus()
      }
    }, 500)
  }

  onClick = (e) => {
    e.preventDefault()
    const {
      login: doLogin,
      adminLogin: doAdminLogin,
      loginError: doLoginError,
      history: { location: { pathname } },
    } = this.props
    const { errors } = this.state
    const email = this.email.value
    const password = this.password.value
    let validationMsg
    if (!email && !password) {
      validationMsg = 'The Email and Password fields are empty'
    } else if (!email) {
      validationMsg = 'The Email field is empty'
    } else if (!password) {
      validationMsg = 'The Password field is empty'
    }
    if (errors.email && errors.password) {
      validationMsg = `${errors.email} ${errors.password}`
    } else if (errors.email) {
      validationMsg = errors.email
    } else if (errors.password) {
      validationMsg = errors.password
    }
    if (validationMsg) {
      doLoginError(validationMsg)
    } else {
      if (pathname === Routes.LOGIN) {
        doLogin({
          email: this.email.value,
          password: this.password.value,
        })
      } else if (pathname === Routes.ADMIN_LOGIN) {
        doAdminLogin({
          email: this.email.value,
          password: this.password.value,
        })
      }
    }
  }

  onKeyPress = ({ key, target }) => {
    if (key === 'Enter') {
      if (target.name === 'email') {
        if (this.password) {
          this.password.focus()
        }
        return
      }
      this.onClick(event)
    }
  }

  renderInputs() {
    return (
      <SignInForm
        rules={{ email: 'email|required', password: 'required' }}
        onErrors={(errors) => this.setState({ errors })}
      >
        <input
          ref={(ref) => (this.email = ref)}
          className={styles.username}
          name="email"
          placeholder="Enter your email"
          onKeyPress={this.onKeyPress}
        />
        <input
          ref={(ref) => (this.password = ref)}
          className={styles.username}
          name="password"
          placeholder="Enter your password"
          type="password"
          onKeyPress={this.onKeyPress}
        />
      </SignInForm>
    )
  }

  render() {
    const { error, history } = this.props
    return (
      <div className={styles.login}>
        {error ? <ErrorMessage error={error} /> : null}
        {this.renderInputs()}
        <div>
          <button
            style={{ width: '140px' }}
            className={styles.button}
            onClick={this.onClick}
            name="login"
          >
            Login
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history.push('/forgotPassword')}
          >
            Forgot Password
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history.push('/privacyPolicy')}
          >
            Privacy Policy
          </button>
        </div>
      </div>
    )
  }
}

LoginContainer.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  adminLogin: PropTypes.func.isRequired,
  loginError: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
})

const mapDispatchToProps = {
  login,
  adminLogin,
  loginError,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
