import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import EditDisabled from '../../../components/EditDisabled'
import GroupAdornment, {
  EditDisabled as Label,
} from '../../../components/GroupAdornment'
import StoresScreen from './stores.screen'
import TooltipCell from '../../../components/TooltipCell'
import { DeviceConfigurationList } from '../../../components/DeviceConfiguration'

const renderContent = (region, onStore, editManager) => {
  const { createdAt, updatedAt } = region

  const dateToString = (date) =>
    moment(date)
      .utcOffset(0)
      .format('MM/DD/YYYY')

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="form-group col-md-3 bmd-form-group">
          <EditDisabled header={'Created'} text={dateToString(createdAt)} />
        </div>
        <div className="form-group col-md-3 bmd-form-group">
          <EditDisabled
            header={'Last Updated'}
            text={dateToString(updatedAt)}
          />
        </div>
        <div className="form-group col-md-6 bmd-form-group">
          <div className="d-flex flex-row align-items-end">
            <GroupAdornment
              disabled
              AdornmentProps={{
                onClick: editManager,
                text: 'Edit',
                className: 'ml-4',
              }}
            >
              <TooltipCell
                text="TBD..."
                render={(tooltip) => (
                  <span ref={(ref) => tooltip.target(ref)}>
                    <Label header={'Regional Managers'} text="TBD.." />
                  </span>
                )}
              />
            </GroupAdornment>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <DeviceConfigurationList resourceType="Region" resourceId={region.id} />
      </div>
      <div className="row">
        <div className="col">
          <StoresScreen
            id={'region'}
            stores={region.stores}
            onStore={onStore}
          />
        </div>
      </div>
    </div>
  )
}

function RegionDetailsScreen({
  id,
  region,
  title,
  dangerText,
  confirmText,
  onDanger,
  onClose,
  onConfirm,
  onStore,
  isRegionEditLocked,
  editManager,
}) {
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
        <div style={{ flex: 1, marginLeft: 20 }}>
          {!isRegionEditLocked &&
            onDanger && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onDanger()
              }}
            >
              {dangerText}
            </button>
          )}
        </div>
        <div>
          {!isRegionEditLocked &&
            onConfirm && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
              }}
            >
              {confirmText}
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">
        {renderContent(region, onStore, editManager)}
      </div>
    </div>
  )
}

RegionDetailsScreen.propTypes = {
  id: PropTypes.string.isRequired,
  region: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  dangerText: PropTypes.string,
  confirmText: PropTypes.string,
  onDanger: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onStore: PropTypes.func.isRequired,
  isRegionEditLocked: PropTypes.bool,
  editManager: PropTypes.func.isRequired,
}

export default RegionDetailsScreen
