import React from 'react'
import PropTypes from 'prop-types'

const FetchFailedAlert = ({ failed }) => {
  function errorString(failed) {
    if (failed instanceof TypeError) {
      if (failed.message === 'Failed to fetch') {
        return 'Not connected to the internet - try again when re-connected'
      }

      return failed.message
    }

    if (failed instanceof Error) {
      return failed.message
    }

    if (typeof failed === 'string') {
      return failed
    }

    if (!failed) {
      return null
    }

    if (failed.error && failed.error.statusCode === 404) {
      return 'Object you are trying to update or delete was not found. Please refresh the page and try again (error code: 404).'
    }

    if (failed.error && failed.error.statusCode === 500) {
      return 'Internal Server Error, please click Refresh button to refresh the page (error code: 500).'
    }

    return JSON.stringify(failed)
  }

  return (
    <div className="alert alert-danger" role="alert">
      {errorString(failed)}
    </div>
  )
}

FetchFailedAlert.propTypes = {
  failed: PropTypes.any,
}

export default FetchFailedAlert
