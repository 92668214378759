import React from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getTableHeight, storeTopic } from '../../../utils/utils'
import {
  notifyModal,
  storeDetailsAction,
  subscribeOnTopic,
} from '../../App/actions'
import { setStore } from './actions'
import {
  makeSelectHasMtiRole,
  makeSelectMtiPermission,
} from '../../App/selectors'
import StoreManagerScreen from './index.screen'
import injectReducer from '../../../utils/injectReducer'
import injectSaga from '../../../utils/injectSaga'
import {
  makeSelectStore,
  makeSelectLoading,
  makeSelectStores,
} from './selectors'
import storesReducer from './reducer'
import storesSaga from './sagas'
import StoresSelector from './StoresSelection'
import { createSmartStoreLayoutLink } from '../../../utils/mtiUtils'
import { selectStoreHasMissingSecuredProducts } from '../../ExceptionsList/SecuredProducts/selectors'
import warningAlert from '../../../images/warning-alert.png'
// import { isMobile } from '../../../utils/screen'

const noPermissionTextKeys =
  "You don't have appropriate rights to access the Keys.\nPlease contact your store manager."
const noPermissionTextUsers =
  "You don't have appropriate rights to access the Users List.\nPlease contact your store manager."
const noPermissionTextSchedules =
  "You don't have appropriate rights to access the Schedules List.\nPlease contact your store manager."
const noPermissionTextReports =
  "You don't have appropriate rights to access the Store Reports.\nPlease contact your store manager."
const inDevelopment = 'This feature is still in development'

export class StoreManagerOrStaff extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { store, subscribeOnTopic: doSubscribeOnTopic } = this.props
    if (store) {
      const topic = storeTopic(store.id)
      doSubscribeOnTopic(topic)
    }
  }

  /* eslint-disable no-undef */
  onSelect = (store) => {
    const {
      setStore: doSetStore,
      store: currentStore,
      subscribeOnTopic: doSubscribeOnTopic,
    } = this.props
    if (currentStore || {} !== store) {
      doSubscribeOnTopic(storeTopic(store.id))
      doSetStore(store)
    }
  }

  static onItem = (event, item, props) => {
    if (!item || !item.name) return
    const {
      store,
      hasViewKeysPermission,
      hasViewStoreReportsPermission,
      hasViewUsersPermission,
      hasViewSchedulesPermission,
      notifyModal: doNotifyModal,
    } = props

    switch (item.name) {
      case 'Keys': {
        if (!hasViewKeysPermission) {
          doNotifyModal(noPermissionTextKeys)
        }

        break
      }
      case 'Historical Logs': {
        if (!hasViewStoreReportsPermission) {
          doNotifyModal(noPermissionTextReports)
        }

        break
      }
      case 'Current Exceptions': {
        if (!hasViewStoreReportsPermission) {
          doNotifyModal(noPermissionTextReports)
        }

        break
      }
      case 'Users': {
        if (!hasViewUsersPermission) {
          doNotifyModal(noPermissionTextUsers)
        }
        break
      }
      case 'Schedules': {
        if (!hasViewSchedulesPermission) {
          doNotifyModal(noPermissionTextSchedules)
        }
        break
      }
      case 'Store Details': {
        event.preventDefault()
        StoreManagerOrStaff.onStoreSelection(store, props)
        break
      }
      case 'Store Layout':
      case 'Secured Products':
      case 'Scan QR Code':
      case 'Table View':
        break
      default:
        doNotifyModal(inDevelopment)
        break
    }
  }

  static onStoreSelection = (store, props) => {
    const { storeDetailsAction: doStoreDetailsAction } = props
    doStoreDetailsAction(store)
  }

  static getItemsData = (store, props) => {
    const {
      hasStoreManagerRole,
      hasViewKeysPermission,
      hasViewUsersPermission,
      hasViewStoreReportsPermission,
      hasMissingSecuredProducts,
    } = props

    // const mobile = isMobile()

    if (hasStoreManagerRole) {
      let items = [
        {
          name: 'Store Details',
          id: 0,
          navigateTo: '',
        },
        {
          name: 'Store Layout',
          id: 1,
          navigateTo: store ? createSmartStoreLayoutLink(store).path : '',
        },
        {
          name: 'Current Exceptions',
          id: 2,
          navigateTo: '/current_exceptions',
        },
        {
          name: 'Historical Logs',
          id: 3,
          navigateTo: '/historical_logs',
        },
        {
          name: 'Users',
          id: 4,
          navigateTo: `/stores/${(store || {}).id}/${(store || {}).name}/users`,
        },
        {
          name: 'Keys',
          id: 5,
          navigateTo: `/stores/${(store || {}).id}/${(store || {}).name}/keys`,
        },
        {
          name: 'Secured Products',
          id: 6,
          navigateTo: `/stores/${(store || {}).id}/${
            (store || {}).name
          }/secured_products`,
          icon: hasMissingSecuredProducts ? warningAlert : null,
          historyState: hasMissingSecuredProducts
            ? { category: 'missing' }
            : null,
        },
        {
          name: 'Schedules',
          id: 7,
          navigateTo: `/stores/${(store || {}).id}/${
            (store || {}).name
          }/schedules`,
        },
        {
          name: 'Table View',
          id: 8,
          navigateTo: `/stores/${(store || {}).id}/${
            (store || {}).name
          }/layout_table_view`,
        },
      ]

      // if (mobile) {
      //   items.push({
      //     name: 'Scan QR Code',
      //     id: 8,
      //     navigateTo: '/qr_reader',
      //   })
      // }

      return items
    }
    return [
      {
        name: 'Store Details',
        id: 0,
        navigateTo: '',
      },
      {
        name: 'Store Layout',
        id: 1,
        navigateTo: store ? createSmartStoreLayoutLink(store).path : '',
      },
      {
        name: 'Current Exceptions',
        id: 2,
        navigateTo: hasViewStoreReportsPermission ? '/current_exceptions' : '',
      },
      {
        name: 'Historical Logs',
        id: 3,
        navigateTo: hasViewStoreReportsPermission ? '/historical_logs' : '',
      },
      {
        name: 'Users',
        id: 4,
        navigateTo: hasViewUsersPermission
          ? `/stores/${(store || {}).id}/${(store || {}).name}/users`
          : '',
      },
      {
        name: 'Keys',
        id: 5,
        navigateTo: hasViewKeysPermission
          ? `/stores/${(store || {}).id}/${(store || {}).name}/keys`
          : '',
      },
    ]
  }

  render() {
    const {
      hasStoreManagerRole,
      hasStoreAssociateRole,
      hasRegionalManagerRole,
      hasViewKeysPermission,
      hasViewUsersPermission,
      store,
      loading,
      stores,
    } = this.props
    const storeId = (store || {}).id
    const items = StoreManagerOrStaff.getItemsData(store, this.props)
    const storesCount = (stores || []).length
    const selectorHeight = storesCount > 1 ? 183 : 0

    // TODO: consider moving preloader from StoresSelection to current place
    return (
      <div style={{ height: '100%' }}>
        <StoresSelector onSelect={this.onSelect} storeId={storeId} />
        {!loading && store ? (
          <div>
            <StoreManagerScreen
              quickActionsItems={items}
              storeId={storeId}
              onItem={(event, item) =>
                StoreManagerOrStaff.onItem(event, item, this.props)
              }
              height={getTableHeight() - selectorHeight}
              history={this.props.history}
              hasStoreManagerRole={hasStoreManagerRole}
              hasStoreAssociateRole={hasStoreAssociateRole}
              hasRegionalManagerRole={hasRegionalManagerRole}
              hasViewKeysPermission={hasViewKeysPermission}
              hasViewUsersPermission={hasViewUsersPermission}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

StoreManagerOrStaff.propTypes = {
  history: PropTypes.any,
  notifyModal: PropTypes.func,
  storeDetailsAction: PropTypes.func,
  hasStoreManagerRole: PropTypes.bool,
  hasStoreAssociateRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasViewKeysPermission: PropTypes.bool,
  hasViewStoreReportsPermission: PropTypes.bool,
  hasViewUsersPermission: PropTypes.bool,
  hasViewSchedulesPermission: PropTypes.bool,
  setStore: PropTypes.func,
  store: PropTypes.object,
  loading: PropTypes.bool,
  stores: PropTypes.array,
  subscribeOnTopic: PropTypes.func,
}

function getStoreId(state) {
  return (state.get('editStores').get('store') || {}).id
}

const mapStateToProps = createStructuredSelector({
  hasStoreManagerRole: (state, ownProps) =>
    makeSelectHasMtiRole('Store Manager', getStoreId(state))(state, ownProps),
  hasStoreAssociateRole: (state, ownProps) =>
    makeSelectHasMtiRole('Store Associate', getStoreId(state))(state, ownProps),
  hasRegionalManagerRole: (state, ownProps) =>
    makeSelectHasMtiRole('Regional Manager', getStoreId(state))(
      state,
      ownProps
    ),
  hasViewKeysPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Index (list) Records',
      getStoreId(state)
    )(state, ownProps), // deprecated name 'viewKeys'
  hasViewStoreReportsPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Saved Report: Index (list) Records',
      getStoreId(state)
    )(state, ownProps), // deprecated name 'viewStoreReports'
  hasViewUsersPermission: (state, ownProps) =>
    makeSelectMtiPermission('User: Index (list) Records', getStoreId(state))(
      state,
      ownProps
    ), // deprecated name 'viewUsers'
  hasViewSchedulesPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Schedule: Index (list) Records',
      getStoreId(state)
    )(state, ownProps), // deprecated name 'viewUsers'
  store: makeSelectStore(),
  loading: makeSelectLoading(),
  stores: makeSelectStores(),
  hasMissingSecuredProducts: (state) =>
    selectStoreHasMissingSecuredProducts(state, getStoreId(state)),
})

const mapDispatchToProps = {
  notifyModal,
  storeDetailsAction,
  setStore,
  subscribeOnTopic,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withStoresReducer = injectReducer({
  key: 'editStores',
  reducer: storesReducer,
})
const withStoresSaga = injectSaga({ key: 'stores', saga: storesSaga })

export default compose(withStoresReducer, withStoresSaga, withConnect)(
  StoreManagerOrStaff
)
