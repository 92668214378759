import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectHasMtiRoleAnyResource } from '../../containers/App/selectors'
import CorporateUserDrawer from './CorporateUser'
import StoreManagerDrawer from './StoreManagerOrStaff'

class Drawer extends React.PureComponent {
  drawer = () => {
    const {
      hasOrganizationAdminRole,
      hasRegionalManagerRole,
      hasMtiAdminRole,
    } = this.props
    if (hasOrganizationAdminRole || hasRegionalManagerRole || hasMtiAdminRole) {
      return <CorporateUserDrawer />
    }
    return <StoreManagerDrawer />
  }

  render() {
    return (
      <div id="appDrawer" className="bmd-layout-drawer bg-faded">
        {this.drawer()}
      </div>
    )
  }
}

Drawer.propTypes = {
  hasOrganizationAdminRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasMtiAdminRole: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  hasOrganizationAdminRole: makeSelectHasMtiRoleAnyResource(
    'Organization Administrator'
  ),
  hasRegionalManagerRole: makeSelectHasMtiRoleAnyResource('Regional Manager'),
  hasMtiAdminRole: makeSelectHasMtiRoleAnyResource('MTI Admin'),
})

export default connect(mapStateToProps)(Drawer)
