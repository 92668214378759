import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IdleTimerModal from './modal'
import { logout, openModal, closeModal } from '../App/actions'
import { idleTimerSeconds, logoutTimerSeconds } from './constants'
import { isDebugMode } from '../../utils/utils'

class IdleTimer extends React.Component {
  componentDidUpdate = prevProps => {
    const { user } = this.props
    if (user.id != prevProps.user.id) {
      if (user.id) {
        // check if the user is logged in
        this.initIdleTimer()
      } else {
        this.endIdleTimer()
      }
    }
  }

  initIdleTimer = () => {
    if (this.props.user.id && !isDebugMode()) {
      this.addListeners()
      this.startIdleTimer()
    }
  }

  endIdleTimer = () => {
    this.clearIdleTimer()
    this.removeListeners()
  }

  addListeners = () => {
    document.addEventListener('keypress', this.resetTimer)
    document.addEventListener('mousemove', this.resetTimer)
  }

  removeListeners = () => {
    document.removeEventListener('keypress', this.resetTimer)
    document.removeEventListener('mousemove', this.resetTimer)
  }

  startIdleTimer = () => {
    const timeout = idleTimerSeconds * 1000

    this.idleTimer = setTimeout(() => {
      // It seems there may be a race condition when the user logs out and the timer starts, so this
      // is a second check to make sure they're still logged in when the modal opens.
      if (!this.props.user.id) { return }
      this.displayModal()
      this.endIdleTimer()
    }, timeout)
  }

  clearIdleTimer = () => {
    clearTimeout(this.idleTimer)
  }

  resetTimer = () => {
    this.clearIdleTimer()
    this.startIdleTimer()
  }

  displayModal = () => {
    const { openModal, closeModal, logout } = this.props
    const id = 'idle-timer'

    openModal({
      id: id,
      type: 'bootstrap',
      appearance: 'modal-md',
      content: (
        <IdleTimerModal
          id={id}
          logoutTime={logoutTimerSeconds}
          onClose={() => {
            closeModal({ id })
            this.initIdleTimer()
          }}
          logout={() => {
            logout()
            closeModal({ id })
          }}
          hideCloseInHeader
        />
      ),
    })
  }

  //renderless component
  render() {
    return null
  }
}

IdleTimer.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  logout: PropTypes.func,
}

const mapDispatchToProps = {
  logout,
  openModal,
  closeModal,
}

export default connect(null, mapDispatchToProps)(IdleTimer)
