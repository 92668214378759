/**
 * Regions Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { getRegions, orgIdUrl } from '../../api'
import {
  makeSelectToken,
  makeSelectIsMtiAdmin,
} from '../../containers/App/selectors'
import { makeSelectRegions, makeSelectLoading } from './selectors'
import { errorToast } from '../../utils/utils'
import {
  fetchRegionsFailed,
  fetchRegionsFulfilled,
  fetchRegionsPending,
} from './actions'

export function* loadRegionsData() {
  const token = yield select(makeSelectToken())
  const data = yield call(getRegions, token, undefined)
  return data
}

export function* loadRegions() {
  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Regions')
    return
  } else {
    // console.log('get Regions')
  }
  const isMtiAdmin = yield select(makeSelectIsMtiAdmin())
  if (isMtiAdmin && !orgIdUrl()) return
  const isFetchRegionsPending = yield select(makeSelectLoading())
  if (isFetchRegionsPending) return
  const regionsLoaded = yield select(makeSelectRegions())
  const isRegionsLoaded = regionsLoaded && regionsLoaded.length > 0
  try {
    if (!isRegionsLoaded) {
      yield put(fetchRegionsPending())
    }

    const data = yield call(loadRegionsData)
    yield put(fetchRegionsFulfilled(data))
  } catch (error) {
    console.error(error)
    yield put(fetchRegionsFailed(error))
    errorToast('Load regions failed')
  }
}
