import { ActionTypes } from '../constants'

/**
 * Fetch Keys Failed
 *
 * @returns {Object}
 */
export function fetchKeysFailed(error) {
  return {
    type: ActionTypes.FETCH_KEYS_FAILED,
    payload: error,
  }
}

/**
 * Fetch Keys Fulfilled
 *
 * @returns {Object}
 */
export function fetchKeysFulfilled(storeId, keys) {
  return {
    type: ActionTypes.FETCH_KEYS_FULFILLED,
    payload: {
      storeId,
      keys,
    },
  }
}

/**
 * Fetch Keys Pending
 *
 * @returns {Object}
 */
export function fetchKeysPending(storeId) {
  return {
    type: ActionTypes.FETCH_KEYS_PENDING,
    payload: {
      storeId,
    },
  }
}

/**
 * Load Keys
 *
 * @returns {Object}
 */
export function loadKeys(sId) {
  return {
    type: ActionTypes.LOAD_KEYS,
    payload: {
      storeId: sId,
    },
  }
}

/**
 * Update Keys Data
 *
 * @returns {Object}
 */
export function updateKeysData(storeId, keys) {
  return {
    type: ActionTypes.UPDATE_KEYS_DATA,
    payload: {
      storeId,
      keys,
    },
  }
}

/**
 * Post Key Failed
 *
 * @returns {Object}
 */
export function postKeyFailed(error) {
  return {
    type: ActionTypes.POST_KEY_FAILED,
    payload: error,
  }
}

/**
 * Post Key Finished
 *
 * @returns {Object}
 */
export function postKeyFinished(storeId, keys) {
  return {
    type: ActionTypes.POST_KEY_FINISHED,
    payload: {
      storeId,
      keys,
    },
  }
}

/**
 * Post Key Pending
 *
 * @returns {Object}
 */
export function postKeyPending(storeId) {
  return {
    type: ActionTypes.POST_KEY_PENDING,
    payload: {
      storeId,
    },
  }
}

/**
 * Add Key
 *
 *
 */
export function addKey(payload) {
  return {
    type: ActionTypes.ADD_KEY,
    payload,
  }
}

/**
 * Add Keys
 *
 *
 */
export function addKeys(payload) {
  return {
    type: ActionTypes.ADD_KEYS,
    payload,
  }
}

/**
 * Update Key Failed
 *
 * @returns {Object}
 */
export function updateKeyFailed(error) {
  return {
    type: ActionTypes.UPDATE_KEY_FAILED,
    payload: error,
  }
}

/**
 * Delete Key
 *
 * @returns {Object}
 */
export function deleteKey(payload) {
  return {
    type: ActionTypes.DELETE_KEY,
    payload,
  }
}

/**
 * Delete Keys
 *
 * @returns {Object}
 */
export function deleteKeys(payload) {
  return {
    type: ActionTypes.DELETE_KEYS,
    payload,
  }
}

/**
 * Delete Key Pending
 *
 * @returns {Object}
 */
export function deleteKeyPending(payload) {
  return {
    type: ActionTypes.DELETE_KEY_PENDING,
    payload,
  }
}

/**
 * Delete Key Finished
 *
 * @returns {Object}
 */
export function deleteKeyFinished(payload) {
  return {
    type: ActionTypes.DELETE_KEY_FINISHED,
    payload,
  }
}

/**
 * Delete Key Finished
 *
 * @returns {Object}
 */
export function deleteKeyFailed(payload) {
  return {
    type: ActionTypes.DELETE_KEY_FAILED,
    payload,
  }
}

/**
 * Update Key Finished
 *
 * @returns {Object}
 */
export function updateKeyFinished(storeId, keys, keyId) {
  return {
    type: ActionTypes.UPDATE_KEY_FINISHED,
    payload: {
      storeId,
      keys,
      keyId,
    },
  }
}

/**
 * Update Key Pending
 *
 * @returns {Object}
 */
export function updateKeyPending(storeId, keyId) {
  return {
    type: ActionTypes.UPDATE_KEY_PENDING,
    payload: {
      storeId,
      keyId,
    },
  }
}

/**
 * Activate Keys
 *
 *
 */
export function activateKeys(payload) {
  return {
    type: ActionTypes.ACTIVATE_KEYS,
    payload,
  }
}

/**
 * Update Key
 *
 *
 */
export function updateKey(payload) {
  return {
    type: ActionTypes.UPDATE_KEY,
    payload,
  }
}

/**
 * Add New Key
 *
 *
 */
export function addNewKey(payload) {
  return {
    type: ActionTypes.ADD_NEW_KEY,
    payload,
  }
}

/**
 * Update New Key
 *
 *
 */
export function updateNewKey(payload) {
  return {
    type: ActionTypes.UPDATE_NEW_KEY,
    payload,
  }
}

/**
 * Delete New Key
 *
 *
 */
export function deleteNewKey(payload) {
  return {
    type: ActionTypes.DELETE_NEW_KEY,
    payload,
  }
}

/**
 * Delete New Keys
 *
 *
 */
export function deleteNewKeys(payload) {
  return {
    type: ActionTypes.DELETE_NEW_KEYS,
    payload,
  }
}

/**
 * Add Default Key
 *
 *
 */
export function addDefaultKey() {
  return {
    type: ActionTypes.ADD_DEFAULT_KEY,
  }
}
