import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { IssueContainer, TextTop } from '../Issue'

import NOT_COMPLIANT_ICON from '../../images/svg/not_compliant.svg'
import {
  ActionButton,
  ButtonsContainer,
  CellContainer,
  Dropdown,
  DropdownMenu,
} from '../Issue/DropdownButton'

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  width: 160px;
`

export const Icon = styled.img`
  text-align: right;
  height: 20px;
  width: 20px;
`

export const IssuesContainer = styled.div`
  margin: 32px 0px;
  border-top: 1px solid #d9d9d9;
`

export const IssuesTitle = styled.span`
  margin-left: 24px;
`

export const FixtureIssueStatus = styled.span`
  width: 100px;
  font-size: 12px;
`

function renderNonCompliantFixturesList(fixtures, openFixture) {
  if (!fixtures || fixtures.length < 1) {
    return (
      <IssueContainer>
        <TextTop>No Issues</TextTop>
      </IssueContainer>
    )
  }

  return fixtures.map((fixture) => (
    <Dropdown key={`${fixture.id}`} className="btn-group dropup">
      <CellContainer
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <IssueContainer>
          <Row>
            <TextTop>{fixture.name}</TextTop>
          </Row>
          <FixtureIssueStatus>Not Compliant</FixtureIssueStatus>
          <Icon src={NOT_COMPLIANT_ICON} />
        </IssueContainer>
      </CellContainer>
      <DropdownMenu
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        <ButtonsContainer>
          <ActionButton
            style={{
              backgroundColor: '#4E92DF',
            }}
            onClick={() => openFixture(fixture)}
          >
            <span style={{ color: 'white' }}>Go&nbsp;To</span>
          </ActionButton>
        </ButtonsContainer>
      </DropdownMenu>
    </Dropdown>
  ))
}

export default function NotCompliantFixturesList({
  notCompliantFixtures,
  openFixture,
}) {
  return (
    <IssuesContainer>
      <IssuesTitle>Fixtures with Compliance Issues:</IssuesTitle>
      {renderNonCompliantFixturesList(notCompliantFixtures, openFixture)}
    </IssuesContainer>
  )
}

NotCompliantFixturesList.propTypes = {
  notCompliantFixtures: PropTypes.array,
  openFixture: PropTypes.func.isRequired,
}
