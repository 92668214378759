/**
 * Organizations Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { getStores } from 'api'
import { makeSelectToken } from '../../../App/selectors'
import {
  fetchOrganizationsPending,
  fetchOrganizationsFulfilled,
  fetchOrganizationsFailed,
} from './actions'
import { makeSelectOrganizations, makeSelectLoading } from './selectors'
import { errorToast } from '../../../../utils/utils'

export function* loadOrganizationsData() {
  const token = yield select(makeSelectToken())
  const { organizations } = yield call(getStores, token, 'organization')
  return organizations
}

export function* loadOrganizations({ payload = {} } = {}) {
  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Organizations')
    return
  } else {
    // console.log('get Organizations')
  }

  const isFetchOrganizationsPending = yield select(makeSelectLoading())
  if (isFetchOrganizationsPending) return
  const organizationsLoaded = yield select(makeSelectOrganizations())
  const isOrganizationsLoaded =
    organizationsLoaded && organizationsLoaded.length > 0
  try {
    if (!isOrganizationsLoaded) {
      yield put(fetchOrganizationsPending())
    }

    const data = yield call(loadOrganizationsData, { payload })
    yield put(fetchOrganizationsFulfilled(data))
  } catch (error) {
    console.error(error)
    yield put(fetchOrganizationsFailed(error))
    errorToast('Load Organizations failed')
  }
}
