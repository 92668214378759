import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {
  CheckboxAndInputContainer,
  CheckboxContainer,
} from '../../containers/TemplateFixture/index.screen'
import Checkbox from '../Checkbox'
import EditDisabled from '../EditDisabled'
import TimePickerInput from '../TimePickerInput'
import { Container } from '../GroupAdornment'
import PropTypes from 'prop-types'
import { dottedBackground } from '../../styles/styled'

const CustomContainer = styled(Container)`
  ${(props) =>
    props.disabled &&
    `
  border-bottom-color: transparent;
  ${dottedBackground}`} input {
    background: none !important;
    text-align: center;
    margin-bottom: 3px;
  }
  label {
    border: none;
  }
  .editDisabled {
    label {
      ${(props) => props.disabled && 'margin-bottom: 6px !important'};
    }
  }
  .rdtPicker {
    width: 150px;
    box-shadow: 3px 3px 15px -2px;
  }
`

class KeyExpirationInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isIntervalPickerVisible: props.checked && props.interval,
      date: this.props.interval
        ? moment(this.props.interval * 1000).utc()
        : null,
    }
  }

  onChange = ({ target: { checked } }) => {
    this.setState({ isIntervalPickerVisible: checked })
    this.props.onChange(checked)
    if (!this.props.interval && !this.state.date) {
      this.onTimeChange(moment(this.timePickerValue()))
    }
  }

  onTimeChange = (date) => {
    this.setState({ date })
    const seconds = date.utc().unix()
    this.props.onChangeInterval(seconds)
  }

  timePickerValue = () => {
    const { date } = this.props
    if (date) {
      return date
    }

    const defaultDate = new Date()
    defaultDate.setHours(6)
    defaultDate.setMinutes(30)
    return defaultDate
  }

  render() {
    const { isIntervalPickerVisible, date } = this.state
    const { disabled, checked, name } = this.props
    return (
      <CustomContainer disabled={disabled}>
        <CheckboxAndInputContainer>
          <CheckboxContainer>
            <Checkbox
              name={name}
              onChange={this.onChange}
              checked={checked}
              disabled={disabled}
            />
          </CheckboxContainer>
          {!isIntervalPickerVisible && (
            <span className="editDisabled">
              <EditDisabled text="Check to set interval" grey />
            </span>
          )}
          {isIntervalPickerVisible && (
            <TimePickerInput
              defaultValue={date}
              onChange={this.onTimeChange}
              disabled={disabled}
            />
          )}
        </CheckboxAndInputContainer>
      </CustomContainer>
    )
  }
}

KeyExpirationInput.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  interval: PropTypes.number,
  checked: PropTypes.bool,
  onChangeInterval: PropTypes.func,
  onChange: PropTypes.func,
}

export default KeyExpirationInput
