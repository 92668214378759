import { Record, List, Set } from 'immutable'
import { fixApiRolesCache, splitRolesByName } from '../../utils/mtiUtils'
import { removeDuplicateStrings } from '../../utils/utils'

export const User = new Record({
  id: null,
  name: null,
  email: null,
  lastSignedInAt: null,
  avatarUrl: null,
  firstName: null,
  lastName: null,
  status: null,
  employeeIdentifier: null,
  phoneNumber: null,
  rolesTitle: null,
  personaId: null,
  resourceNames: null,
  assignedTo: null,
  roleKey: null,
  roleName: null,
  resourceType: null,
  resourceIds: List(),
  roleResources: List(),
})

const RoleResource = Record({
  id: null,
  type: null,
  name: null,
})

export function createUsers(usersData) {
  const { images, users, personae, organizations, regions, stores } = usersData
  if (!users) return []
  return users.map(data => {
    // Deprecated
    const { personaIds, rolesCache } = data

    // Deprecated
    const userPersonae = (personae || []).filter(persona =>
      (personaIds || []).find(pId => pId === persona.id)
    )
    // Deprecated
    const personaeNames = (userPersonae || []).map(persona => persona.name)

    const image = images && images.find(obj => obj.id === data.avatarId)

    // Deprecated
    const rolesFixed = fixApiRolesCache(rolesCache)
    // Deprecated
    const roles = splitRolesByName(rolesFixed) || {}
    // Organizations
    const organizationAdmin = (organizations || []).filter(item =>
      (roles.organizationAdmin || []).find(
        ({ resourceId }) => resourceId === item.id
      )
    )
    const organizationAdminNames = (organizationAdmin || []).map(
      item => item.name
    )
    // Regions Manager
    const regionalManager = (regions || []).filter(item =>
      (roles.regionalManager || []).find(
        ({ resourceId }) => resourceId === item.id
      )
    )
    const regionalManagerNames = (regionalManager || []).map(
      item => item.name
    )
    // Stores Manager
    const storeManager = (stores || []).filter(item =>
      (roles.storeManager || []).find(
        ({ resourceId }) => resourceId === item.id
      )
    )
    const storeManagerNames = (storeManager || []).map(item => item.name)
    // Stores Associate
    const storeAssociate = (stores || []).filter(item =>
      (roles.storeAssociate || []).find(
        ({ resourceId }) => resourceId === item.id
      )
    )
    const storeAssociateNames = (storeAssociate || []).map(item => item.name)
    // Assigned Resource Names
    const resourceAssignmentNames = removeDuplicateStrings(
      organizationAdminNames.concat(
        regionalManagerNames,
        storeManagerNames,
        storeAssociateNames
      )
    )
    //
    const userAssignments = (rolesFixed || []).map(r => ({
      ...r,
      personaId: ((userPersonae || []).find(p => p.name === r.name) || {}).id,
      userId: data.id,
    }))
    // Assigned To, used for displaying user roles in user details modal
    const userAssignedTo = {
      assignments: userAssignments,
      personae: userPersonae,
      organizations: organizationAdmin,
      regions: regionalManager,
      stores: storeManager.concat(storeAssociate),
      users,
    }

    const roleResources =
      typeof usersData[data.roleResourcesCollection] === 'undefined'
        ? List()
        : Set(
          usersData[data.roleResourcesCollection]
            .filter(
              res =>
                res.type === data.resourceType &&
                  data.roleResourceIds.includes(res.id)
            )
            .map(res => RoleResource(res))
        ).toList()

    const attrs = {
      id: data.id,
      name: `${data.firstName || ''} ${data.lastName || ''}`,
      email: data.email,
      lastSignedInAt: data.lastSignedInAt,
      avatarUrl: image && image.versions['@3xLink'].href,
      firstName: data.firstName,
      lastName: data.lastName,
      status: data.status,
      employeeIdentifier: data.employeeIdentifier,
      phoneNumber: data.phoneNumber,
      rolesTitle: personaeNames || [],
      personaId: (data.personaIds || [{}])[0],
      resourceNames: resourceAssignmentNames || [],
      assignedTo: userAssignedTo,
      roleKey: data.roleKey,
      roleName: data.roleName,
      resourceType: data.resourceType,
      resourceIds: data.roleResourceIds,
      roleResources,
    }
    return new User(attrs)
  })
}
