import React from 'react'
import PropTypes from 'prop-types'
import SmartInput from 'react-phone-number-input/smart-input'
import PhoneInput from 'react-phone-number-input/min'
import 'react-phone-number-input/style.css'
import { defaultRequiredMessage } from '../SubmitWithHint'

class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props)
    const { value, defaultValue } = props
    this.state = {
      isValid: this.isValid10Digits(value || defaultValue),
      value: value || defaultValue || '',
    }
  }

  isValid10Digits = (str) => {
    const digitsNumber = (str || '').replace(/\D/g, '').length
    if (!digitsNumber) {
      return true
    }

    return digitsNumber > 9
  }

  onChange = (value) => {
    if (this.isValid10Digits(value)) {
      this.setState({ isValid: true, value })
      this.props.onChange(value || '')
    } else {
      this.setState({ isValid: false, value })
    }
  }

  setCustomValidity(msg) {
    const element = this.phoneInputContainer
    if (msg === '') {
      this.setServerErrorDismiss(element)
    } else {
      this.setServerError(element, msg)
    }
  }

  setServerErrorDismiss(element) {
    const $element = $(element) // eslint-disable-line
    $element.keyup(() => {
      $element.attr('data-content', '') // eslint-disable-line
      $element.popover('hide') // eslint-disable-line
    })
  }

  setServerError(element, msg) {
    const $element = $(element) // eslint-disable-line
    $element.attr('data-placement', 'bottom')
    $element.attr('data-content', msg)
    $element.popover('show')
    setTimeout(() => {
      $element.attr('data-content', '')
      $element.popover('disable')
    }, 3000)
  }

  isCustom() {
    return true
  }

  isValid() {
    return this.state.isValid
  }

  validationMessage = () => {
    const { isValid } = this.state

    if (!isValid) {
      this.props.onInvalid()
      return this.props.invalidMsg || 'Invalid phone number'
    }

    return undefined
  }

  showInvalidMessage = () => this.setCustomValidity(this.validationMessage())

  requiredDefaultValidation = () => {
    const { required } = this.props
    if (required) {
      return {
        required: true,
        onInvalid: defaultRequiredMessage,
        onInput: defaultRequiredMessage,
      }
    }
    return undefined
  }

  render() {
    return (
      <div
        className="w-100"
        ref={(ref) => {
          this.phoneInputContainer = ref
          this.setServerErrorDismiss(ref)
        }}
      >
        <PhoneInput
          inputComponent={SmartInput}
          country="US"
          value={this.state.value}
          onChange={this.onChange}
          error={this.validationMessage()}
          id={this.props.id}
        />
      </div>
    )
  }
}

PhoneNumberInput.defaultProps = {
  onInvalid: () => {},
  onRequired: () => {},
  onChange: () => {},
  required: false,
}

PhoneNumberInput.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  // placeholder: PropTypes.string,
  onInvalid: PropTypes.func,
  onRequired: PropTypes.func,
  onChange: PropTypes.func,
  invalidMsg: PropTypes.string,
  requiredMsg: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default PhoneNumberInput
