import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'

const dbStateSelector = (state) => state.get('orm')
const selectApp = (state) => state.get('app')
const selectFailed = createSelector(selectApp, (app) =>
  app.get('securedProductsFailed')
)
const selectLoading = createSelector(selectApp, (app) =>
  app.get('securedProductsLoading')
)

const selectArg1 = (state, arg1) => arg1
const selectSecuredProductsByStoreId = createOrmSelector(
  orm,
  dbStateSelector,
  selectArg1,
  (session, storeId) => {
    if (!session.Store.hasId(storeId)) return []
    const { name: storeName } = session.Store.withId(storeId).ref
    let sps = session.SecuredProduct.all()
    sps = sps
      .filter((d) => d.storeId.toString() === storeId)
      .toModelArray()
      .map((securedProductModel) => {
        const positionModel = securedProductModel.positionId
        const position = positionModel && positionModel.ref
        const fixture = positionModel && positionModel.fixtureId.ref
        const fixtureData = fixture && {
          fixtureId: fixture.id,
          fixtureName: fixture.name,
        }
        let extraSecuredProductData = {}
        return {
          ...securedProductModel.ref,
          manufacturer: (securedProductModel.manufacturerId || {}).ref,
          position: {
            ...position,
            ...fixtureData,
            storeId: storeId,
            storeName,
          },
          productName: securedProductModel.productName(),
          manufacturerName: securedProductModel.manufacturerName(),
        }
      })
    return sps
  }
)

export { selectFailed, selectLoading, selectSecuredProductsByStoreId }
