import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  postUser,
  deleteUserById,
  patchUser,
  getUsers,
  assignPersonaeRole,
  unassignPersonaeRole,
  requestPassword,
} from '../../../api'
import { tokenSelector, userEditSelector } from '../selectors'
import { ActionTypes } from '../constants'
import {
  postUserPending,
  postUserFulfilled,
  postUserFailed,
  deleteUserPending,
  deleteUserFinished,
  deleteUserFailed,
  patchUserPending,
  patchUserFulfilled,
  patchUserFailed,
  findUserPending,
  findUserFailed,
  userDetailsAction,
  closeModal,
  resetPasswordPending,
  resetPasswordFulfilled,
  resetPasswordFailed,
} from '../actions'
import { loadUsers, changeUserStatus } from '../../UsersPage/sagas'
import { makeSelectUsers } from '../../UsersPage/selectors'
import {
  errorToast,
  getBase64File,
  successToast,
  warnToast,
  removeModalManually,
} from '../../../utils/utils'

export function* post() {
  const user = yield select(userEditSelector)
  if (user) {
    const token = yield select(tokenSelector)
    try {
      yield put(postUserPending())
      const modifiedUser = yield call(modifyUserWithBase64Image, user)
      const data = yield call(postUser, token, modifiedUser)
      const id = data.users[0].id
      yield call(loadUsers)
      yield put(postUserFulfilled())
      const usersStored = yield select(makeSelectUsers())
      const userStored = usersStored.find(({ id: uId }) => uId === id)
      yield put(userDetailsAction(userStored)) // Open user details modal
      successToast('User Created')
    } catch (error) {
      // react-boilerplate way to get the error body
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(postUserFailed(errorObj))
      errorToast('Create user failed')
    }
  }
}

export function* deleteUser({ payload }) {
  const token = yield select(tokenSelector)
  try {
    yield put(deleteUserPending())
    const data = yield call(deleteUserById, token, payload)
    yield put(deleteUserFinished(data))
    successToast('User Deleted')
    yield call(loadUsers)
  } catch (error) {
    yield put(deleteUserFailed(error))
    errorToast('Delete user failed')
  }
}

export function* patch({ payload: id }) {
  const user = yield select(userEditSelector)
  if (user) {
    const token = yield select(tokenSelector)
    try {
      yield put(patchUserPending())
      const modifiedUser = yield call(modifyUserWithBase64Image, user)
      yield call(patchUser, token, id, modifiedUser)
      yield call(loadUsers)
      yield put(patchUserFulfilled())
      const usersStored = yield select(makeSelectUsers())
      const userStored = usersStored.find(({ id: uId }) => uId === id)
      if (userStored) {
        yield put(userDetailsAction(userStored)) // Open user details modal
      } else {
        yield put(closeModal('edit-user'))
        removeModalManually('edit-user')
      }
      successToast('User Updated')
    } catch (error) {
      // react-boilerplate way to get the error body
      console.log('error', error)
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(patchUserFailed(errorObj))
      errorToast('Update user failed')
    }
  }
}

function* modifyUserWithBase64Image(user) {
  if (!user.avatar) return user

  const { avatar } = user
  const base64ImageFile = yield call(getBase64File, avatar)
  return {
    ...user,
    avatar: { file: base64ImageFile, fileName: avatar.name },
  }
}

export function* find() {
  const user = yield select(userEditSelector)
  if (user) {
    const token = yield select(tokenSelector)
    const { email, employeeIdentifier } = user
    try {
      yield put(findUserPending())
      const data = yield call(
        getUsers,
        token,
        undefined, // TODO: define for mti admin
        'lastName',
        'asc',
        email,
        employeeIdentifier,
        true, // include inactive users in the search
      )
      if (!data.users || data.users.length === 0) {
        yield put(findUserFailed())
        warnToast('User Not Found')
        return
      }
      // yield put(findUserFulfilled())
      const id = data.users[0].id
      const usersStored = yield select(makeSelectUsers())
      const userStored = usersStored.find(({ id: uId }) => uId === id)
      yield put(userDetailsAction(userStored)) // Open user details modal
    } catch (error) {
      console.error(error)
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(findUserFailed(errorObj))
      errorToast('Find user failed')
    }
  }
}

export function* assign({ payload }) {
  const { userId, resourceId, personaeId, resourceType } = payload
  const token = yield select(tokenSelector)
  yield call(
    assignPersonaeRole,
    token,
    userId,
    resourceId,
    personaeId,
    resourceType
  )
}

export function* unassign({ payload }) {
  const { userId, resourceId, personaeId, resourceType } = payload
  const token = yield select(tokenSelector)
  yield call(
    unassignPersonaeRole,
    token,
    userId,
    resourceId,
    personaeId,
    resourceType
  )
}

export function* resetPassword({ payload: id }) {
  const token = yield select(tokenSelector)
  if (token) {
    try {
      yield put(resetPasswordPending())
      yield call(requestPassword, token, id)
      yield put(resetPasswordFulfilled())
    } catch (error) {
      console.log(error)
      yield put(resetPasswordFailed(error))
      errorToast('Reset password failed')
    }
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.POST_USER, post),
    yield takeLatest(ActionTypes.DELETE_USER, deleteUser),
    yield takeLatest(ActionTypes.PATCH_USER, patch),
    yield takeLatest(ActionTypes.FIND_USER, find),
    yield takeLatest(ActionTypes.RESET_PASSWORD, resetPassword),
    yield takeLatest(ActionTypes.CHANGE_USER_STATUS, changeUserStatus),
  ])
}
