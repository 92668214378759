import React from 'react'

export function userRoleComponent(filter, onChange) {
  return (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="">Show All</option>
      <option value="organization_administrator">
        Organization Administrator
      </option>
      <option value="regional_manager" name="Regional Manager">
        Regional Manager
      </option>
      <option value="store_associate" name="Store Associate">
        Store Associate
      </option>
      <option value="store_manager" name="Store Manager">
        Store Manager
      </option>
    </select>
  )
}
