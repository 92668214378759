import { ActionTypes } from '../constants'

/**
 * Region Details Action
 *
 * @returns {Object}
 */
export function regionDetailsAction(region) {
  return {
    type: ActionTypes.REGION_DETAILS,
    payload: region,
  }
}

/**
 * Region Details Action
 *
 * @returns {Object}
 */
export function createRegionAction() {
  return {
    type: ActionTypes.CREATE_REGION,
  }
}

/**
 * Reset Region Management
 *
 * @returns {Object}
 */
export function resetRegionManagement() {
  return {
    type: ActionTypes.RESET_REGION_MANAGEMENT,
  }
}

/**
 * Edit Region
 *
 * @returns {Object}
 */
export function editRegion(o) {
  return {
    type: ActionTypes.REGION_EDIT,
    payload: o,
  }
}

/**
 * Clear Region
 *
 * @returns {Object}
 */
export function clearRegion() {
  return {
    type: ActionTypes.REGION_CLEAR,
  }
}

/**
 * Post Region Failed
 *
 * @returns {Object}
 */
export function postRegionFailed(error) {
  return {
    type: ActionTypes.POST_REGION_FAILED,
    payload: error,
  }
}

/**
 * Post Region Fulfilled
 *
 * @returns {Object}
 */
export function postRegionFulfilled() {
  return {
    type: ActionTypes.POST_REGION_FULFILLED,
  }
}

/**
 * Post Region Pending
 *
 * @returns {Object}
 */
export function postRegionPending() {
  return {
    type: ActionTypes.POST_REGION_PENDING,
  }
}

/**
 * Post Region
 *
 * @returns {Object}
 */
export function postRegion() {
  return {
    type: ActionTypes.POST_REGION,
  }
}

/**
 * Delete Region
 *
 * @returns {Object}
 */
export function deleteRegionAct(region) {
  return {
    type: ActionTypes.DELETE_REGION_ACT,
    payload: region,
  }
}

/**
 * Delete Region Failed
 *
 * @returns {Object}
 */
export function deleteRegionFailed(error) {
  return {
    type: ActionTypes.DELETE_REGION_FAILED,
    payload: error,
  }
}

/**
 * Delete Region Fulfilled
 *
 * @returns {Object}
 */
export function deleteRegionFinished() {
  return {
    type: ActionTypes.DELETE_REGION_FINISHED,
  }
}

/**
 * Delete Region Pending
 *
 * @returns {Object}
 */
export function deleteRegionPending() {
  return {
    type: ActionTypes.DELETE_REGION_PENDING,
  }
}

/**
 * Patch Region Failed
 *
 * @returns {Object}
 */
export function patchRegionFailed(error) {
  return {
    type: ActionTypes.PATCH_REGION_FAILED,
    payload: error,
  }
}

/**
 * Patch Region Fulfilled
 *
 * @returns {Object}
 */
export function patchRegionFulfilled(data) {
  return {
    type: ActionTypes.PATCH_REGION_FULFILLED,
    payload: {
      regions: data,
    },
  }
}

/**
 * Patch Region Pending
 *
 * @returns {Object}
 */
export function patchRegionPending() {
  return {
    type: ActionTypes.PATCH_REGION_PENDING,
  }
}

/**
 * Patch Region
 *
 * @returns {Object}
 */
export function patchRegion(id) {
  return {
    type: ActionTypes.PATCH_REGION,
    payload: id,
  }
}

/**
 * Find Region Failed
 *
 * @returns {Object}
 */
export function findRegionFailed(error) {
  return {
    type: ActionTypes.FIND_REGION_FAILED,
    payload: error,
  }
}

/**
 * Find Region Fulfilled
 *
 * @returns {Object}
 */
export function findRegionFulfilled(data) {
  return {
    type: ActionTypes.FIND_REGION_FULFILLED,
    payload: {
      regions: data,
    },
  }
}

/**
 * Find Region Pending
 *
 * @returns {Object}
 */
export function findRegionPending() {
  return {
    type: ActionTypes.FIND_REGION_PENDING,
  }
}

/**
 * Find Region Found Handled
 *
 * @returns {Object}
 */
export function findRegionFoundHandled() {
  return {
    type: ActionTypes.FIND_REGION_FOUND_HANDLED,
  }
}
