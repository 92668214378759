import { ActionTypes } from '../constants'

/**
 * Fetch Hub
 *
 * @returns {Object}
 */
export function fetchHub(storeId) {
  return {
    type: ActionTypes.FETCH_HUB,
    payload: { storeId },
  }
}

/**
 * Fetch Hub Pending
 *
 * @returns {Object}
 */
export function fetchHubPending(storeId) {
  return {
    type: ActionTypes.FETCH_HUB_PENDING,
    payload: { storeId },
  }
}

/**
 * Fetch Hub Fulfilled
 *
 * @returns {Object}
 */
export function fetchHubFulfilled(storeId, hub) {
  return {
    type: ActionTypes.FETCH_HUB_FULFILLED,
    payload: { storeId, hub },
  }
}

/**
 * Fetch Hub Failed
 *
 * @returns {Object}
 */
export function fetchHubFailed(storeId, error) {
  return {
    type: ActionTypes.FETCH_HUB_FAILED,
    payload: { storeId, error },
  }
}

/**
 * Fetch Hub Status
 *
 * @returns {Object}
 */
export function fetchHubStatus(hubIdentifier) {
  return {
    type: ActionTypes.FETCH_HUB_STATUS,
    payload: { hubIdentifier },
  }
}

/**
 * Fetch Hub Status Pending
 *
 * @returns {Object}
 */
export function fetchHubStatusPending() {
  return {
    type: ActionTypes.FETCH_HUB_STATUS_PENDING,
  }
}

/**
 * Fetch Hub Status Failed
 *
 * @returns {Object}
 */
export function fetchHubStatusFailed(error) {
  return {
    type: ActionTypes.FETCH_HUB_STATUS_FAILED,
    payload: { error },
  }
}

/**
 * Fetch Hub Status Fulfilled
 *
 * @returns {Object}
 */
export function fetchHubStatusFulfilled(hubIdentifier, otauStatus) {
  return {
    type: ActionTypes.FETCH_HUB_STATUS_FULFILLED,
    payload: { hubIdentifier, otauStatus },
  }
}

/**
 * Ping Hub Failed
 *
 * @returns {Object}
 */
export function pingHubFailed(hubIdentifier, error) {
  return {
    type: ActionTypes.PING_HUB_FAILED,
    payload: { hubIdentifier, error },
  }
}

/**
 * Ping Hub Fulfilled
 *
 * @returns {Object}
 */
export function pingHubFulfilled(hubIdentifier, pingStatus) {
  return {
    type: ActionTypes.PING_HUB_FULFILLED,
    payload: { hubIdentifier, pingStatus },
  }
}

/**
 * Ping Hub Pending
 *
 * @returns {Object}
 */
export function pingHubPending(hubIdentifier) {
  return {
    type: ActionTypes.PING_HUB_PENDING,
    payload: { hubIdentifier },
  }
}

/**
 * Ping Hub
 *
 * @returns {Object}
 */
export function pingHub(hubIdentifier) {
  return {
    type: ActionTypes.PING_HUB,
    payload: { hubIdentifier },
  }
}

/**
 * Reboot Hub
 *
 * @returns {Object}
 */
export function rebootHub(hubIdentifier) {
  return {
    type: ActionTypes.REBOOT_HUB,
    payload: { hubIdentifier },
  }
}

/**
 * Restart Lode
 *
 * @returns {Object}
 */
export function restartLode(hubIdentifier) {
  return {
    type: ActionTypes.RESTART_LODE,
    payload: { hubIdentifier },
  }
}
