/*
 *
 * User reducer
 *
 */

import { fromJS, List } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  users: null,
  failed: null,
  loading: false,
  rolesInOrganization: List([]),
  newOrganization: List([]),
  deleteOrganization: List([]),
  rolesInRegions: List([]),
  newRegions: List([]),
  deleteRegions: List([]),
  rolesInStores: List([]),
  newStores: List([]),
  deleteStores: List([]),
  roles: null,
})

function userEditReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.USER_DETAILS:
      return state
        .set('userDetails', payload)
        .set('management', ActionTypes.USER_DETAILS)
        .set('loading', false)
    case ActionTypes.CREATE_USER:
      return state
        .set('userDetails', undefined)
        .set('management', ActionTypes.CREATE_USER)
    case ActionTypes.RESET_USER_MANAGEMENT:
      return state.set('userDetails', undefined).set('management', undefined)
    case ActionTypes.RESET_PASSWORD_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.RESET_PASSWORD_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.RESET_PASSWORD_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.DELETE_USER_FINISHED:
      return state.set('loading', false).set('failed', null)
    case ActionTypes.DELETE_USER_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.DELETE_USER_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.POST_USER_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.POST_USER_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.POST_USER_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.PATCH_USER_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.PATCH_USER_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.PATCH_USER_FAILED:
      return state.set('failed', payload).set('loading', false)
    // case ActionTypes.FIND_USER_FULFILLED:
    // return state.set('loading', false).set('failed', null)
    case ActionTypes.FIND_USER_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FIND_USER_FAILED:
      return state.set('failed', payload).set('loading', false)
    // case ActionTypes.FIND_USER_FOUND_HANDLED:
    // return state.set('userFound', undefined)
    case ActionTypes.USER_EDIT:
      return state.merge(state, payload)
    case ActionTypes.USER_CLEAR:
      return state
        .delete('firstName')
        .delete('lastName')
        .delete('email')
        .delete('password')
        .delete('employeeIdentifier')
        .delete('passcode')
        .delete('welcomeEmail')
        .delete('role')
        .delete('phoneNumber')
        .delete('avatar')
        .delete('persona')
        .delete('resourceIds')
    case ActionTypes.DELETE_ORGANIZATION: {
      const list = state.get('deleteOrganization')
      const index = list.findIndex((assignment) => assignment.id === payload.id)
      return state.set(
        'deleteOrganization',
        index === -1
          ? state.get('deleteOrganization').concat([payload])
          : list.delete(index)
      )
    }
    case ActionTypes.ADD_NEW_ORGANIZATION: {
      const list = state.get('newOrganization')
      const index = list.findIndex((x) => x.id === payload.id)
      return state.set(
        'newOrganization',
        index === -1 ? list.concat([payload]) : list
      )
    }
    case ActionTypes.DELETE_REGION: {
      const list = state.get('deleteRegions')
      const index = list.findIndex((assignment) => assignment.id === payload.id)
      return state.set(
        'deleteRegions',
        index === -1
          ? state.get('deleteRegions').concat([payload])
          : list.delete(index)
      )
    }
    case ActionTypes.ADD_NEW_REGION: {
      const { regions } = state.get('roles') || {}
      const isAssigned = !!(regions || [{}]).find((r) => r.id === payload.id)
      const list = state.get('newRegions')
      const index = list.findIndex((x) => x.id === payload.id)
      return state.set(
        'newRegions',
        index === -1 && !isAssigned ? list.concat([payload]) : list
      )
    }

    case ActionTypes.DELETE_STORE: {
      const list = state.get('deleteStores')
      const index = list.findIndex((assignment) => assignment.id === payload.id)
      return state.set(
        'deleteStores',
        index === -1
          ? state.get('deleteStores').concat([payload])
          : list.delete(index)
      )
    }
    case ActionTypes.ADD_NEW_STORE: {
      const { stores } = state.get('roles') || {}
      const isAssigned = !!(stores || [{}]).find((s) => s.id === payload.id)
      const list = state.get('newStores')
      const index = list.findIndex((x) => x.id === payload.id)
      return state.set(
        'newStores',
        index === -1 && !isAssigned ? list.concat([payload]) : list
      )
    }
    case ActionTypes.EDIT_ROLE_CLEAR:
      return state
        .set('roles', null)
        .set('rolesInOrganization', List([]))
        .set('newOrganization', List([]))
        .set('deleteOrganization', List([]))
        .set('rolesInRegions', List([]))
        .set('newRegions', List([]))
        .set('deleteRegions', List([]))
        .set('rolesInStores', List([]))
        .set('newStores', List([]))
        .set('deleteStores', List([]))
    default:
      return state
  }
}

export default userEditReducer
