import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactTable from 'react-table'
import styled from 'styled-components'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import {
  renderFilterInput,
  HeaderContainer,
  Header,
  renderHeaderCell,
  CellContainer,
} from '../UsersPage/index.screen'
import RegionsPreloader from '../../components/Preloaders/RegionsPreloader'
import TooltipCell from '../../components/TooltipCell'

export const RegionsContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Flex1 = styled.span`
  flex: 1;
`
const StoresColumn = styled(Flex1)`
  padding-right: 34px;
`

export class RegionsPageScreen extends React.PureComponent {
  state = {}

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  render() {
    const {
      regions,
      isAddRegionLocked,
      onCreateRegion,
      onRegion,
      loading,
    } = this.props
    const { tableHeight } = this.state
    const columns = [
      {
        id: 'name',
        width: 225,
        filterable: true,
        Header: renderHeaderCell('Region Name'),
        accessor: 'name',
        Cell: ({ original }) => (
          <CellContainer>
            <a
              tabIndex="-1"
              role="button"
              onClick={() => onRegion(original)}
              name={original.name}
            >
              {original.name}
            </a>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'name',
          }),
      },
      {
        filterable: true,
        width: 100,
        Header: renderHeaderCell('# Stores', true),
        accessor: 'storesCount',
        Cell: ({ original: { storesCount } }) => (
          <TooltipCell
            text={storesCount.toString()}
            render={(tooltip) => (
              <StoresColumn
                ref={(ref) => tooltip.target(ref)}
                className=" text-right"
              >
                {tooltip.text()}
              </StoresColumn>
            )}
          />
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'storesCount',
          }),
      },
      {
        id: 'stores',
        filterable: true,
        Header: renderHeaderCell('Stores'),
        accessor: ({ stores }) => stores && stores.map(({ name }) => name),
        Cell: ({ original: { stores } }) => (
          <TooltipCell
            text={stores && stores.map(({ name }) => name).join(', ')}
            render={(tooltip) => (
              <RegionsContainer ref={(ref) => tooltip.target(ref)}>
                {tooltip.text()}
              </RegionsContainer>
            )}
          />
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'stores',
          }),
      },
      {
        filterable: true,
        width: 130,
        Header: renderHeaderCell('Created', true),
        accessor: 'createdAt',
        Cell: ({ original: { createdAt } }) => (
          <div>
            {createdAt
              ? moment(createdAt)
                .utcOffset(0)
                .format('MM/DD/YYYY')
              : ''}
          </div>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'createdAt',
          }),
      },
    ]

    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Regions</Header>
          <div>
            {!isAddRegionLocked && (
              <button
                className="btn btn-primary"
                onClick={onCreateRegion}
                name="createRegion"
              >
                CREATE REGION
              </button>
            )}
          </div>
        </HeaderContainer>
        {loading ? (
          <RegionsPreloader />
        ) : (
          <ReactTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            data={regions}
            columns={columns}
            defaultFilterMethod={fullTextFilter}
            defaultSorted={[
              {
                id: 'name',
                desc: false,
              },
            ]}
          />
        )}
      </div>
    )
  }
}

RegionsPageScreen.propTypes = {
  regions: PropTypes.array,
  isAddRegionLocked: PropTypes.bool,
  onCreateRegion: PropTypes.func.isRequired,
  onRegion: PropTypes.func.isRequired,
}

export default RegionsPageScreen
