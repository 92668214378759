import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'
import {
  getPermission,
  getPermissionAnyResource,
  getPermissionResources,
  getPermissionRegions,
  hasRole,
  hasRoleAnyResource,
} from '../../utils/mtiUtils'
import MTIRolesPermissionsTable from '../../csv/MTIRolesPermissionsTable.csv'
import { createKey } from '../Keys/keys'
import { createUsers } from '../UsersPage/users'

const selectRoute = (state) => state.get('route')

const makeSelectLocation = () =>
  createSelector(selectRoute, (routeState) => routeState.get('location').toJS())

const selectUser = (userState, appState) => {
  const user = userState.get('data')
  const isMtiAdmin =
    (((user || {}).mtiRoles || [])[0] || {}).name === 'MTI Admin'
  if (isMtiAdmin) {
    return user
  }
  const users = appState.get('users')
  const regions = appState.get('regions')
  if (user && users) {
    const userData = (users.users || []).find(({ id }) => user.id === id)
    const usersData = createUsers({
      ...users,
      ...{ users: userData ? [userData] : undefined, ...{ regions } },
    })
    return usersData.length ? usersData[0].toJS() : user
  } else {
    return user
  }
}

const userSelector = (state) => state.get('user')

const makeSelectData = () =>
  createSelector(userSelector, appSelector, (userState, appState) =>
    selectUser(userState, appState)
  )

const selectIsMtiAdmin = (userState) => {
  const user = userState.get('data')
  const isMtiAdmin =
    ((user || {}).mtiRoles || []).filter(({ name }) => name === 'MTI Admin')
      .length > 0
  return isMtiAdmin
}

const makeSelectIsMtiAdmin = () =>
  createSelector(userSelector, (userState) => selectIsMtiAdmin(userState))

const selectMe = (appState) => appState.get('me') || []

const makeSelectMe = () =>
  createSelector(appSelector, (appState) => selectMe(appState))

const selectMeLoading = (appState) => appState.get('meLoading')

const makeSelectMeLoading = () =>
  createSelector(appSelector, (appState) => selectMeLoading(appState))

const selectPersonae = (appState) => appState.get('personae') || []

const makeSelectPersonae = () =>
  createSelector(appSelector, (appState) => selectPersonae(appState))

const selectPersonaeLoading = (appState) => appState.get('personaeLoading')

const makeSelectPersonaeLoading = () =>
  createSelector(appSelector, (appState) => selectPersonaeLoading(appState))

const mqttSelector = (state) => state.get('mqtt')

const makeSelectSubscribedTopic = () =>
  createSelector(mqttSelector, (mqttState) => mqttState.get('topic'))

const appSelector = (state) => state.get('app')

const makeSelectImages = () =>
  createSelector(appSelector, (appState) => appState.get('images'))

const makeSelectSecurityDeviceIdentifySerialNumber = () =>
  createSelector(appSelector, (appState) =>
    appState.get('securityDeviceIdentifySerialNumber').toArray()
  )

const makeSelectAppRegions = () =>
  createSelector(appSelector, (appState) => appState.get('regions'))

const makeSelectMtiPermissionAccessData = (useCase) =>
  createSelector(userSelector, appSelector, (userState, appState) => {
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRolesPerm = makeSelectMtiRolesPermission(useCase)
    if (!mtiRolesPerm) {
      console.warn('Check permission use case name')
      return undefined
    }
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    const regions = (appState.get('regions') || []).map((r) => r.toJS())

    return {
      mtiRoles,
      mtiRolesPermission: mtiRolesPerm,
      organizationId: orgId,
      regions,
    }
  })

const makeSelectHasMtiRoleAnyResource = (role) =>
  createSelector(userSelector, (userState) => {
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    const regions = (userState.get('data') || {}).regions
    const hasOrgRole = hasRole(mtiRoles, role, orgId, regions)
    const hasResourceRole = hasRoleAnyResource(mtiRoles, role)
    return hasOrgRole || hasResourceRole
  })

const makeSelectHasMtiRole = (role, resourceId) =>
  createSelector(userSelector, (userState) => {
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    const regions = (userState.get('data') || {}).regions
    const hasOrgRole = hasRole(mtiRoles, role, orgId, regions)
    const hasResourceRole = hasRole(mtiRoles, role, resourceId, regions)
    return hasOrgRole || hasResourceRole
  })

const makeSelectMtiRolesPermission = (useCase) =>
  MTIRolesPermissionsTable.find((row) => row['Use Case'] === useCase)

const makeSelectMtiPermissionAnyResource = (useCase) =>
  createSelector(userSelector, (userState) => {
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRolesPerm = makeSelectMtiRolesPermission(useCase)
    if (!mtiRolesPerm) console.warn('Check permission use case name')
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    // Organization level access
    const orgPermission = getPermission(mtiRoles, mtiRolesPerm, orgId)
    // Other than Organization level access
    const resourcePermission = getPermissionAnyResource(mtiRoles, mtiRolesPerm)
    return orgPermission || resourcePermission
  })

const makeSelectMtiPermission = (useCase, sId) =>
  createSelector(userSelector, (userState) => {
    // console.log('makeSelectMtiPermission sId:', sId)
    if (!sId) {
      const orgId = (userState.get('data') || {}).organizationId
      const mtiRolesPerm = makeSelectMtiRolesPermission(useCase)
      if (!mtiRolesPerm) console.warn('Check permission use case name')
      const mtiRoles = (userState.get('data') || {}).mtiRoles
      // Organization level access
      const orgPermission = getPermission(mtiRoles, mtiRolesPerm, orgId)
      // Other than Organization level access
      const resourcePermission = getPermissionAnyResource(
        mtiRoles,
        mtiRolesPerm
      )
      return orgPermission || resourcePermission
    }
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRolesPerm = makeSelectMtiRolesPermission(useCase)
    if (!mtiRolesPerm) console.warn('Check permission use case name')
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    // Organization level access
    // console.log('getPermission>>>>', mtiRoles, mtiRolesPerm, orgId)
    const orgPermission = getPermission(mtiRoles, mtiRolesPerm, orgId)
    // console.log(`'${useCase}' organization: ${orgId}: ${orgPermission}`)
    // Other than Organization level access
    // console.log('getPermission>>>>', mtiRoles, mtiRolesPerm, sId)
    const storePermission = getPermission(mtiRoles, mtiRolesPerm, sId)
    // console.log(`'${useCase}' resource ${sId}: ${storePermission}`)
    const regions = (userState.get('data') || {}).regions
    const regionalPermission = getPermissionRegions(
      mtiRoles,
      mtiRolesPerm,
      sId,
      regions
    )
    // console.log(`'${useCase}' resource ${sId}: ${storePermission}`)
    return orgPermission || storePermission || regionalPermission
  })

const makeSelectMtiPermissionResources = (role) =>
  createSelector(userSelector, (userState) => {
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    const regions = (userState.get('data') || {}).regions
    const resourcePermissions = getPermissionResources(mtiRoles, role, regions)
    return resourcePermissions
  })

const makeSelectIsLoading = () =>
  createSelector(userSelector, (userState) => userState.get('isLoading'))

const makeSelectError = () =>
  createSelector(userSelector, (userState) => userState.get('error'))

const makeSelectToken = () =>
  createSelector(userSelector, (userState) => {
    try {
      return userState.get('data').token
    } catch (e) {
      return undefined
    }
  })
const tokenSelector = makeSelectToken()

const makeSelectSaltToken = () =>
  createSelector(userSelector, (userState) => {
    try {
      return userState.get('data').saltToken
    } catch (e) {
      return undefined
    }
  })

const makeSelectTokenId = () =>
  createSelector(userSelector, (userState) => {
    try {
      return userState.get('data').tokenId
    } catch (e) {
      return undefined
    }
  })

const makeSelectUserId = () =>
  createSelector(userSelector, (userState) => {
    try {
      return userState.get('data').id
    } catch (e) {
      return undefined
    }
  })

const permissionsSelector = (state) => state.get('permissions')

const makeSelectPermissions = () =>
  createSelector(permissionsSelector, (permissionsState) =>
    permissionsState.get('permissions')
  )

const selectTags = (state) => state.get('tags')

const makeSelectTaggings = () =>
  createSelector(selectTags, (tags) => tags.get('taggings'))

const makeSelectTagsEditIsLoading = () =>
  createSelector(selectTags, (tags) => tags.get('editIsLoading'))

const makeSelectTags = () =>
  createSelector(selectTags, (tags) => tags.get('tags'))

const makeSelectEditedTaggings = () =>
  createSelector(selectTags, (tags) =>
    (tags.get('editedTaggings') || { toArray: () => [] })
      .toArray()
      .map((t) => t.toJS())
  )

// Load Organization
const organizationSelector = (state) => state.get('organization')

const makeSelectOrganizationLoading = () =>
  createSelector(organizationSelector, (orgState) => orgState.get('isLoading'))

const makeSelectOrganizationError = () =>
  createSelector(organizationSelector, (orgState) => orgState.get('error'))

const makeSelectOrganization = () =>
  createSelector(organizationSelector, (orgState) =>
    orgState.get('organization')
  )

const makeSelectOrganizationId = () =>
  createSelector(
    organizationSelector,
    (orgState) =>
      (((orgState.get('organization') || {}).organizations || [])[0] || {}).id
  )

const makeSelectSubdomain = () =>
  // TODO: temporary
  createSelector(
    organizationSelector,
    (orgState) =>
      (((orgState.get('organization') || {}).organizations || [])[0] || {})
        .subdomain
  )

const makeSelectOrganizationDetails = () =>
  createSelector(organizationSelector, (orgState) => {
    const state = orgState.get('organization') || {}
    const organizationData = (state.organizations || [])[0]
    const address = (state.addresses || [])[0]
    return { ...organizationData, address }
  })

// Edit Organization
const selectOrganizationEdit = (state) => state.get('organizationEdit')

const makeSelectOrganizationEdit = () =>
  createSelector(
    selectOrganizationEdit,
    (organization) => (organization ? organization.toJS() : organization)
  )

const makeSelectOrganizationPatchLoading = () =>
  createSelector(selectOrganizationEdit, (organization) =>
    organization.get('patchLoading')
  )

const makeSelectOrganizationPatchFailed = () =>
  createSelector(selectOrganizationEdit, (organization) =>
    organization.get('patchFailed')
  )

const makeSelectOrganizationManagement = () =>
  createSelector(selectOrganizationEdit, (organization) =>
    organization.get('management')
  )

const dbStateSelector = (state) => state.get('orm')

const selectStoreById = (sId) =>
  createOrmSelector(orm, (session) => getStore(session, sId))

const makeSelectStore = (sId) =>
  createSelector(dbStateSelector, (ormState) => selectStoreById(sId)(ormState))

const selectStore = createOrmSelector(
  orm,
  dbStateSelector,
  (state, storeId) => storeId,
  (session, storeId) => getStore(session, storeId)
)

const getStore = (session, storeId) => {
  try {
    return session.Store.withId(storeId).ref
  } catch (e) {
    return undefined
  }
}

const selectPositionById = (pId) =>
  createOrmSelector(orm, (session) => {
    try {
      return session.Position.withId(pId).ref
    } catch (e) {
      return undefined
    }
  })

const makeSelectPosition = (pId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectPositionById(pId)(ormState)
  )

const selectSecurityDeviceById = (sdId) =>
  createOrmSelector(orm, (session) => {
    try {
      return session.SecurityDevice.withId(sdId).ref
    } catch (e) {
      return undefined
    }
  })

const makeSelectSecurityDevice = (sdId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectSecurityDeviceById(sdId)(ormState)
  )

const selectStoreIsInDefultFloorModeById = (sId) =>
  createOrmSelector(orm, (session) => {
    try {
      if (!session.Store.hasId(sId)) {
        return false
      }
      const store = session.Store.withId(sId).ref
      const floorsExist = (store.floors || [])
        .map((fId) => session.Floor.hasId(fId))
        .filter((is) => is)
      if (floorsExist.length === 1) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  })

const makeSelectStoreIsInDefultFloorMode = (sId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectStoreIsInDefultFloorModeById(sId)(ormState)
  )

const selectStoreIsInDefultAreaModeById = (sId) =>
  createOrmSelector(orm, (session) => {
    try {
      if (!session.Store.hasId(sId)) {
        return false
      }
      const store = session.Store.withId(sId).ref
      const floorsExist = (store.floors || [])
        .map((fId) => session.Floor.hasId(fId))
        .filter((is) => is)
      const areasExist = (store.areas || [])
        .map((aId) => session.Area.hasId(aId))
        .filter((is) => is)
      if (floorsExist.length === 1 && areasExist.length === 1) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  })

const makeSelectStoreIsInDefultAreaMode = (sId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectStoreIsInDefultAreaModeById(sId)(ormState)
  )

const selectKeys = (state) => state.get('keys')

const makeSelectKeys = (storeId) =>
  createSelector(appSelector, selectKeys, (app, keys) => {
    const users = app.get('users')
    const regions = app.get('regions')
    const usersExtended = createUsers({ ...users, ...{ regions } })
    let keysByStore = keys.get('keys').get(storeId)
    if (!keysByStore) return
    for (let i = 0; i < (keysByStore || {}).size; i++) {
      keysByStore = keysByStore.update(i, (item) => {
        const user = usersExtended.find(({ id }) => id === item.userId)
        if (user) {
          return createKey({ ...item.toJS(), user })
        } else {
          return item
        }
      })
    }
    return keysByStore
  })

const makeSelectKeysFailed = () =>
  createSelector(selectKeys, (keys) => keys.get('failed'))

const makeSelectKeysLoading = () =>
  createSelector(selectKeys, (keys) => keys.get('loading'))

const selectUserEdit = (state) => state.get('userEdit')

const makeSelectUserManagement = () =>
  createSelector(selectUserEdit, (userEdit) => userEdit.get('management'))

const makeSelectUserDetails = () =>
  createSelector(selectUserEdit, (userEdit) => userEdit.get('userDetails'))

const makeSelectUser = () =>
  createSelector(selectUserEdit, (user) => (user ? user.toObject() : user))
const userEditSelector = makeSelectUser()

const makeSelectUserEditFailed = () =>
  createSelector(selectUserEdit, (userEdit) => userEdit.get('failed'))

const makeSelectUserEditLoading = () =>
  createSelector(selectUserEdit, (userEdit) => userEdit.get('loading'))

const selectSecuredProductEdit = (state) => state.get('securedProductEdit')

const makeSelectSelectedSecuredProduct = () =>
  createSelector(selectSecuredProductEdit, (securedProductEdit) =>
    securedProductEdit.get('selectedSecuredProduct')
  )

const makeSelectSecuredProductModalTypeToShow = () =>
  createSelector(selectSecuredProductEdit, (securedProductEdit) =>
    securedProductEdit.get('securedProductModalTypeToShow')
  )

const makeSelectPatchSecuredProductFailed = () =>
  createSelector(selectSecuredProductEdit, (securedProductEdit) =>
    securedProductEdit.get('patchSecuredProductFailed')
  )

const makeSelectPatchSecuredProductLoading = () =>
  createSelector(selectSecuredProductEdit, (securedProductEdit) =>
    securedProductEdit.get('patchSecuredProductLoading')
  )

const selectScheduleEdit = (state) => state.get('scheduleEdit')

const makeSelectScheduleManagement = () =>
  createSelector(selectScheduleEdit, (scheduleEdit) =>
    scheduleEdit.get('management')
  )

const makeSelectScheduleDetails = () =>
  createSelector(selectScheduleEdit, (scheduleEdit) =>
    scheduleEdit.get('scheduleDetails')
  )

const makeSelectSchedule = () =>
  createSelector(
    selectScheduleEdit,
    (schedule) => (schedule ? schedule.toJS() : schedule)
  )

const makeSelectScheduleEditFailed = () =>
  createSelector(selectScheduleEdit, (scheduleEdit) =>
    scheduleEdit.get('failed')
  )

const makeSelectScheduleEditLoading = () =>
  createSelector(selectScheduleEdit, (scheduleEdit) =>
    scheduleEdit.get('loading')
  )

const makeSelectUserFound = () =>
  createSelector(selectUserEdit, (userEdit) => userEdit.get('userFound'))

const selectStoreEdit = (state) => state.get('storeEdit')

const makeSelectStoreEdit = () =>
  createSelector(selectStoreEdit, (store) => (store ? store.toJS() : store))

const makeSelectPatchLoading = () =>
  createSelector(selectStoreEdit, (store) => store.get('patchLoading'))

const makeSelectPatchFailed = () =>
  createSelector(selectStoreEdit, (store) => store.get('patchFailed'))

const makeSelectPostLoading = () =>
  createSelector(selectStoreEdit, (store) => store.get('postLoading'))

const makeSelectPostFailed = () =>
  createSelector(selectStoreEdit, (store) => store.get('postFailed'))

const makeSelectStoreManagement = () =>
  createSelector(selectStoreEdit, (store) => store.get('management'))

const makeSelectStoreDetails = () =>
  createSelector(selectStoreEdit, (store) => store.get('storeDetails'))

const makeSelectRegions = () =>
  createSelector(appSelector, (app) => app.get('regions'))

const selectRegionEdit = (state) => state.get('regionEdit')

const makeSelectRegionManagement = () =>
  createSelector(selectRegionEdit, (regionEdit) => regionEdit.get('management'))

const makeSelectRegionDetails = () =>
  createSelector(selectRegionEdit, (regionEdit) =>
    regionEdit.get('regionDetails')
  )

const makeSelectRegion = () =>
  createSelector(
    selectRegionEdit,
    (region) => (region ? region.toJS() : region)
  )

const makeSelectRegionEditFailed = () =>
  createSelector(selectRegionEdit, (regionEdit) => regionEdit.get('failed'))

const makeSelectRegionEditLoading = () =>
  createSelector(selectRegionEdit, (regionEdit) => regionEdit.get('loading'))

const makeSelectRegionFound = () =>
  createSelector(selectRegionEdit, (regionEdit) =>
    regionEdit.get('regionFound')
  )

//
const makeSelectFetchAndUpsertStoreLoading = (sId) =>
  createSelector(appSelector, (app) =>
    app.get('storeUpsertLoading').get(sId.toString())
  )

const makeSelectFetchAndUpsertStoreFailed = (sId) =>
  createSelector(appSelector, (app) =>
    app.get('storeUpsertFailed').get(sId.toString())
  )

//
const makeSelectFetchAndUpsertGeometryLoading = (sId) =>
  createSelector(appSelector, (app) =>
    app.get('geometryUpsertLoading').get(sId.toString())
  )

const makeSelectFetchAndUpsertGeometryFailed = (sId) =>
  createSelector(appSelector, (app) =>
    app.get('geometryUpsertFailed').get(sId.toString())
  )

//
const selectBaseSnByPuckSn = (puckSn) =>
  createOrmSelector(orm, (session) => {
    try {
      const baseId = (session.SecurityDevice.all()
        .filter(({ serialNumber }) => serialNumber === puckSn)
        .toRefArray() || [])[0].baseId
      if (!baseId) return undefined
      const baseSN = session.SecurityDevice.withId(baseId).ref.serialNumber
      return baseSN
    } catch (e) {
      return undefined
    }
  })

const makeSelectBaseSnByPuckSn = (puckSn) =>
  createSelector(dbStateSelector, (ormState) =>
    selectBaseSnByPuckSn(puckSn)(ormState)
  )

const selectHubsReducer = (state) => state.get('hubsReducer')

const makeSelectHubByStore = (storeId) =>
  createSelector(selectHubsReducer, (state) => {
    const hubs = state.get('hubs')
    if (!hubs || !storeId) return undefined
    return Object.values(hubs).find((hub) => hub.storeId === storeId)
  })

const makeSelectHubByStoreExist = (storeId) =>
  createSelector(selectHubsReducer, (state) => {
    const hubs = state.get('hubs')
    if (!hubs || !storeId) return false
    return !!Object.values(hubs).find((hub) => hub.storeId === storeId)
  })

const makeSelectHub = (hubIdentifier) =>
  createSelector(selectHubsReducer, (state) => {
    const hubs = state.get('hubs')
    if (!hubs) return undefined
    return hubs.hasOwnProperty(hubIdentifier) ? hubs[hubIdentifier] : undefined // eslint-disable-line
  })

const makeSelectIsHubFetchingByStoreId = (storeId) =>
  createSelector(selectHubsReducer, (state) => {
    const hubsLoadingStatuses = state.get('hubsLoadingStatuses')
    if (!hubsLoadingStatuses) return false
    return hubsLoadingStatuses.hasOwnProperty(storeId) // eslint-disable-line
      ? hubsLoadingStatuses[storeId].loading
      : false
  })

const makeSelectIsHubFetchErrorByStoreId = (storeId) =>
  createSelector(selectHubsReducer, (state) => {
    const hubsLoadingStatuses = state.get('hubsLoadingStatuses')
    if (!hubsLoadingStatuses) return undefined
    return hubsLoadingStatuses.hasOwnProperty(storeId) // eslint-disable-line
      ? hubsLoadingStatuses[storeId].error
      : undefined
  })

const makeSelectPingStatusLoading = () =>
  createSelector(selectHubsReducer, (state) => state.get('pingStatusLoading'))

const makeSelectPingStatusError = () =>
  createSelector(selectHubsReducer, (state) => state.get('pingStatusError'))

const makeSelectHubStatusLoading = () =>
  createSelector(selectHubsReducer, (state) => state.get('hubStatusLoading'))

const makeSelectHubStatusError = () =>
  createSelector(selectHubsReducer, (state) => state.get('hubStatusError'))

// Note toObject() keeps members immutable and preserves instance methods
// like humanize()
// Don't imagine we'll need to instantiate this more than once.
const assignableRolesSelector = createSelector(
  (state) => state.get('assignableRoles'),
  (substate) => substate.toObject()
)

export {
  makeSelectLocation,
  makeSelectData,
  makeSelectIsMtiAdmin,
  makeSelectMe,
  makeSelectMeLoading,
  makeSelectPersonae,
  makeSelectPersonaeLoading,
  appSelector,
  makeSelectImages,
  makeSelectAppRegions,
  makeSelectMtiPermissionAccessData,
  makeSelectHasMtiRoleAnyResource,
  makeSelectHasMtiRole,
  makeSelectMtiPermissionAnyResource,
  makeSelectMtiPermission,
  makeSelectMtiPermissionResources,
  makeSelectError,
  makeSelectPermissions,
  makeSelectIsLoading,
  makeSelectToken,
  tokenSelector,
  makeSelectTokenId,
  makeSelectUserId,
  makeSelectTaggings,
  makeSelectTags,
  makeSelectTagsEditIsLoading,
  makeSelectRegions,
  makeSelectEditedTaggings,
  // Load Organization
  makeSelectOrganizationLoading,
  makeSelectOrganizationDetails,
  makeSelectOrganizationError,
  makeSelectOrganization,
  makeSelectOrganizationId,
  makeSelectSubdomain,
  // Edit Organization
  makeSelectOrganizationEdit,
  makeSelectOrganizationPatchLoading,
  makeSelectOrganizationPatchFailed,
  makeSelectOrganizationManagement,
  //
  makeSelectSecurityDeviceIdentifySerialNumber,
  makeSelectPosition,
  makeSelectSecurityDevice,
  makeSelectStore,
  makeSelectStoreIsInDefultFloorMode,
  makeSelectStoreIsInDefultAreaMode,
  makeSelectKeys,
  makeSelectKeysFailed,
  makeSelectKeysLoading,
  makeSelectUser,
  userEditSelector,
  makeSelectUserManagement,
  makeSelectUserDetails,
  makeSelectUserEditFailed,
  makeSelectUserEditLoading,
  makeSelectUserFound,
  makeSelectSchedule,
  makeSelectScheduleManagement,
  makeSelectScheduleDetails,
  makeSelectScheduleEditFailed,
  makeSelectScheduleEditLoading,
  makeSelectStoreEdit,
  makeSelectPatchFailed,
  makeSelectPatchLoading,
  makeSelectPostFailed,
  makeSelectPostLoading,
  makeSelectStoreManagement,
  makeSelectStoreDetails,
  makeSelectSubscribedTopic,
  makeSelectRegion,
  makeSelectRegionManagement,
  makeSelectRegionDetails,
  makeSelectRegionEditFailed,
  makeSelectRegionEditLoading,
  makeSelectRegionFound,
  //
  makeSelectFetchAndUpsertStoreLoading,
  makeSelectFetchAndUpsertStoreFailed,
  //
  makeSelectFetchAndUpsertGeometryLoading,
  makeSelectFetchAndUpsertGeometryFailed,
  //
  makeSelectBaseSnByPuckSn,
  //
  makeSelectPingStatusLoading,
  makeSelectPingStatusError,
  makeSelectSaltToken,
  makeSelectHubByStore,
  makeSelectIsHubFetchErrorByStoreId,
  makeSelectIsHubFetchingByStoreId,
  makeSelectHub,
  makeSelectHubStatusError,
  makeSelectHubStatusLoading,
  makeSelectHubByStoreExist,
  //
  makeSelectSelectedSecuredProduct,
  makeSelectSecuredProductModalTypeToShow,
  makeSelectPatchSecuredProductFailed,
  makeSelectPatchSecuredProductLoading,
  //
  assignableRolesSelector,
  selectStore,
}
