import React from 'react'
import PropTypes from 'prop-types'
import Issue from '../../../components/Issue'
import { friendlyDeviceType } from '../../../utils/mtiUtils'
import { SecuredProductsExceptions } from '../../ExceptionsList/SecuredProducts'

const StoreManagerScreen = ({
  positions,
  height,
  openPosition,
  securityDevices,
  securedProducts,
  onGoToSecuredProducts,
}) => {
  // TODO: Add positions and security device exceptions components in app/containers/ExceptionsList
  // positions exceptions is used in several containers
  const renderSecurityList = () => {
    let notificationsList = []
    notificationsList = notificationsList.concat(
      getSecurityDevicesList(securityDevices),
      getPositionsList(positions, openPosition),
      getSecuredProductsList(securedProducts, onGoToSecuredProducts)
    )
    return notificationsList
  }
  return <div style={{ height: height || 'auto' }}>{renderSecurityList()}</div>
}

const getSecurityDevicesList = (securityDevices) => {
  if (securityDevices) {
    return securityDevices.map((i) => (
      <Issue
        key={i.id}
        issue={{
          id: i.id,
          title: friendlyDeviceType(i.deviceType),
          device: i.status.label,
          status: i.status.type,
          statusTitle: i.status.typeDisplay,
          armStateAction: i.status.action,
          armStateActionTitle: i.status.actionDisplay,
          securityDeviceId: i.id,
          hideGoTo: true,
        }}
      />
    ))
  }
  return []
}

const getPositionsList = (positions, openPosition) => {
  if (positions) {
    return positions.map((i) => (
      <Issue
        key={i.id}
        issue={{
          id: i.id,
          title: i.path,
          device: i.name,
          status: i.status.type,
          statusTitle: i.status.title,
          armStateAction: i.status.states.armStateAction,
          armStateActionTitle: i.status.states.armStateActionTitle,
          deviceType: i.deviceType,
          securityDeviceId: i.securityDevice,
        }}
        openPosition={openPosition}
      />
    ))
  }
  return []
}

const getSecuredProductsList = (securedProducts, onGoToSecuredProducts) => (
  <SecuredProductsExceptions
    securedProducts={securedProducts}
    onGoToSecuredProducts={onGoToSecuredProducts}
  />
)

StoreManagerScreen.propTypes = {
  positions: PropTypes.array.isRequired,
  height: PropTypes.number,
  openPosition: PropTypes.func,
  securityDevices: PropTypes.array.isRequired,
  securedProducts: PropTypes.array.isRequired,
  onGoToSecuredProducts: PropTypes.func,
}

export default StoreManagerScreen
