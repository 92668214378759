import { ActionTypes } from '../constants'

/**
 * Fetch Me Failed
 *
 * @returns {Object}
 */
export function fetchMeFailed(error) {
  return {
    type: ActionTypes.FETCH_ME_FAILED,
    payload: error,
  }
}

/**
 * Fetch Me Fulfilled
 *
 * @returns {Object}
 */
export function fetchMeFulfilled(data) {
  return {
    type: ActionTypes.FETCH_ME_FULFILLED,
    payload: {
      users: data,
    },
  }
}

/**
 * Fetch Me Pending
 *
 * @returns {Object}
 */
export function fetchMePending() {
  return {
    type: ActionTypes.FETCH_ME_PENDING,
  }
}

/**
 * Load Me
 *
 * @returns {Object}
 */
export function loadMe() {
  return {
    type: ActionTypes.LOAD_ME,
  }
}
