import React from 'react'
import PropTypes from 'prop-types'
import { PositionRuleStatus } from 'mti-jsclient-shared'
import { NotCompliantRule, CompliantRule } from '../RuleCell'

export default function RulesList({ rules }) {
  if (!rules) return null

  return rules.map((rule) => {
    if (rule.status === PositionRuleStatus.VIOLATION) {
      return <NotCompliantRule key={`${rule.id}`} rule={rule} />
    }

    return <CompliantRule key={`${rule.id}`} rule={rule} />
  })
}

RulesList.propTypes = {
  rules: PropTypes.array,
}
