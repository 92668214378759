import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import ConfirmModal from 'containers/ModalContainer/ConfirmModal'
import { ActionTypes } from '../App/constants'
import {
  makeSelectMtiPermissionAnyResource,
  makeSelectUserManagement,
  makeSelectUserDetails,
  makeSelectUserEditFailed,
  makeSelectUserEditLoading,
  makeSelectUserFound,
  makeSelectHasMtiRoleAnyResource,
  makeSelectData,
} from '../App/selectors'
import {
  openModal,
  closeModal,
  notifyModal,
  editUser,
  clearUser,
  postUser,
  deleteUser,
  patchUser,
  findUser,
  findUserFoundHandled,
  addRegion,
  addStore,
  clearRole,
  resetUserManagement,
  resetPassword,
} from '../App/actions'
import UserModal from './UserModal'
import CreateEditUserModal from './CreateEditUserModal'
import Regions from '../RegionsPage'
import Stores from '../StoresPage'
import { getEditUserGradeAccess } from '../../utils/mtiUtils'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class UserManagement extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      userManagement,
      userDetails,
      resetUserManagement: doResetUserManagement,
      userEditFailed,
      userEditLoading,
    } = this.props

    if (userManagement === ActionTypes.USER_DETAILS) {
      this.openUserDetailsModal(userDetails)
      doResetUserManagement()
      return
    }

    if (userManagement === ActionTypes.CREATE_USER) {
      this.openCreateUserModal()
      doResetUserManagement()
      return
    }

    if (!userEditLoading && prevProps.userEditLoading && this.createUserModal) {
      this.createUserModal.setLoading(false)
    }

    if (userEditLoading && !prevProps.userEditLoading && this.createUserModal) {
      this.createUserModal.setLoading(true)
    }

    if (
      !userEditFailed &&
      !userEditLoading &&
      this.props.userEditLoading &&
      this.createUserModal
    ) {
      this.createUserModal.close()
    }

    if (userEditFailed && this.createUserModal) {
      this.createUserModal.setServerValidity(userEditFailed)
    }
    /*
    if (userFound) {
      setTimeout(() => {
        this.props.findUserFoundHandled()
      }, 500)
    }
    */
  }

  openResetPasswordModal = (user) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      resetPassword: doResetPassword,
    } = this.props
    setTimeout(() => {
      doOpenModal({
        id: 'password-reset',
        type: 'bootstrap',
        appearance: 'modal-sm',
        content: (
          <ConfirmModal
            id={'password-reset'}
            questionText={
              'This action will reset password and pincode for the selected user. Do you wish to proceed?'
            }
            cancelText={'CANCEL'}
            confirmText={'YES, RESET PASSWORD'}
            onClose={() => {
              doCloseModal({ id: 'password-reset' })
            }}
            onConfirm={() => doResetPassword(user.id)}
          />
        ),
      })
    }, 500)
  }

  openUserDetailsModal = (user) => {
    const {
      hasShowUserPermission,
      hasCreateUserPermission,
      hasEditUserPermission,
      hasDestroyUserPermission,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      clearUser: doClearUser,
      deleteUser: doDeleteUser,
      clearRole: doClearRole,
      onCloseUserModal,
      currentUser,
      hasMtiAdminRole,
      hasOrganizationAdminRole,
      hasRegionalManagerRole,
      hasStoreManagerRole,
    } = this.props
    const isCurrentUser = currentUser.id === user.id
    if (
      !isCurrentUser &&
      this.noPermission(hasShowUserPermission, permText('View User'))
    )
      return
    const hasEditUserGradeAccess = getEditUserGradeAccess(
      isCurrentUser,
      hasMtiAdminRole,
      hasOrganizationAdminRole,
      hasRegionalManagerRole,
      hasStoreManagerRole,
      hasEditUserPermission,
      user.assignedTo
    )

    const isMtiAdmin =
      ((user || {}).mtiRoles || []).filter(({ name }) => name === 'MTI Admin')
        .length > 0
    const isUserEditLocked = !hasEditUserGradeAccess || isMtiAdmin

    doClearUser()
    doClearRole()
    doOpenModal({
      id: 'user',
      type: 'bootstrap',
      content: (
        <UserModal
          id={'user'}
          user={user}
          isEditProfileOnly={currentUser.id === user.id}
          hasCreateUserPermission={hasCreateUserPermission}
          isUserEditLocked={isUserEditLocked}
          title={'User Details'}
          dangerText={'DELETE USER'}
          resetPasswordText={'RESET PASSWORD & PINCODE'}
          confirmText={'EDIT USER DETAILS'}
          onResetPassword={() => this.openResetPasswordModal(user)}
          onDanger={() =>
            setTimeout(() => {
              if (
                this.noPermission(
                  hasDestroyUserPermission,
                  permText('Delete User')
                )
              ) {
                return
              }
              doOpenModal({
                id: 'delete',
                type: 'bootstrap',
                appearance: 'modal-sm',
                content: (
                  <ConfirmModal
                    id={'delete'}
                    cancelText={'CANCEL'}
                    confirmText={'DELETE USER'}
                    onClose={() => doCloseModal({ id: 'delete' })}
                    onConfirm={() => doDeleteUser(user.id)}
                  />
                ),
              })
            }, 500)
          }
          onConfirm={() => {
            doCloseModal({ id: 'user' })
            doClearUser()
            doClearRole()
            setTimeout(() => this.openEditExistingUserModal(user), 500)
            if (onCloseUserModal) onCloseUserModal()
          }}
          onClose={() => {
            doCloseModal({ id: 'user' })
            if (onCloseUserModal) onCloseUserModal()
          }}
        />
      ),
    })
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  openEditExistingUserModal = (user) => {
    const {
      hasEditUserPermission,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      editUser: doEditUser,
      patchUser: doPatchUser,
      currentUser,
      hasRegionalManagerRole,
      hasStoreManagerRole,
      hasOrganizationAdminRole,
      hasMtiAdminRole,
    } = this.props
    if (this.noPermission(hasEditUserPermission, permText('Edit User'))) return

    doOpenModal({
      id: 'edit-user',
      type: 'bootstrap',
      content: (
        <CreateEditUserModal
          ref={(ref) => (this.createUserModal = ref)}
          id={'edit-user'}
          user={user}
          onChange={(o) => doEditUser(o)}
          title={'Edit User'}
          confirmText={'SAVE USER DETAILS'}
          onClose={() => doCloseModal({ id: 'edit-user' })}
          onConfirm={() => doPatchUser(user.id)}
          isEditProfileOnly={currentUser.id === user.id}
          hasRegionalManagerRole={hasRegionalManagerRole}
          hasStoreManagerRole={hasStoreManagerRole}
          hasOrganizationAdminRole={hasOrganizationAdminRole}
          hasMtiAdminRole={hasMtiAdminRole}
          userEditLoading={this.props.userEditLoading}
        />
      ),
    })
  }

  openRegionSelectionModal = (user, personaeId) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      addRegion: doAddRegion,
    } = this.props

    doOpenModal({
      id: 'select-region',
      type: 'bootstrap',
      content: (
        <Regions
          ref={(ref) => (this.selectRegionModal = ref)}
          isSelectRegionModal
          id={'select-region'}
          onClose={() => {
            doCloseModal({ id: 'select-region' })
          }}
          onRegionSelected={(region) => {
            doCloseModal({ id: 'select-region' })
            doAddRegion({ ...region, personaeId })
            setTimeout(() => this.openEditExistingUserModal(user), 500)
          }}
        />
      ),
    })
  }

  openStoreSelectionModal = (user, personaeId, personaeNames) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      addStore: doAddStore,
    } = this.props

    doOpenModal({
      id: 'select-store',
      type: 'bootstrap',
      content: (
        <Stores
          ref={(ref) => (this.selectStoreModal = ref)}
          isSelectStoreModal
          id={'select-store'}
          onClose={() => {
            doCloseModal({ id: 'select-store' })
          }}
          onStoreSelected={(store) => {
            doCloseModal({ id: 'select-store' })
            doAddStore({ ...store, personaeId, personaeNames })
            setTimeout(() => this.openEditExistingUserModal(user), 500)
          }}
        />
      ),
    })
  }

  openCreateUserModal = () => {
    const {
      hasCreateUserPermission,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      postUser: doPostUser,
      clearUser: doClearUser,
      editUser: doEditUser,
      findUser: doFindUser,
      clearRole: doClearRole,
    } = this.props
    if (this.noPermission(hasCreateUserPermission, permText('Create User'))) {
      return
    }

    doClearUser()
    doClearRole()
    doOpenModal({
      id: 'add-user',
      type: 'bootstrap',
      content: (
        <CreateEditUserModal
          ref={(ref) => (this.createUserModal = ref)}
          id={'add-user'}
          user={{}}
          onChange={(o) => doEditUser(o)}
          title={'Add User'}
          confirmText={'CREATE USER'}
          onClose={() => doCloseModal({ id: 'add-user' })}
          onConfirm={() => doPostUser()}
          onFind={() => doFindUser()}
          userEditLoading={this.props.userEditLoading}
        />
      ),
    })
  }

  render() {
    return <div />
  }
}

UserManagement.propTypes = {
  userManagement: PropTypes.string,
  userDetails: PropTypes.object,
  userEditLoading: PropTypes.bool,
  userEditFailed: PropTypes.object,
  hasCreateUserPermission: PropTypes.bool,
  hasShowUserPermission: PropTypes.bool,
  hasEditUserPermission: PropTypes.bool,
  hasDestroyUserPermission: PropTypes.bool,
  notifyModal: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  postUser: PropTypes.func,
  patchUser: PropTypes.func,
  resetPassword: PropTypes.func,
  clearUser: PropTypes.func,
  deleteUser: PropTypes.func,
  editUser: PropTypes.func,
  findUser: PropTypes.func,
  findUserFoundHandled: PropTypes.func,
  userFound: PropTypes.object,
  addRegion: PropTypes.func,
  addStore: PropTypes.func,
  clearRole: PropTypes.func,
  resetUserManagement: PropTypes.func,
  onCloseUserModal: PropTypes.func,
  currentUser: PropTypes.object,
  hasRegionalManagerRole: PropTypes.bool,
  hasStoreManagerRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasMtiAdminRole: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  userManagement: makeSelectUserManagement(),
  userDetails: makeSelectUserDetails(),
  userEditLoading: makeSelectUserEditLoading(),
  userEditFailed: makeSelectUserEditFailed(),
  userFound: makeSelectUserFound(),
  hasCreateUserPermission: makeSelectMtiPermissionAnyResource(
    'User: Create New Objects'
  ),
  hasShowUserPermission: makeSelectMtiPermissionAnyResource(
    'User: Read/Show Details'
  ),
  hasEditUserPermission: makeSelectMtiPermissionAnyResource(
    'User: Update Details of Existing Records'
  ),
  hasDestroyUserPermission: makeSelectMtiPermissionAnyResource(
    'User: Destroy Existing Records'
  ),
  currentUser: makeSelectData(),
  hasRegionalManagerRole: makeSelectHasMtiRoleAnyResource('Regional Manager'),
  hasStoreManagerRole: makeSelectHasMtiRoleAnyResource('Store Manager'),
  hasOrganizationAdminRole: makeSelectHasMtiRoleAnyResource(
    'Organization Administrator'
  ),
  hasMtiAdminRole: makeSelectHasMtiRoleAnyResource('MTI Admin'),
})

const mapDispatchToProps = {
  notifyModal,
  openModal,
  closeModal,
  postUser,
  patchUser,
  resetPassword,
  clearUser,
  deleteUser,
  editUser,
  findUser,
  findUserFoundHandled,
  addRegion,
  addStore,
  clearRole,
  resetUserManagement,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)(UserManagement)
