import { createSelector } from 'reselect'
import { createUsers } from './users'

const selectApp = (state) => state.get('app')

const makeSelectUsers = () =>
  createSelector(selectApp, (app) => {
    const users = app.get('users')
    if (users) {
      const regions = app.get('regions')
      return createUsers({ ...users, ...{ regions } })
    } else {
      return null
    }
  })

const makeSelectFailed = () =>
  createSelector(selectApp, (app) => app.get('usersFailed'))

const makeSelectLoading = () =>
  createSelector(selectApp, (app) => app.get('usersLoading'))

export { selectApp, makeSelectUsers, makeSelectFailed, makeSelectLoading }
