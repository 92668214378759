import { fabric } from 'fabric'

// State
import circleAlarm from 'images/svg/positions/Circle-Alarm.svg'
import circleAlarmHC from 'images/svg/positions/Circle-Alarm-and-health-and-compliance.svg'
import circleSilentAlarm from 'images/svg/positions/Circle-Silent-Alarm.svg'
import circleSilentAlarmHC from 'images/svg/positions/Circle-Silent-Alarm-and-health-and-compliance.svg'

import squareSilentAlarm from 'images/svg/positions/Square-Silent-Alarm.svg'
import squareSilentAlarmHC from 'images/svg/positions/Square-Silent-Alarm-and-health-and-compliance.svg'

import squareAlarm from 'images/svg/positions/Square-Alarm.svg'
import squareAlarmHC from 'images/svg/positions/Square-Alarm-and-health-and-compliance.svg'

import circleLock from 'images/svg/positions/Circle-Lock.svg'
import circleUnlocked from 'images/svg/positions/Circle-Unlocked.svg'
import circleUnlockedHC from 'images/svg/positions/Circle-Unlocked-Health-and-compliance.svg'
// import circleLockLowWarn from 'images/svg/positions/Circle-Lock-Unhealthy.svg'
import circleLockLowWarnHC from 'images/svg/positions/Circle-Lock-health-and-compliance.svg'

// import circleHC from 'images/svg/positions/Circle-Health-and-compliance.svg'
import securePlugUndefined from 'images/svg/positions/Undefined-Secure-Plug.svg'
import cxFlexUndefined from 'images/svg/positions/Undefined-CX-Flex-four-port.svg'
import lockUndefined from 'images/svg/positions/Undefined-Lock.svg'
import circleUndefined from 'images/svg/positions/Undefined.svg'
// import unLockedBigRed from 'images/svg/positions/UnLocked-big-Red.svg'
import unLockedBigYellow from 'images/svg/positions/UnLocked-big-Yellow.svg'
import unLockedBigYellowHC from 'images/svg/positions/UnLocked-big-Yellow-health-and-compliance.svg'
import lockedBigGreen from 'images/svg/positions/Locked-big-Green.svg'
import lockedBigYellowHC from 'images/svg/positions/Locked-big-Yellow-health-and-compliance.svg'

import circleNoPower from 'images/svg/positions/Circle-No-Power.svg'

// Secure Plug
import securePlug from 'images/svg/positions/Secure-Plug.svg'

// CX Flex Four Port
import cxFlexFourPort from 'images/svg/positions/CX-Flex-four-port.svg'
import cxFlexFourPortHC from 'images/svg/positions/CX-Flex-four-port-health-and-compliance.svg'

// Selection
import selectionRound from 'images/svg/positions/selection/selection-round.svg'
import selectionSquare from 'images/svg/positions/selection/selection-square.svg'
import selectionSecurePlug from 'images/svg/positions/selection/selection-secure-plug.svg'
import selectionCXFlexFourPort from 'images/svg/positions/selection/selection-cx-flex-four-port.svg'

import { positionType } from 'utils/mtiCanvasUtils'

export const propsToInclude = [
  'id',
  'name',
  'parentId',
  'mtiType',
  'wantedDeviceType',
  'deviceType',
  'contextType',
  'selectedForEdit',
  'lockScalingX',
  'lockScalingY',
  'hasControls',
  'hasRotatingPoint',
  'hasBorders',
  'evented',
  'selectable',
  'scaleFactor',
]
// eslint-disable-next-line
fabric.Object.prototype.toObject = (function(toObject) {
  // eslint-disable-next-line
  return function(propertiesToInclude) {
    propertiesToInclude = (propertiesToInclude || []).concat(propsToInclude) // eslint-disable-line
    return toObject.apply(this, [propertiesToInclude])
  }
})(fabric.Object.prototype.toObject)

export async function getObject(type, isToObj = true) {
  switch (type) {
    case positionType.alarming:
      return getFabricObject(circleAlarm, isToObj)
    case positionType.alarmingHC:
      return getHcObject(circleAlarmHC, isToObj)
    case positionType.silentAlarming:
      return getFabricObject(circleSilentAlarm, isToObj)
    case positionType.silentAlarmingHC:
      return getHcObject(circleSilentAlarmHC, isToObj)
    case positionType.alarmingLock:
      return getFabricObject(squareAlarm, isToObj)
    case positionType.alarmingLockHC:
      return getHcObject(squareAlarmHC, isToObj)
    case positionType.silentAlarmingLock:
      return getFabricObject(squareSilentAlarm, isToObj)
    case positionType.silentAlarmingLockHC:
      return getHcObject(squareSilentAlarmHC, isToObj)
    case positionType.armed:
      return getFabricObject(circleLock, isToObj)
    case positionType.locked:
      return getFabricObject(lockedBigGreen, isToObj)
    case positionType.disarmed:
      return getFabricObject(circleUnlocked, isToObj)
    case positionType.disarmedHC:
      return getHcObject(circleUnlockedHC, isToObj)
    case positionType.open:
    case positionType.unlocked:
      return getFabricObject(unLockedBigYellow, isToObj)
    case positionType.unlockedHC:
      return getHcObject(unLockedBigYellowHC, isToObj)
    case positionType.unhealthy:
    case positionType.warning:
    case positionType.nonCompliant:
      return getHcObject(circleLockLowWarnHC, isToObj)
    case positionType.unhealthyLock:
    case positionType.warningLock:
    case positionType.nonCompliantLock:
      return getHcObject(lockedBigYellowHC, isToObj)
    case positionType.unpowered:
      return getFabricObject(circleNoPower, isToObj)
    case positionType.silenced:
      return getFabricObject(circleSilentAlarm, isToObj)
    case positionType.securePlug:
      return getFabricObject(securePlug, isToObj)
    case positionType.missingSecurePlugDevice:
      return getFabricObject(securePlugUndefined, isToObj)
    case positionType.missingCXFlexDevice:
      return getFabricObject(cxFlexUndefined, isToObj)
    case positionType.missingLockDevice:
      return getFabricObject(lockUndefined, isToObj)
    case positionType.cxFlexFourPort:
      return getFabricObject(cxFlexFourPort, isToObj)
    case positionType.cxFlexFourPortHC:
      return getHcObject(cxFlexFourPortHC, isToObj)
    case positionType.missingSecurityDevice:
      return getFabricObject(circleUndefined, isToObj)
    default:
      return getFabricObject(circleUndefined, isToObj)
  }
}

export const selectionType = {
  round: 'round',
  square: 'square',
  securePlug: 'securePlug',
  cxFlexFourPort: 'cxFlexFourPort',
}

export function getSelectionObject(type, isToObj = true) {
  switch (type) {
    case selectionType.round:
      return getFabricObject(selectionRound, isToObj)
    case selectionType.square:
      return getFabricObject(selectionSquare, isToObj)
    case selectionType.securePlug:
      return getFabricObject(selectionSecurePlug, isToObj)
    case selectionType.cxFlexFourPort:
      return getFabricObject(selectionCXFlexFourPort, isToObj)
    default:
      return getFabricObject(selectionRound, isToObj)
  }
}

export async function getHcObject(resource, isToObj) {
  const icon = await getFabricObject(resource, false)
  const obj = createHcObject('0', icon)
  return isToObj ? obj.toObject() : obj
}

export function createHcObject(number, hcObj) {
  const width = hcObj.get('width')
  const height = hcObj.get('height')

  return new fabric.Group(
    [
      hcObj,
      new fabric.TruncatedText(number, {
        truncatedTextLength: 10,
        name: 'label',
        fontSize: 14,
        fill: '#ffffff',
        fontFamily: 'Arial',
        originX: 'center',
        originY: 'center',
        left: width / 2,
        top: height / 2 - 10,
        fontWeight: 200,
      }),
    ],
    {
      width,
      height,
      originX: 'center',
      originY: 'center',
      left: 0,
      top: 0,
    }
  )
}

export function getFabricObject(resource, isToObj) {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(resource, (objects, options) => {
      const loadedObjects = fabric.util.groupSVGElements(objects, options)
      resolve(isToObj ? loadedObjects.toObject() : loadedObjects)
    })
  })
}
