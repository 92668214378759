/*
 *
 * Keys reducer
 *
 */

import uuid from 'uuid'
import { fromJS, List } from 'immutable'
import { ActionTypes } from './constants'
import { createKeys } from '../Keys/keys'

const initialState = () => {
  const newKeyId = uuid.v4()
  return fromJS({
    failed: null,
    loading: false,
    keys: List([]),
    newKeys: List([{ id: newKeyId, status: 0 }]),
    focusedKeyId: newKeyId,
  })
}

function keysReducer(state = initialState(), { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_KEYS_FULFILLED: {
      const { storeId, keys } = payload
      return state
        .set('storeId', storeId.toString())
        .set(
          'keys',
          state.get('keys').set(storeId.toString(), createKeys(keys))
        )
        .set('failed', null)
        .set('loading', false)
    }
    case ActionTypes.FETCH_KEYS_PENDING:
    case ActionTypes.DELETE_KEY_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_KEYS_FAILED:
    case ActionTypes.DELETE_KEY_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.DELETE_KEY_FINISHED: {
      return state.set('failed', null).set('loading', false)
    }
    case ActionTypes.UPDATE_KEY_PENDING: {
      const { storeId, keyId } = payload
      let list = state.get('keys').get(storeId.toString())
      list = list.update(
        list.findIndex((item) => item.get('id') === keyId),
        (item) => item.set('loading', true)
      )
      return state
        .set('storeId', storeId)
        .set('keys', state.get('keys').set(storeId.toString(), list))
    }
    case ActionTypes.UPDATE_KEYS_DATA: {
      const { storeId, keys } = payload
      const key = createKeys(keys).get(0)
      let list = state.get('keys').get(storeId.toString())
      let index
      list = list.update(
        list.findIndex((item) => item.get('id') === key.get('id')),
        () => key
      )
      list = list.set(index, key)
      return state
        .set('storeId', storeId)
        .set('keys', state.get('keys').set(storeId.toString(), list))
        .set('failed', null)
    }
    case ActionTypes.UPDATE_KEY_FINISHED: {
      const { storeId, keyId } = payload
      let list = state.get('keys').get(storeId.toString())
      list = list.update(
        list.findIndex((item) => item.get('id') === keyId),
        (item) => item.set('loading', true)
      )
      return state
        .set('storeId', storeId)
        .set('loading', false)
        .set('failed', null)
        .set('keys', state.get('keys').set(storeId.toString(), list))
    }
    case ActionTypes.UPDATE_KEY_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.ADD_NEW_KEY:
      return state
        .set('newKeys', List([payload]).concat(state.get('newKeys')))
        .set('focusedKeyId', payload.id)
    case ActionTypes.UPDATE_NEW_KEY: {
      const list = state.get('newKeys')
      const index = list.findIndex((x) => x.id === payload.id)
      return state
        .set('newKeys', list.update(index, () => payload))
        .set('focusedKeyId', payload.id)
    }
    case ActionTypes.DELETE_NEW_KEY: {
      const list = state.get('newKeys')
      const index = list.findIndex((x) => x.id === payload)
      return state.set('newKeys', list.delete(index)).set('focusedKeyId', -1)
    }
    case ActionTypes.DELETE_NEW_KEYS:
      return state.set('newKeys', List([])).set('focusedKeyId', -1)
    case ActionTypes.ADD_DEFAULT_KEY: {
      const list = state.get('newKeys')
      if (list.size !== 0) return state
      const keyId = uuid.v4()
      return state
        .set('newKeys', List([{ id: keyId, status: 0 }]))
        .set('focusedKeyId', keyId)
    }
    case ActionTypes.POST_KEY_FINISHED: {
      const list = state.get('newKeys')
      const index = list.findIndex((x) => x.id === payload.uiId)
      return state.set('newKeys', list.delete(index))
    }
    default:
      return state
  }
}

export default keysReducer
