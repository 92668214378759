/**
 * Stores Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { modelActionCreators as mac } from 'mti-jsclient-shared'
import { getStores, orgIdUrl } from '../../api'
import {
  makeSelectToken,
  makeSelectIsMtiAdmin,
} from '../../containers/App/selectors'
import { storeImages } from '../../containers/App/actions'
import { makeSelectStores, makeSelectLoading } from './selectors'
import {
  fetchStoresPending,
  fetchStoresFulfilled,
  fetchStoresFailed,
} from './actions'
import { errorToast } from '../../utils/utils'

export function* upsertFloorData(data) {
  const { floors, areas, fixtures, images } = data
  yield images && put(storeImages(images))
  yield floors && put(mac.upsertFloors(floors))
  yield areas && put(mac.upsertAreas(areas))
  yield fixtures && put(mac.upsertFixtures(fixtures))
}

/**
 * Load Stores
 *
 * Reusable saga for fetching data required for displaying
 * - list of stores,
 * - store details modal dialog with regions and tags,
 * - creation of navigation link to store canvas.
 *
 * Data will be stored in ORM and in redux store.
 * Data can be selected by /StoresPage/selectors -> makeSelectStores
 */
export function* loadStoresData() {
  const token = yield select(makeSelectToken())
  // TODO: We will use include property >> // TODO: to create proper navigation link we should get ids and names of floors and areas
  // of course it has drawbacks, loading speed will be significantly reduced if stores > 10
  // please consider navigation async creation or other ways to reduce the loading time
  // const floors = yield all(
  //   (stores || []).map(({ id }) => { console.log('id', id); return call(getFloors, token, id) })
  // )
  const data = yield call(getStores, token, undefined) // , 'floors,areas,address,tag,tags,tagset,tagsets,taggings,tagging,region')
  const { stores, addresses, images } = data
  yield images && put(storeImages(images))
  yield stores && put(mac.upsertStores(stores))
  yield addresses && put(mac.upsertStoreAddresses(addresses))

  const includedFloors = yield call(getStores, token, [
    'floors,floors.preview_image,areas,fixtures,images',
  ])
  yield includedFloors && call(upsertFloorData, includedFloors)
}

export function* loadStores() {
  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Stores')
    return
  } else {
    // console.log('get Stores')
  }
  const isMtiAdmin = yield select(makeSelectIsMtiAdmin())
  if (isMtiAdmin && !orgIdUrl()) return
  const isFetchStoresPending = yield select(makeSelectLoading())
  if (isFetchStoresPending) return
  const storesLoaded = yield select(makeSelectStores())
  const isStoresLoaded = storesLoaded && storesLoaded.length > 1
  try {
    if (!isStoresLoaded) {
      yield put(fetchStoresPending())
    }

    yield call(loadStoresData)
    yield put(fetchStoresFulfilled())
  } catch (error) {
    console.error(error)
    yield put(fetchStoresFailed(error))
    errorToast('Load stores failed')
  }
}
