import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectMtiPermissionAnyResource,
  makeSelectUserFound,
} from '../App/selectors'
import {
  notifyModal,
  userDetailsAction,
  createUserAction,
  changeUserStatusAction,
} from '../App/actions'
import { loadUsers } from './actions'
import {
  makeSelectUsers,
  makeSelectFailed,
  makeSelectLoading,
} from './selectors'
import UsersPageScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import UsersListForHomePage from './usersListForHomePage.screen'
import UsersPageModalScreen from './index.modal.screen'
import UserManagement from './userManagement'
import FetchFailedAlert from '../../components/FetchFailed'

const permText = text => `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class UsersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showInactiveUsers: false }
  }

  componentWillMount() {
    const { userIndexListRecords, history } = this.props
    if (this.noPermission(userIndexListRecords, permText('View Users'))) {
      history.push('/')
      return
    }
    this.loadUsers()
  }

  loadUsers() {
    this.props.loadUsers()
  }

  /* eslint-disable no-undef */
  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  showInactiveUsers(shouldShow) {
    this.setState({ showInactiveUsers: shouldShow })
    this.props.loadUsers(shouldShow)
  }

  render() {
    const {
      users,
      loading,
      failed,
      isHomePage,
      height,
      isModalUserSelect,
      onAssignSelectedUser,
      changeUserStatusAction,
      onClose,
      id,
      userCreateNewObjects,
      userIndexListRecords,
      createUserAction: doCreateUserAction,
      userDetailsAction: doUserDetailsAction,
    } = this.props

    if (loading && !isHomePage) return <UsersPageScreen loading={loading} />
    if (loading && isHomePage) return <ProgressIndicator text={'Loading...'} />
    if (failed) return <FetchFailedAlert failed={failed} />
    if (!users) return null
    const usersChecked = userIndexListRecords ? users : []

    if (isHomePage) {
      return (
        <div>
          <UserManagement />
          <UsersListForHomePage
            users={usersChecked}
            height={height}
            onUser={user => doUserDetailsAction(user)}
          />
        </div>
      )
    }

    if (isModalUserSelect) {
      return (
        <UsersPageModalScreen
          id={id}
          users={usersChecked}
          onUser={user => onAssignSelectedUser(user)}
          onClose={() => onClose()}
        />
      )
    }

    return (
      <div>
        <UserManagement />
        <UsersPageScreen
          users={usersChecked}
          isAddUserLocked={!userCreateNewObjects}
          onUser={user => doUserDetailsAction(user)}
          changeUserStatus={(user, newStatus) => changeUserStatusAction(user, newStatus, this.state.showInactiveUsers)}
          onCreateUser={() => doCreateUserAction()}
          showInactiveUsers={this.state.showInactiveUsers}
          onShowInactiveUsersToggled={shouldShow => this.showInactiveUsers(shouldShow)}
        />
      </div>
    )
  }
}

UsersPage.propTypes = {
  match: PropTypes.object,
  loadUsers: PropTypes.func.isRequired,
  isHomePage: PropTypes.bool,
  height: PropTypes.number,
  users: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  notifyModal: PropTypes.func,
  userDetailsAction: PropTypes.func,
  changeUserStatusAction: PropTypes.func,
  createUserAction: PropTypes.func,
  isModalUserSelect: PropTypes.bool,
  onAssignSelectedUser: PropTypes.func,
  onClose: PropTypes.func,
  id: PropTypes.string,
  userCreateNewObjects: PropTypes.bool,
  userIndexListRecords: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  users: makeSelectUsers(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  userFound: makeSelectUserFound(),
  userCreateNewObjects: makeSelectMtiPermissionAnyResource(
    'User: Create New Objects'
  ),
  userIndexListRecords: makeSelectMtiPermissionAnyResource(
    'User: Index (list) Records'
  ),
})

const mapDispatchToProps = {
  loadUsers,
  notifyModal,
  userDetailsAction,
  createUserAction,
  changeUserStatusAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(UsersPage)
