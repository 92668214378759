import _ from 'lodash'
import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'
import { getTagsByStore } from 'utils/mtiUtils'
import { getImage } from 'utils/mtiCanvasUtils'

const dbStateSelector = (state) => state.get('orm')
const selectTags = (state) => state.get('tags')
const selectApp = (state) => state.get('app')

const selectAllStores = createOrmSelector(orm, (session, tags, app) => {
  const tagsByStore = getTagsByStore(tags.get('taggings'))
  const images = app.get('images')
  const regions = app.get('regions')
  return session.Store.all()
    .toModelArray()
    .map((store) => {
      const obj = store.ref
      const address = store.addressId
      const addressObj = (address && address.ref) || {}
      const floors = store.floorIds.toRefArray().map((f) => ({
        ...f,
        previewImageUrl: getImage(images, f.previewImageId, 'thumbLink').image,
      }))
      const areas = _.flatten(
        store.floorIds.toModelArray().map((floor) => floor.areaIds.toRefArray())
      )
      const region = (regions || []).find(({ id }) => id === store.regionId)
      const imageObj = getImage(images, obj.imageId, '@3xLink')

      return {
        ...obj,
        areas,
        floors,
        address: addressObj,
        tags: (tagsByStore || [])
          .filter((storeTag) => storeTag.id === store.id)
          .map((storeTag) => storeTag.taggings),
        regionName: (region || {}).name,
        imageUrl: imageObj.image,
      }
    })
})

const makeSelectStores = () =>
  createSelector(
    dbStateSelector,
    selectTags,
    selectApp,
    (ormState, tags, app) => selectAllStores(ormState, tags, app)
  )

const makeSelectFailed = () =>
  createSelector(selectApp, (app) => app.get('storesFailed'))

const makeSelectLoading = () =>
  createSelector(selectApp, (app) => app.get('storesLoading'))

export {
  selectAllStores,
  makeSelectStores,
  makeSelectFailed,
  makeSelectLoading,
}
