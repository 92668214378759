import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import {
  CellContainer,
  renderFilterInput,
  HeaderContainer,
  Header,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import MobileNotSupport from '../../components/MobileNotSupport'
import ProgressIndicator from '../../components/ProgressIndicator'
import TablePreloader from '../../components/Preloaders/TablePreloader'
import TooltipCell from '../../components/TooltipCell'

export const NameTitle = styled.span`
  font-weight: 700;
`

export const TagsContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const getStringTags = (tags) => {
  const str = tags.reduce(
    (acc, tag) => acc + tag.map((t) => t.tag.map((i) => i.name)).join(', '),
    ''
  )
  return str
}

export class StoresPageScreen extends React.Component {
  state = {}

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  render() {
    const {
      stores,
      onStoreSelection,
      onAddNewStore,
      onExportAsCSV,
      loading,
    } = this.props
    const { tableHeight } = this.state

    const columns = [
      {
        filterable: true,
        width: 240,
        Header: renderHeaderCell('Name'),
        accessor: 'name',
        Cell: ({ original: { name } }) => (
          <CellContainer>
            <NameTitle id="table_storeName">{name}</NameTitle>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'name',
          }),
      },
      {
        id: 'regionName',
        filterable: true,
        filterMethod: (filter, row) =>
          (row[filter.id] || '')
            .toLowerCase()
            .includes(filter.value.toLowerCase()),
        Header: renderHeaderCell('Region'),
        accessor: ({ regionName }) => regionName,
        Cell: ({ original: { regionName } }) => (
          <TooltipCell
            text={regionName || ''}
            render={(tooltip) => (
              <CellContainer ref={(ref) => tooltip.target(ref)}>
                {regionName || ''}
              </CellContainer>
            )}
          />
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'region',
          }),
      },
      {
        filterable: true,
        Header: renderHeaderCell('State'),
        accessor: 'address.state',
        Cell: ({ original: { address } }) => (
          <CellContainer>{address.state}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'state',
          }),
      },
      {
        filterable: true,
        Header: renderHeaderCell('City'),
        accessor: 'address.city',
        Cell: ({ original: { address } }) => (
          <CellContainer>{address.city}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'city',
          }),
      },
      {
        filterable: true,
        Header: renderHeaderCell('Contact Name'),
        accessor: 'contactName',
        Cell: ({ original: { contactName } }) => (
          <CellContainer>{contactName}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'contactName',
          }),
      },
      {
        filterable: true,
        Header: renderHeaderCell('Contact Email'),
        accessor: 'contactEmail',
        Cell: ({ original: { contactEmail } }) => (
          <TooltipCell
            text={contactEmail}
            render={(tooltip) => (
              <CellContainer ref={(ref) => tooltip.target(ref)}>
                {contactEmail}
              </CellContainer>
            )}
          />
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'contactEmail',
          }),
      },
      {
        Header: renderHeaderCell('Tags'),
        Cell: ({ original: { tags } }) => (
          <TooltipCell
            text={getStringTags(tags)}
            render={(tooltip) => (
              <TagsContainer ref={(ref) => tooltip.target(ref)}>
                {tooltip.text()}
              </TagsContainer>
            )}
          />
        ),
        id: 'tags',
        sortable: true,
        filterable: true,
        accessor: ({ tags }) => getStringTags(tags),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'tags',
          }),
      },
    ]
    const width = window.innerWidth

    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Stores</Header>
          <div>
            <MobileNotSupport text="Export as CSV">
              <button
                type="button"
                className="btn btn-primary mb-0"
                onClick={onExportAsCSV}
              >
                Export as CSV
              </button>
            </MobileNotSupport>
            <button
              type="button"
              className="btn btn-primary mb-0"
              name="addNewStore"
              onClick={onAddNewStore}
            >
              Add New Store
            </button>
          </div>
        </HeaderContainer>
        {loading ? (
          <React.Fragment>
            <TablePreloader columnsCount={7} loaderWidth={width} />
            <ProgressIndicator text={'Loading...'} />
          </React.Fragment>
        ) : (
          <ReactTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            data={stores}
            columns={columns}
            defaultFilterMethod={fullTextFilter}
            getTdProps={(state, rowInfo) => ({
              onClick: (e, handleOriginal) => {
                onStoreSelection(rowInfo.original)

                if (handleOriginal) {
                  handleOriginal()
                }
              },
            })}
            defaultSorted={[
              {
                id: 'name',
                desc: false,
              },
            ]}
          />
        )}
      </div>
    )
  }
}

StoresPageScreen.propTypes = {
  stores: PropTypes.array.isRequired,
  onStoreSelection: PropTypes.func.isRequired,
  onAddNewStore: PropTypes.func.isRequired,
  onExportAsCSV: PropTypes.func.isRequired,
}

export default StoresPageScreen
